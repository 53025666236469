import { useEffect, useMemo, useState } from 'react';
import { Form, notification } from 'antd';

import { DiscountForm, DiscountFormData } from '../interfaces';
import useAppSelector from './useAppSelector';
import useAppDispatch from './useAppDispatch';
import { parseToDates } from '../utils/dayjs';
import { closeFormModal, createDiscount, setSelectedDiscount, updateDiscount } from '../redux/slices/discounts';
import dayjs from 'dayjs';

const useDiscountsForm = () => {
    const [form] = Form.useForm<DiscountFormData>();
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { selectedDiscount, discountVariants } = useAppSelector(state => state.discounts);

    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        if (selectedDiscount) {
            form.setFieldsValue({
                name: selectedDiscount.name,
                discountPercentage: selectedDiscount.discountPercentage,
                range: [dayjs(selectedDiscount.startDate), dayjs(selectedDiscount.endDate)],
            });
            setTargetKeys(
                discountVariants
                    .filter(({ idDiscount }) => idDiscount === selectedDiscount.idDiscount)
                    .map(discountVariant => discountVariant.idVariant.toString())
            );
        } else {
            reset();
        }
    }, [selectedDiscount]);

    const title = useMemo(() => {
        if (selectedDiscount)
            return 'ACTUALIZAR DESCUENTO';
        return 'AGREGAR NUEVO DESCUENTO';
    }, [selectedDiscount]);

    const okText = useMemo(() => {
        if (selectedDiscount)
            return 'ACTUALIZAR'
        return 'AGREGAR';
    }, [selectedDiscount]);

    const onFinish = ({ name, discountPercentage, range }: DiscountFormData) => {
        const dates = parseToDates(range);
        const data: DiscountForm = { name, discountPercentage, ...dates, variants: targetKeys };

        if (selectedDiscount)
            dispatch(updateDiscount(selectedDiscount.idDiscount, data, api, closeModal));
        else
            dispatch(createDiscount(data, api, closeModal));
    }

    const handleTransferChange = (newTargetKeys: string[]) => {
        setTargetKeys(newTargetKeys);
    }

    const handleTransferSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }

    const reset = () => {
        form.resetFields();
        setTargetKeys([]);
        setSelectedKeys([]);
    }

    const closeModal = () => {
        reset();
        dispatch(setSelectedDiscount(null));
        dispatch(closeFormModal());
    }

    return {
        form,
        onFinish,
        title,
        okText,
        targetKeys,
        selectedKeys,
        handleTransferChange,
        handleTransferSelectChange,
        reset,
        closeModal,
        contextHolder,
    };
}

export default useDiscountsForm;