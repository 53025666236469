import { FC, useEffect } from 'react';
import { Button, Col, Row, Space, Typography, notification } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import { button } from '../styles';
import { ExcelUploadModal, ShippingCostFormModal, TableShippings } from '../components';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getAllShippingCosts, openFormModal, uploadShippingCostsBatch } from '../redux/slices/shippingCosts';
import { Columns, exportCSV } from '../utils/excel';
import { ExcelShippingCostRow, ShippingCost } from '../interfaces';
import moment from 'moment';
import { openExcelUploadModal } from '../redux/slices/ui';

interface Props {
}

const excelColumns: Columns<ShippingCost> = [
    {
        key: 'idShippingCost',
        name: 'ID',
    },
    {
        key: 'destination',
        name: 'Destino',
    },
    {
        key: 'municipality',
        name: 'Municipio',
    },
    {
        key: 'costTier1',
        name: '0.01 a 20 Kg',
    },
    {
        key: 'costTier2',
        name: '20 a 40 Kg',
    },
    {
        key: 'costTier3',
        name: '40 a 60 Kg',
    },
];

const Shipping: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { shippingCosts } = useAppSelector(state => state.shippingCosts);

    const getData = () => {
        dispatch(getAllShippingCosts());
    }

    useEffect(() => {
        getData();
    }, []);

    const downloadExcel = async () => {
        await exportCSV<ShippingCost>(excelColumns, shippingCosts, {
            fileName: `Listado_Tarifas_${moment().format('DD-MM-YYYY')}.xlsx`,
            sheet: 'Tarifas'
        });
    }

    const uploadBatch = async (data: ExcelShippingCostRow[]) => {
        dispatch(uploadShippingCostsBatch(data, api));
    }

    return (
        <>
            <Typography.Title>Tarifas</Typography.Title>
            <Row justify='end'>
                <Col>
                    <Space>
                        <Button icon={<RetweetOutlined />} onClick={getData} />
                        <Button type='primary' style={button} onClick={() => dispatch(openFormModal())}>
                            Agregar una nueva tarifa
                        </Button>
                        <Button type='primary' style={button} onClick={downloadExcel}>
                            Exportar csv
                        </Button>
                        <Button type='primary' style={button} onClick={() => dispatch(openExcelUploadModal())}>
                            Importar csv
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <TableShippings />
            </Row>
            <ShippingCostFormModal />
            <ExcelUploadModal
                title='Tarifas'
                columns={excelColumns}
                onDataRead={uploadBatch}
            />
        </>
    );
}

export default Shipping;