import { FC } from 'react';
import { ColumnsType } from 'antd/es/table';

import { useAppSelector } from '../../hooks';
import { Color } from '../../interfaces';
import { DataTable } from '../ui';
import { Button, Space, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface Props {
    onEdit?: (color: Color) => void;
    onDelete?: (color: Color) => void;
}

const ColorsTable: FC<Props> = ({ onEdit, onDelete }) => {
    const { colors } = useAppSelector((state) => state.colors);

    const columns: ColumnsType<Color> = [
        {
            title: 'ID',
            dataIndex: 'idColor',
            key: 'idColor',
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Card',
            dataIndex: 'card',
            key: 'card',
        },
        {
            title: 'RGB',
            dataIndex: 'rgb',
            key: 'rgb',
            render: (color) => (
                <Space>
                    <div
                        style={{
                            width: '3rem',
                            height: '1rem',
                            backgroundColor: `rgb${color}`,
                            borderRadius: 2,
                        }}
                    />
                    <Typography.Text>
                        {color}
                    </Typography.Text>
                </Space>
            ),
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Tooltip title='Editar color'>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => {
                                if (onEdit) onEdit(record);
                            }}
                        />
                    </Tooltip>

                    <Tooltip title='Eliminar color'>
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                if (onDelete) onDelete(record);
                            }}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ]

    return (
        <DataTable columns={columns} data={colors} rowKey='idColor' />
    );
}

export default ColorsTable;