import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UIState {
    loading: boolean;
    excelUploadModalActive: boolean;
}

const initialState: UIState = {
    loading: false,
    excelUploadModalActive: false,
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setLoading(state, { payload }: PayloadAction<boolean>) {
            state.loading = payload;
        },
        startLoading(state) {
            state.loading = true;
        },
        finishLoading(state) {
            state.loading = false;
        },
        openExcelUploadModal(state) {
            state.excelUploadModalActive = true;
        },
        closeExcelUploadModal(state) {
            state.excelUploadModalActive = false;
        },
    },
});

export const {
    setLoading,
    startLoading,
    finishLoading,
    openExcelUploadModal,
    closeExcelUploadModal,
} = uiSlice.actions;

export default uiSlice.reducer;