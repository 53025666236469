import { FC } from 'react';
import { Card, Col, theme, Typography } from 'antd';

interface Props {
    label: string;
    value: number;
    backgroundColor?: string;
    color?: string;
}

const DashboardCard: FC<Props> = ({ label, value, backgroundColor, color }) => {
    const { token } = theme.useToken();


    return (
        <Col xs={{ span: 6 }} style={{ marginBottom: '1rem' }}>
            <Card style={{ backgroundColor: backgroundColor ?? token.colorPrimary }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography.Text
                        style={{ color: color ?? 'white', fontSize: '1.5rem',  display: 'inline' }}
                    >
                        {label}
                    </Typography.Text>
                    <Typography.Title style={{ display: 'inline', margin: 0, color: color ?? 'white' }}>
                        {value}
                    </Typography.Title>
                </div>
            </Card>
        </Col>
    );
}

export default DashboardCard;