import { Modal } from 'antd';
import { FC } from 'react';
import { colors } from '../../themes';

interface Props {
    onClose: () => void;
    source: string;
}

const MobilePreviewModal: FC<Props> = ({ source, onClose }) => {
    return (
        <Modal title='Vista Previa' open={true} onCancel={onClose} onOk={onClose} cancelButtonProps={{ style: { display: 'none' } }} okText='ACEPTAR' width='70%'>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                <div style={{ width: '25%', height: 'calc( 56vh - 60px )' }}>
                    <img
                        src={source}
                        alt='Desktop Banner Preview'
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
                <div
                    style={{
                        position: 'absolute',
                        width: '22.5%',
                        height: '10rem',
                        top: '59%',
                        backgroundColor: colors.primary,
                        borderRadius: 5,
                    }}
                />
            </div>
        </Modal>
    );
}

export default MobilePreviewModal;