import { CSSProperties, FC, useEffect, useRef } from 'react';
import { notification, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { ImagesInputStyles } from '../../styles';

interface Props {
    files: File[];
    limit?: number;
    containerStyle?: CSSProperties;
    onFilesSubmit: (files: File[]) => void;
    onImageDelete: (index: number) => void;
}

const { container, text, imageContainer, image, previewContainer, inputContainer } = ImagesInputStyles;

const fileExtensionsAreValid = (files: File[]) => {
    const allowedExtensions = ['png', 'jpg', 'jpeg', 'avif', 'webp'];
    const invalidFiles = files.filter((file) => !allowedExtensions.includes(file.type.split('/')[1]));
    return invalidFiles.length <= 0;
}

/**
 * Basic Usage:  <ImagesInput files={[...]} limit={1} onFilesSubmit={(files) => ...} onImageDelete={(index) => ...} />
 */
const ImagesInput: FC<Props> = ({ containerStyle, files, limit = 1, onFilesSubmit, onImageDelete }) => {
    const [api, contextHolder] = notification.useNotification();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const drop = useRef(null);

    const onSizeError = () => {
        api.error({ message: 'La cantidad de archivos seleccionados excede el límite.' });
    }

    const onExtensionError = () => {
        api.error({ message: 'La extensión de archivo es inválida.' })
    }

    const openFileInput = () => {
        inputRef.current!.click();
    }

    const onFileCapture = (fileList: FileList | null) => {
        if (!fileList || fileList.length === 0) return;

        const capturedFiles: File[] = [];

        for (let i = 0; i < fileList.length; i++) {
            capturedFiles.push(fileList[i]);
        }

        if ((files.length + capturedFiles.length) > limit) {
            return onSizeError();
        }

        if (!fileExtensionsAreValid(capturedFiles)) {
            return onExtensionError();
        }

        onFilesSubmit(capturedFiles);
    }

    const onDragEnter = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDragLeave = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const { files = null } = e.dataTransfer!;

        onFileCapture(files)
    };

    useEffect(() => {
        const dropRef = drop.current as unknown as HTMLDivElement;

        dropRef.addEventListener('dragover', onDragOver);
        dropRef.addEventListener('drop', onDrop);
        dropRef.addEventListener('dragenter', onDragEnter);
        dropRef.addEventListener('dragleave', onDragLeave);

        return () => {
            dropRef.removeEventListener('dragover', onDragOver);
            dropRef.removeEventListener('drop', onDrop);
            dropRef.removeEventListener('dragenter', onDragEnter);
            dropRef.removeEventListener('dragleave', onDragLeave);
        };
    }, []);

    return (
        <>
            {contextHolder}
            <div ref={drop} style={{ width: '100%', ...containerStyle }}>
                <div style={container}>
                    {
                        files.length > 0
                            ? <div style={previewContainer}>
                                <Space align='center'>
                                    {
                                        files.map((file, index) =>
                                            <div key={file.name} style={imageContainer} onClick={() => onImageDelete(index)}>
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt={file.name}
                                                    style={image}
                                                />
                                            </div>

                                        )
                                    }
                                </Space>
                            </div>
                            : null
                    }
                    <div onClick={openFileInput} style={inputContainer}>
                        <UploadOutlined style={{ color: '#d9d9d9' }} />
                        <p style={text}>SUBIR ARCHIVO</p>
                        <input
                            style={{ display: 'none' }}
                            ref={(element) => inputRef.current = element}
                            type="file"
                            accept='image/*'
                            multiple
                            value=''
                            onChange={({ target }) => onFileCapture(target.files)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ImagesInput;