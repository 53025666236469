import { FC, useMemo } from 'react';
import { Card, Row, Typography } from 'antd';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { Order } from '../../interfaces';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
    orders: Order[];
}

interface ProductChartData {
    id: string;
    name: string;
    quantity: number;
}

const MonthlyProductSalesGraph: FC<Props> = ({ orders }) => {

    const products = useMemo(() => {
        const totalProducts: ProductChartData[] = []

        orders.forEach(order => {
            order.products.forEach(product => {
                const productInArray = totalProducts.find(p => p.id === product.variantKey);
                if (productInArray) {
                    totalProducts.map(p => ({
                        ...p,
                        quantity: p.quantity + product.quantity,
                    }));
                } else {
                    totalProducts.push({
                        id: product.variantKey,
                        name: product.productName,
                        quantity: product.quantity,
                    });
                }
            });
        });

        return totalProducts;
    }, [orders]);

    const data = useMemo(() => {
        return {
            labels: products.slice(0, 6).map(p => `${p.id}`),
            datasets: [
                {
                    label: '# de Elementos Vendidos',
                    data: products.slice(0, 6).map(p => p.quantity),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
    }, [products]);

    return (
        <Card size='small' >
            <Row justify='center'>
                <Typography.Text strong style={{ textTransform: 'uppercase' }}>
                    Productos más vendidos del mes
                </Typography.Text>
            </Row>
            <Row>
                <Doughnut data={data} />
            </Row>
        </Card>
    );
}

export default MonthlyProductSalesGraph;