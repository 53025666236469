import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, CollapseProps, Row, Typography } from 'antd';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';

import { Banner } from '../../interfaces';
import DesktopPreviewModal from './DesktopPreviewModal';
import MobilePreviewModal from './MobilePreviewModal';

interface Props {
    data: Banner;
    onCardClick: (banner: Banner) => void;
}

const BannerCard: FC<Props> = ({ data, onCardClick }) => {
    const [showDesktopPreview, setShowDesktopPreview] = useState(false);
    const [showMobilePreview, setShowMobilePreview] = useState(false);

    const lastUpdate = useMemo(() => {
        return (
            data.updatedAt
                ? moment(data.updatedAt).format('LLL')
                : moment(data.createdAt).format('LLL')
        );
    }, [data]);

    const CardTitle = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Text>
                    Banner
                </Typography.Text>
                <Typography.Text>
                    #{data.idBanner}
                </Typography.Text>
            </div>
        );
    }

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Versión de Escritorio',
            children: <>
                <Row style={{ marginBottom: '1rem' }}>
                    {
                        data.desktopImage === 'N/A'
                            ? <Typography.Text>
                                Banner no registrado.
                            </Typography.Text>
                            : <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <img
                                    src={data.desktopImage}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '10rem',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>
                    }
                </Row>
                <Row justify='center'>
                    <Button icon={<EyeOutlined />} disabled={data.desktopImage === 'N/A'} onClick={() => setShowDesktopPreview(true)}>
                        VISTA PREVIA
                    </Button>
                </Row>
            </>
        },
        {
            key: '2',
            label: 'Versión para Móviles',
            children: <>
                <Row style={{ marginBottom: '1rem' }}>
                    {
                        data.mobileImage === 'N/A'
                            ? <Typography.Text>
                                Banner no registrado.
                            </Typography.Text>
                            : <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <img
                                    src={data.mobileImage}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '10rem',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>
                    }
                </Row>
                <Row justify='center'>
                    <Button icon={<EyeOutlined />} disabled={data.mobileImage === 'N/A'} onClick={() => setShowMobilePreview(true)}>
                        VISTA PREVIA
                    </Button>
                </Row>
            </>
        }
    ]

    return (
        <>
            <Col xs={{ span: 8 }}>
                <Card size='small' type='inner' title={<CardTitle />}>
                    <Row>
                        <Collapse style={{ width: '100%' }} items={items}>
                        </Collapse>
                    </Row>
                    <Row style={{ marginTop: '1rem' }}>
                        <Button type='primary' style={{ width: '100%' }} onClick={() => onCardClick(data)}>
                            GESTIONAR
                        </Button>
                    </Row>
                    <Row justify='end' style={{ marginTop: '1rem' }}>
                        <Typography.Text>
                            Última Actualización: {lastUpdate}
                        </Typography.Text>
                    </Row>
                </Card>
            </Col>
            {showDesktopPreview && <DesktopPreviewModal source={data.desktopImage} onClose={() => setShowDesktopPreview(false)} />}
            {showMobilePreview && <MobilePreviewModal source={data.mobileImage} onClose={() => setShowMobilePreview(false)} />}
        </>
    );
}

export default BannerCard;