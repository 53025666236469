import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { mainApi } from '../../api';
import {
    ExcelProductRow,
    ExcelVariantRow,
    Feature,
    GetFeatures,
    GetImages,
    GetProducts,
    GetVariants,
    HotProduct,
    HotProductsFormData,
    Image,
    Product,
    Variant
} from '../../interfaces';
import { FeedbackFunction } from '../../types';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';

interface ProductsState {
    detailsModalActive: boolean;
    formModalActive: boolean;
    products: Product[];
    selectedProduct: Product | null;
    allFeatures: Feature[];
    allVariants: Variant[];
    allImages: Image[];
    hotProductsModalActive: boolean;
    hotProducts: HotProduct[];
}

const initialState: ProductsState = {
    detailsModalActive: false,
    formModalActive: false,
    products: [],
    selectedProduct: null,
    allFeatures: [],
    allVariants: [],
    allImages: [],
    hotProductsModalActive: false,
    hotProducts: [],
}

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        openProductsFormModal(state) {
            state.formModalActive = true;
        },
        closeProductsFormModal(state) {
            state.formModalActive = false;
        },
        setProducts(state, { payload }: PayloadAction<Product[]>) {
            state.products = payload;
        },
        setSelectedProduct(state, { payload }: PayloadAction<Product | null>) {
            state.selectedProduct = payload;
        },
        setAllFeatures(state, { payload }: PayloadAction<Feature[]>) {
            state.allFeatures = payload;
        },
        setAllVariants(state, { payload }: PayloadAction<Variant[]>) {
            state.allVariants = payload;
        },
        setAllImages(state, { payload }: PayloadAction<Image[]>) {
            state.allImages = payload;
        },
        openDetailsModal(state) {
            state.detailsModalActive = true;
        },
        closeDetailsModal(state) {
            state.detailsModalActive = false;
        },
        setHotProductsModalActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.hotProductsModalActive = payload;
        },
        setHotProducts(state, { payload }: PayloadAction<HotProduct[]>) {
            state.hotProducts = payload;
        },
    },
});

export const {
    openProductsFormModal,
    closeProductsFormModal,
    setProducts,
    setSelectedProduct,
    setAllFeatures,
    setAllVariants,
    setAllImages,
    openDetailsModal,
    closeDetailsModal,
    setHotProductsModalActiveAs,
    setHotProducts,
} = productsSlice.actions;

export const getAllFeatures = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data: { features } } = await mainApi.get<GetFeatures>('/features');

            dispatch(setAllFeatures(features));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllFeatures', error);
            dispatch(finishLoading());
        }
    }
}

export const getAllVariants = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data: { variants } } = await mainApi.get<GetVariants>('/variants');

            dispatch(setAllVariants(variants));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllVariants', error);
            dispatch(finishLoading());
        }
    }
}

export const getAllImages = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data: { images } } = await mainApi.get<GetImages>('/images');

            dispatch(setAllImages(images));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllImages', error);
            dispatch(finishLoading());
        }
    }
}

export const getAllProducts = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data: { products } } = await mainApi.get<GetProducts>('/products');

            dispatch(setProducts(products));
            dispatch(getAllFeatures());
            dispatch(getAllVariants());
            dispatch(getAllImages());
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllProducts', error);
            dispatch(finishLoading());
        }
    }
}

export const deleteProduct = (id: number, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/products/${id}`);

            dispatch(finishLoading());
            dispatch(getAllProducts());
            onSuccess('El producto ha sido eliminado con éxito.');
        } catch (error) {
            console.log('deleteProduct', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar eliminar el producto.');
        }
    }
}

export const deleteFeatureInDatabase = (id: number, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading())

            await mainApi.delete(`/features/${id}`);

            dispatch(finishLoading());
            dispatch(getAllFeatures());
            onSuccess('La característica ha sido eliminada con éxito.');
        } catch (error) {
            console.log('deleteFeatureInDatabase', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar eliminar la característica.');
        }
    }
}

export const deleteImageInDatabase = (id: number, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/images/${id}`);

            dispatch(finishLoading());
            dispatch(getAllImages());
            onSuccess('La imagen ha sido eliminada con éxito.');
        } catch (error) {
            console.log('deleteImageInDatabase', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar eliminar la imagen.');
        }
    }
}

export const uploadProductsBatch = (data: ExcelProductRow[], onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(
                Array.from(Array(Math.ceil(data.length / 500)).keys()).map((i) =>
                    mainApi.post('/products/massiveUpload',
                        {
                            data: data.slice(0 + (i * 500), 500 + (i * 500)),
                        }
                    )
                )
            )

            dispatch(finishLoading());
            dispatch(getAllProducts());
            onSuccess('La carga masiva de productos ha sido completa con éxito.');
        } catch (error) {
            console.log('uploadProductsBatch', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar realizar la carga masiva de productos.');
        }
    }
}

export const uploadVariantsBatch = (data: ExcelVariantRow[], onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(
                Array.from(Array(Math.ceil(data.length / 500)).keys()).map((i) =>
                    mainApi.post('/variants/massiveUpload',
                        {
                            data: data.slice(0 + (i * 500), 500 + (i * 500)),
                        }
                    )
                )
            )

            dispatch(finishLoading());
            dispatch(getAllProducts());
            onSuccess('La carga masiva de presentaciones ha sido completa con éxito.');
        } catch (error) {
            console.log('uploadVariantsBatch', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar realizar la carga masiva de presentaciones.');
        }
    }
}

export const deleteVariantInDatabase = (idVariant: number, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/variants/${idVariant}`);

            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            console.log('deleteVariantInDatabase', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const getAllHotProducts = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get('/hotproducts');

            dispatch(setHotProducts(data.hotProducts));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllHotProducts', error);
            dispatch(finishLoading());
        }
    }
}

export const updateHotProducts = (data: HotProductsFormData, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put('/hotproducts', data);

            dispatch(getAllHotProducts());
            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            console.log('updateHotProducts', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export default productsSlice.reducer;