import { FC } from 'react';
import { Invoice } from '../../interfaces';
import { Tag } from 'antd';

interface Props {
    status: Invoice['status'];
}

const InvoiceStatus: FC<Props> = ({ status }) => {
    switch (status) {
        case 'pending':
            return <Tag color='volcano'>PENDIENTE</Tag>;
        case 'fulfilled':
            return <Tag color='green'>COMPLETADO</Tag>;
        default:
            return <Tag>N/A</Tag>
    }
}

export default InvoiceStatus;