import { FC } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

import { useAppDispatch } from '../../hooks';
import { ExcelUploadModal } from '../ui';
import { closeVariantsImportModal } from '../../redux/slices/productsForm';
import { uploadVariantsBatch } from '../../redux/slices/products';
import { ExcelVariantRow } from '../../interfaces';

interface Props {
    api: NotificationInstance;
}

const VariantsImportModal: FC<Props> = ({ api }) => {
    const dispatch = useAppDispatch();

    const handleMassiveUpload = (data: ExcelVariantRow[]) => {
        dispatch(uploadVariantsBatch(data,
            (message) => {
                api.success({ message });
                dispatch(closeVariantsImportModal());
            },
            (message) => api.error({ message })
        ));
    }

    return (
        <ExcelUploadModal
            onClose={() => dispatch(closeVariantsImportModal())}
            title='Presentaciones'
            columns={[
                { key: 'idVariant', name: 'ID' },
                { key: 'productKey', name: 'Clave' },
                { key: 'barcode', name: 'Código de Barras' },
                { key: 'weight', name: 'Peso (Kg)' },
                { key: 'usesColorGallery', name: 'Usa Galería de Color (1 | 0)' },
                { key: 'allowedColorGroups', name: 'Bases de Color' },
                { key: 'localPrice', name: 'Precio Local' },
                { key: 'foreignPrice', name: 'Precio Foráneo' },
                { key: 'generalPrice', name: 'Precio General' },
                { key: 'usesSpecialPrices', name: 'Usa Precio Especial (1 | 0)' },
                { key: 'specialLocalPrice', name: 'Precio Especial Local' },
                { key: 'specialForeignPrice', name: 'Precio Especial Foráneo' },
                { key: 'specialGeneralPrice', name: 'Precio Especial General' },
                { key: 'paintSheens', name: 'Acabados' },
                { key: 'idProduct', name: 'ID Producto' },
                { key: 'idMeasurement', name: 'ID Medida' }
            ]}
            onDataRead={handleMassiveUpload}
        />
    );
}

export default VariantsImportModal;