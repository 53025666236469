import { Button, Col, Form, FormInstance, Input, InputNumber, Row, Select, Space } from 'antd';
import { FC } from 'react';
import { useAppSelector, useFormConstants, useFormRules } from '../../hooks';
import { MeasurementFormData } from '../../interfaces';
import { button } from '../../styles';

interface Props {
    form: FormInstance;
    onCancel: () => void;
    onFinish: (data: MeasurementFormData) => void;
}

const MeasurementFom: FC<Props> = ({ form, onCancel, onFinish }) => {
    const { gutter, xs } = useFormConstants();
    const { required } = useFormRules();

    const { selectedMeasurement, units } = useAppSelector(state => state.measurements);


    return (
        <Form layout='vertical' autoComplete='off' onFinish={onFinish} form={form}>
            <Row gutter={gutter}>
                <Col xs={xs}>
                    <Form.Item label='Contenedor:' name='container' rules={[required]} >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={xs}>
                    <Form.Item label='Capacidad:' name='quantity' rules={[required]}>
                        <InputNumber style={{ width: '100%' }} addonAfter='UNIDADES' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col xs={xs}>
                    <Form.Item label='Unidad:' name='idUnit' rules={[required]} >
                        <Select
                            options={
                                units.map(
                                    ({ idUnit, name, abbreviation }) =>
                                        ({ value: idUnit, label: `${name} - ${abbreviation}` })
                                )
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='end'>
                <Space>
                    <Button style={button} onClick={onCancel}>
                        Cancelar
                    </Button>
                    <Button type='primary' style={button} htmlType='submit'>
                        {selectedMeasurement ? 'Actualizar' : 'Agregar'}
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default MeasurementFom;