import { FC, useEffect } from 'react';
import { Badge, Button, Col, Row, Space, Typography } from 'antd';
import { EyeOutlined, GlobalOutlined } from '@ant-design/icons';

import { Order } from '../interfaces';
import { button } from '../styles';
import { useAppDispatch, useAppSelector, useSocket } from '../hooks';
import { getAllOrders, setOrders, setUnconfirmedModalActiveAs } from '../redux/slices/orders';
import { OrderActionsModal, OrderDetailsModal, TableOrders, UnconfirmedOrdersModal } from '../components';
import { finishLoading, startLoading } from '../redux/slices/ui';
import { Configs } from '../utils';

interface Props {
}

const Orders: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { orders } = useAppSelector(state => state.orders);
    const { socket, online } = useSocket(Configs.API_URL);

    useEffect(() => {
        socket.on('orders-listener', (data: Order[]) => {
            dispatch(startLoading());
            setTimeout(() => {
                dispatch(setOrders(data));
                dispatch(finishLoading());
            }, 25);
        });

        return () => {
            socket.off('orders-listener');
        };
    }, [socket]);

    useEffect(() => {
        dispatch(getAllOrders());
    }, []);

    const openUnconfirmedOrders = () => {
        dispatch(setUnconfirmedModalActiveAs(true));
    }

    return (
        <>
            <Typography.Title>Pedidos</Typography.Title>
            <Row justify='space-between'>
                <Col>
                    <Space>
                        <Button icon={<GlobalOutlined style={{ color: online ? '#27ae60' : '#c0392b' }} />} style={button} >
                            Conexión
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Space style={{ paddingRight: '0.5rem' }}>
                        <Badge count={orders.filter(o => o.status === 'created').length}>
                            <Button icon={<EyeOutlined />} style={button} onClick={openUnconfirmedOrders}>
                                VER PEDIDOS NO CONFIRMADOS
                            </Button>
                        </Badge>
                    </Space>
                </Col>
            </Row>
            <Row>
                <TableOrders />
            </Row>
            <Row>
                <OrderDetailsModal />
                <OrderActionsModal />
                <UnconfirmedOrdersModal />
            </Row>
        </>
    );
}

export default Orders;