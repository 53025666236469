import { CSSProperties } from "react";

export const container: CSSProperties = {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    border: '1px dashed #d9d9d9',
    borderRadius: '5px',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

export const text: CSSProperties = {
    color: '#d9d9d9',
    textAlign: 'center',
    margin: 0,
    padding: 0,
    cursor: 'pointer',
}

export const imageContainer: CSSProperties = {
    width: '6rem', 
    height: '6rem', 
    display: 'flex', 
    alignItems: 'center'
}

export const image: CSSProperties = {
    maxWidth: '100%',
    maxHeight: '6rem',
    objectFit: 'contain',
    cursor: 'pointer',
}

export const previewContainer: CSSProperties = {
    display: 'flex', 
    marginBottom: '1rem',
    alignItems: 'center',
}

export const inputContainer: CSSProperties = {
    width: '100%', 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
    cursor: 'pointer'
}