import { FC, useMemo, useState } from 'react';
import { Card, Row, Select, Typography } from 'antd';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';

import { useAppSelector } from '../../hooks';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface Props {
}

const initialYear = 2023;

const YearSalesGraph: FC<Props> = () => {
    const { orders } = useAppSelector(state => state.orders);

    const [year, setYear] = useState(dayjs().year());

    const getYearOptionsSinceInitial = () => {
        const currentYear = dayjs().year();
        const totalYears = currentYear - initialYear;

        const options = [
            { label: currentYear.toString(), value: currentYear }
        ];

        for (let i = 1; i <= totalYears; i++) {
            options.push({ label: (currentYear - i).toString(), value: (currentYear - i) });
        }

        return options;
    }
    const yearOptions = getYearOptionsSinceInitial();

    const chartOptions = {
        responsive: true,
    };

    const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const data = useMemo(() => {
        return {
            labels,
            datasets: [
                {
                    label: '# de Ventas',
                    data: labels.map(
                        (month, index) =>
                            orders.filter(o => o.status !== 'cancelled'
                                &&
                                dayjs(o.orderDate).year() === year
                                &&
                                dayjs(o.orderDate).month() === index)
                                .length
                    ),
                    backgroundColor: '#313c51',
                },
            ]
        }
    }, [orders, year]);


    return (
        <Card size='small'>
            <Row justify='center'>
                <Typography.Text strong style={{ textTransform: 'uppercase' }}>
                    Ventas por mes
                </Typography.Text>
            </Row>
            <Row style={{ margin: '0.75rem 0' }}>
                <Typography.Text>
                    Año seleccionado:
                </Typography.Text>
                <Select
                    value={year}
                    options={yearOptions}
                    onChange={(value) => setYear(value)}
                    style={{ width: '100%' }}
                />
            </Row>
            <Row>
                <Bar
                    options={chartOptions}
                    data={data}
                />
            </Row>
        </Card>
    );
}

export default YearSalesGraph;