import { useMemo } from 'react';

import useAppSelector from './useAppSelector';
import useModuleStructure from './useModuleStructure';

const useMenuItems = () => {
    const { userData } = useAppSelector(state => state.auth);
    const { appModules } = useModuleStructure();

    const userType = useMemo(() => userData?.type.enum ?? 0, [userData]);

    const menuItems = useMemo(() => {
        if (!userData) return [];
        return appModules
            .filter(({ allowedTypes }) => allowedTypes.includes(userType))
            .map(({ key, label, icon, children }) => ({ key, label, icon, children}));
    }, [userData]);

    const defaultOpenKeys = useMemo(() => {
        return userType === 1 ? ['/admin'] : [];
    }, [userData])

    return {
        menuItems,
        defaultOpenKeys,
    };
}

export default useMenuItems;