import { FC } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

import { useAppDispatch } from '../../hooks';
import { ExcelUploadModal } from '../ui';
import { ExcelLineRow } from '../../interfaces';
import { uploadLinesBatch } from '../../redux/slices/lines';
import { closeExcelUploadModal } from '../../redux/slices/ui';

interface Props {
    api: NotificationInstance;
}

const LinesImportModal: FC<Props> = ({ api }) => {
    const dispatch = useAppDispatch();

    const handleMassiveUpload = (data: ExcelLineRow[]) => {
        dispatch(uploadLinesBatch(data,
            (message) => {
                api.success({ message });
                dispatch(closeExcelUploadModal());
            },
            (message) => api.error({ message })
        ));
    }

    return (
        <ExcelUploadModal
            title='Líneas'
            columns={[
                {
                    key: 'idLine',
                    name: 'ID',
                },
                {
                    key: 'name',
                    name: 'Nombre',
                },
                {
                    key: 'description',
                    name: 'Descripción',
                }
            ]}
            onDataRead={handleMassiveUpload}
        />
    );
}

export default LinesImportModal;