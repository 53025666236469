import { FC, useEffect } from 'react';
import { Button, Row, Space, Typography } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import { useAppDispatch } from '../hooks';
import { getAllInvoices } from '../redux/slices/invoices';
import { InvoiceActionsModal, InvoiceDetailsModal, TableInvoices } from '../components/invoices';

interface Props {
}

const Invoicing: FC<Props> = () => {
    const dispatch = useAppDispatch()

    const fetchData = () => {
        dispatch(getAllInvoices());
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <Typography.Title>Facturación</Typography.Title>
            <Row justify='end'>
                <Space>
                    <Button icon={<RetweetOutlined />} onClick={fetchData} />
                </Space>
            </Row>
            <Row>
                <TableInvoices />
            </Row>
            <Row>
                <InvoiceDetailsModal />
                <InvoiceActionsModal />
            </Row>
        </>
    );
}

export default Invoicing;