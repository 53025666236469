import { FC } from 'react';
import { Tag } from 'antd';

import { Cancellation } from '../../interfaces';

interface Props {
    status: Cancellation['status'];
}

const CancellationStatus: FC<Props> = ({ status }) => {
    switch (status) {
        case 'pending':
            return <Tag color='volcano'>PENDIENTE</Tag>;
        case 'fulfilled':
            return <Tag color='green'>COMPLETADA</Tag>;
        default:
            return <Tag>N/A</Tag>
    }
}

export default CancellationStatus;