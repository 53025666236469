import { Button, Checkbox, Col, Form, FormInstance, Input, InputNumber, notification, Row, Select, Space, Switch, Typography } from 'antd';
import { FC, Fragment, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector, useFormConstants, useFormRules, useProductsForms } from '../../hooks';
import { ProductVariantFormData } from '../../interfaces';
import { addVariant, closeVariantsForm, openVariantsForm, setFiles, setSelectedVariant, updateVariant } from '../../redux/slices/productsForm';
import { button } from '../../styles';
import { ImagesInput, ModalDeleteConfirmation } from '../ui';
import VariantsTable from './VariantsTable';
import ImagesDisplay from '../ui/ImagesDisplay';
import { deleteImageInDatabase } from '../../redux/slices/products';

interface Props {
    variantForm: FormInstance<ProductVariantFormData>;
}

const VariantsForm: FC<Props> = ({ variantForm }) => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { variantsFormActive, selectedVariant, files, colorCodes } = useAppSelector(state => state.productsForm);
    const { measurements, units } = useAppSelector(state => state.measurements);
    const { paintSheens } = useAppSelector(state => state.paintSheens);

    const { required } = useFormRules();
    const { gutter, xs } = useFormConstants();

    const usesColorGallery = Form.useWatch('usesColorGallery', variantForm);
    const usesSpecialPrices = Form.useWatch('usesSpecialPrices', variantForm);

    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const [imageModalDeleteActive, setImageModalDeleteActive] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);

    const resetForm = () => {
        variantForm.resetFields();
        dispatch(setFiles([]));
        setSelectedIndex(null);
        dispatch(setSelectedVariant(null));
    }

    useEffect(() => {
        if (usesColorGallery === 0) {
            variantForm.setFieldValue('usesSpecialPrices', 0);
        }
    }, [usesColorGallery]);

    useEffect(() => {
        if (variantsFormActive === false) resetForm();
    }, [variantsFormActive]);

    const onFinish = (data: ProductVariantFormData) => {
        if (selectedVariant) {
            if (files.length + (selectedVariant.images ? selectedVariant.images.length : 0) > 3) {
                return api.warning({ message: 'La variante solo puede tener un máximo de 3 imágenes.' });
            }

            dispatch(updateVariant(selectedIndex!, { ...data, files, images: selectedVariant.images }));
            resetForm();
            dispatch(closeVariantsForm());
        } else {
            dispatch(addVariant({ ...data, files }));
            resetForm();
            dispatch(closeVariantsForm());
        }
    }

    const onVariantEdit = (record: ProductVariantFormData, index: number) => {
        dispatch(setSelectedVariant(record));
        setSelectedIndex(index);
        dispatch(openVariantsForm());
    }

    return (
        <>
            {contextHolder}
            {
                variantsFormActive
                    ? <Fragment>
                        <Row justify='start'>
                            <Typography.Text strong>
                                {selectedVariant ? 'ACTUALLIZAR PRESENTACIÓN' : 'AGREGAR NUEVA PRESENTACIÓN'}
                            </Typography.Text>
                        </Row>
                        <Form layout='vertical' form={variantForm} onFinish={onFinish} autoComplete='off' style={{ marginTop: '1rem' }}>
                            <Row gutter={gutter} justify='start'>
                                <Col xs={xs}>
                                    <Form.Item label='Clave:' name='productKey' rules={[required]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={xs}>
                                    <Form.Item label='Código de Barras:' name='barcode' rules={[required]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={gutter} justify='start'>
                                <Col xs={xs}>
                                    <Form.Item label='Medida:' name='idMeasurement' rules={[required]}>
                                        <Select
                                            options={
                                                measurements.map(({ idMeasurement, container, quantity, idUnit }) => ({
                                                    label: `${container} ${quantity} ${units.find(u => u.idUnit === idUnit)!.abbreviation}`,
                                                    value: idMeasurement,
                                                }))
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={xs}>
                                    <Form.Item label='Peso:' name='weight' rules={[required]}>
                                        <InputNumber addonAfter='KG' min={0} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={gutter} justify='start'>
                                <Col xs={{ span: 8 }}>
                                    <Form.Item label='Precio Local:' name='localPrice' rules={[required]}>
                                        <InputNumber addonAfter='MXN' min={0} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 8 }}>
                                    <Form.Item label='Precio Foráneo:' name='foreignPrice' rules={[required]}>
                                        <InputNumber addonAfter='MXN' min={0} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 8 }}>
                                    <Form.Item label='Precio General:' name='generalPrice' rules={[required]}>
                                        <InputNumber addonAfter='MXN' min={0} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={gutter} justify='start'>
                                <Col xs={xs}>
                                    <Form.Item label='Usa Galería de Color:' name='usesColorGallery' rules={[required]}>
                                        <Select
                                            options={[
                                                { value: 0, label: 'No' },
                                                { value: 1, label: 'Sí' }
                                            ]}
                                        />
                                    </Form.Item>
                                    {
                                        usesColorGallery === 1
                                            ? <Form.Item label='Bases de Color Disponibles:' name="allowedColorGroups" rules={[required]}>
                                                <Checkbox.Group
                                                    options={colorCodes.map(code => ({ label: code, value: code }))}
                                                />
                                            </Form.Item>
                                            : null
                                    }
                                    {
                                        usesColorGallery === 1
                                            ? <Form.Item label='Acabados Disponibles:' name="paintSheens" rules={[]}>
                                                <Checkbox.Group
                                                    options={paintSheens.map(({ idPaintSheen, name }) => ({ label: name, value: idPaintSheen }))}
                                                />
                                            </Form.Item>
                                            : null
                                    }
                                </Col>
                                {
                                    usesColorGallery === 1
                                        ? <Col xs={xs}>
                                            <Form.Item label='Usa Precios Especiales:' name='usesSpecialPrices' rules={[required]}>
                                                <Select
                                                    options={[
                                                        { value: 0, label: 'No' },
                                                        { value: 1, label: 'Sí' }
                                                    ]}
                                                />
                                            </Form.Item>
                                            {
                                                usesSpecialPrices === 1
                                                    ? <Row>
                                                        <Col xs={{ span: 24 }}>
                                                            <Form.Item label='Precio Especial Local:' name='specialLocalPrice' rules={[required]}>
                                                                <InputNumber addonAfter='MXN' min={0} style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 24 }}>
                                                            <Form.Item label='Precio Especial Foráneo:' name='specialForeignPrice' rules={[required]}>
                                                                <InputNumber addonAfter='MXN' min={0} style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 24 }}>
                                                            <Form.Item label='Precio Especial General:' name='specialGeneralPrice' rules={[required]}>
                                                                <InputNumber addonAfter='MXN' min={0} style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    : null
                                            }
                                        </Col>
                                        : null
                                }
                            </Row>
                        </Form>
                        <Row gutter={gutter}>
                            <Col xs={{ span: 24 }}>
                                <ImagesInput
                                    limit={3}
                                    files={files}
                                    onFilesSubmit={(newFiles) => dispatch(setFiles([...files, ...newFiles]))}
                                    onImageDelete={(index) => dispatch(setFiles(files.filter((_, fileIndex) => fileIndex !== index)))}
                                />
                            </Col>
                        </Row>
                        {
                            (selectedVariant && selectedVariant.images)
                                ? <Row gutter={gutter} style={{ marginTop: '1rem' }}>
                                    <Col xs={{ span: 24 }}>
                                        <ImagesDisplay
                                            images={selectedVariant.images.map(i => i.url)}
                                            onImageClick={(index) => {
                                                setSelectedImageIndex(index);
                                                setImageModalDeleteActive(true);
                                            }}
                                        />
                                    </Col>
                                    <ModalDeleteConfirmation
                                        open={imageModalDeleteActive}
                                        title='¿Está seguro de eliminar la imagen seleccionada?'
                                        onCancel={() => {
                                            setImageModalDeleteActive(false);
                                            setSelectedImageIndex(null)
                                        }}
                                        onOk={() => {
                                            dispatch(
                                                deleteImageInDatabase(
                                                    selectedVariant.images![selectedImageIndex!].idImage,
                                                    (message) => {
                                                        api.success({ message });
                                                        dispatch(setSelectedVariant({
                                                            ...selectedVariant,
                                                            images: selectedVariant.images!.filter((_, imageIndex) => imageIndex !== selectedImageIndex)
                                                        }));
                                                        setSelectedImageIndex(null);
                                                        setImageModalDeleteActive(false);
                                                    },
                                                    (message) => api.error({ message }),
                                                ));
                                        }}
                                    />
                                </Row>
                                : null
                        }

                        <Row justify='end' style={{ marginTop: '1rem' }}>
                            <Space>
                                <Button style={button} onClick={() => dispatch(closeVariantsForm())}>
                                    Cancelar
                                </Button>
                                <Button type='primary' style={button} onClick={variantForm.submit}>
                                    {selectedVariant ? 'Actualizar' : 'Agregar'}
                                </Button>
                            </Space>
                        </Row>

                    </Fragment>
                    : <Fragment>
                        <Row justify='end'>
                            <Button type='primary' style={button} onClick={() => dispatch(openVariantsForm())}>
                                Agregar presentación
                            </Button>
                        </Row>
                        <Row>
                            <VariantsTable
                                onEdit={onVariantEdit}
                            />
                        </Row>
                    </Fragment>
            }
        </>
    );
}

export default VariantsForm;