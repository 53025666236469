import { Button, Col, Form, Input, Row, Select, Space, notification } from 'antd';
import { FC, useEffect } from 'react';

import { button } from '../../styles';
import { useAppDispatch, useAppSelector, useFormConstants, useFormRules } from '../../hooks';
import { ImagesInput } from '../ui';
import { AnnouncementFormData } from '../../interfaces';
import { createAnnouncement, setAnnouncementFormActiveAs, setSelectedAnnouncement, updateAnnouncement } from '../../redux/slices/customers';
import ImagesDisplay from '../ui/ImagesDisplay';

interface Props {
}

const AnnouncementsForm: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm<AnnouncementFormData>();
    const files = Form.useWatch('files', form);

    const dispatch = useAppDispatch();
    const { selectedAnnouncement } = useAppSelector(state => state.customers);
    const { gutter, xs } = useFormConstants();
    const { required } = useFormRules();

    useEffect(() => {
        if (selectedAnnouncement) {
            form.setFieldsValue({
                mode: selectedAnnouncement.mode,
                subject: selectedAnnouncement.subject,
                title: selectedAnnouncement.title,
                message: selectedAnnouncement.message ?? '',
            });
        } else {
            form.resetFields();
        }
    }, [selectedAnnouncement]);

    const closeForm = () => {
        form.resetFields();
        dispatch(setSelectedAnnouncement(null));
        dispatch(setAnnouncementFormActiveAs(false));
    }

    const handleSubmit = (data: AnnouncementFormData) => {
        if (selectedAnnouncement) {
            dispatch(updateAnnouncement(selectedAnnouncement.idAnnouncement, data, () => {
                api.success({ message: 'El anuncio ha sido actualizado con éxito.' });
                closeForm();
            }, () => {
                api.error({ message: 'Ha ocurrido un error al intentar actualizar el anuncio.' });
            }));
        } else {
            dispatch(createAnnouncement(data, () => {
                api.success({ message: 'El anuncio ha sido creado con éxito.' });
                closeForm();
            }, () => {
                api.error({ message: 'Ha ocurrido un error al intentar crear el anuncio.' });
            }));
        }
    }

    return (
        <Form layout='vertical' autoComplete='off' onFinish={handleSubmit} form={form}>
            {contextHolder}
            <Row gutter={gutter} style={{ marginBottom: '1rem' }}>
                <Col xs={xs}>
                    <Form.Item label='Tipo:' name='mode' help='Los correos promocionales solo se enviarán a los clientes que esten suscritos, los correos de anuncio serán enviados a todos los clientes.' rules={[required]}>
                        <Select
                            options={[
                                { label: 'Promoción', value: 'promotional' },
                                { label: 'Anuncio', value: 'public' }
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={xs}>
                    <Form.Item label='Asunto:' name='subject' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={gutter} style={{ marginBottom: '1rem' }}>
                <Col xs={xs}>
                    <Form.Item label='Título:' name='title' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={xs}>
                    <Form.Item label='Mensaje:' name='message' rules={[required]}>
                        <Input.TextArea rows={3} maxLength={750} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col xs={{ span: 24 }}>
                    <Form.Item label='Imágenes (3 máx.):' name='files' initialValue={[]}>
                        <ImagesInput
                            limit={1}
                            files={files ?? []}
                            onFilesSubmit={(newFiles) => form.setFieldValue('files', [...files, ...newFiles])}
                            onImageDelete={(index) => form.setFieldValue('files', files.filter((_, fileIndex) => fileIndex !== index))}
                        />
                    </Form.Item>
                    {
                        selectedAnnouncement
                            ? <ImagesDisplay
                                images={
                                    [
                                        selectedAnnouncement.image1,
                                        selectedAnnouncement.image2,
                                        selectedAnnouncement.image3
                                    ].filter(img => img !== null) as string[]
                                }
                                label='Imágenes actuales (Serán reemplazadas si se suben nuevas imágenes):'
                            />
                            : null
                    }
                </Col>
            </Row>
            <Row justify='end'>
                <Space>
                    <Button style={button} onClick={closeForm}>
                        Cancelar
                    </Button>
                    <Button type='primary' htmlType='submit' style={button}>
                        {selectedAnnouncement ? 'Actualizar' : 'Crear'}
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default AnnouncementsForm;