import { FC, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { notification } from 'antd';

import { Coupon } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { deleteCoupon, openForm, setSelectedCoupon } from '../../redux/slices/coupons';

interface Props {
}

const TableCoupons: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { coupons, selectedCoupon } = useAppSelector(state => state.coupons);

    const [modalDeleteActive, setModalDeleteActive] = useState(false);

    const columns: ColumnsType<Coupon> = [
        {
            title: 'ID',
            key: 'idCoupon', dataIndex: 'idCoupon',
        },
        {
            title: 'Nombre',
            key: 'name', dataIndex: 'name',
        },
        {
            title: 'Descripción',
            key: 'description', dataIndex: 'description',
            render: (value) => value ?? 'N/A',
        },
        {
            title: 'Código',
            key: 'code', dataIndex: 'code',
        },
        {
            title: 'Descuento',
            key: 'discountPercentage', dataIndex: 'discountPercentage',
            render: (value) => `${value}%`,
        },
        {
            title: 'Estado',
            key: 'status', dataIndex: 'status',
            render: (value) => <span style={{ color: value === 'active' ? '#27ae60' : '#c0392b' }}>
                {value === 'active' ? 'Activo' : 'Inactivo'}
            </span>
        },
        {
            title: 'Acciones',
            render: (_, record) =>
                <ActionsColumn
                    label='Cupón'
                    showDetails={false}
                    onEditClick={() => {
                        dispatch(setSelectedCoupon(record));
                        dispatch(openForm());
                    }}
                    onDeleteClick={() => {
                        dispatch(setSelectedCoupon(record));
                        setModalDeleteActive(true);
                    }}
                />
        }
    ];

    const closeDeleteConfirmation = () => {
        dispatch(setSelectedCoupon(null));
        setModalDeleteActive(false);
    }

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={coupons} rowKey='idCoupon' />
            <ModalDeleteConfirmation
                title={'¿Está seguro de eliminar el cupón seleccionado?'}
                open={modalDeleteActive}
                onCancel={closeDeleteConfirmation}
                onOk={() => {
                    dispatch(deleteCoupon(selectedCoupon!.idCoupon, api, closeDeleteConfirmation));
                 }}
            />
        </>
    );
}

export default TableCoupons;