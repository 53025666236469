import { FC, useState } from 'react';
import { notification } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';

import { Parser } from '../../utils';
import { ShippingCost } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { deleteShippingCost, openFormModal, setSelectedShippingCost } from '../../redux/slices/shippingCosts';

interface Props {
}

const TableShippings: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { shippingCosts, selectedShippingCost } = useAppSelector(state => state.shippingCosts);

    const [modalDeleteActive, setModalDeleteActive] = useState(false);

    const columns: ColumnsType<ShippingCost> = [
        {
            title: 'ID',
            key: 'idShippingCost', dataIndex: 'idShippingCost',
        },
        {
            title: 'Municipio',
            key: 'municipality', dataIndex: 'municipality',
            render: (value) => {
                switch (value) {
                    case 1:
                        return 'La Paz';
                    case 2:
                        return 'Los Cabos';
                    case 3:
                        return 'Comondú';
                    case 4:
                        return 'Loreto';
                    case 5:
                        return 'Mulegé';
                    default:
                        return 'N/A';
                }
            }
        },
        {
            title: 'Destino',
            key: 'destination', dataIndex: 'destination',
        },
        {
            title: '0.01 a 20 Kg',
            key: 'costTier1', dataIndex: 'costTier1',
            render: (value) => Parser.parseToPrice(value),
        },
        {
            title: '20 a 40 Kg',
            key: 'costTier2', dataIndex: 'costTier2',
            render: (value) => Parser.parseToPrice(value),
        },
        {
            title: '40 a 60 Kg',
            key: 'costTier3', dataIndex: 'costTier3',
            render: (value) => Parser.parseToPrice(value),
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn
                label='Tarifa'
                showDetails={false}
                onEditClick={() => {
                    dispatch(setSelectedShippingCost(record));
                    dispatch(openFormModal());
                }}
                onDeleteClick={() => {
                    setModalDeleteActive(true);
                    dispatch(setSelectedShippingCost(record));
                }}
            />
        }
    ];

    const closeModal = () => {
        setModalDeleteActive(false);
        dispatch(setSelectedShippingCost(null));
    }

    return (
        <>
        {contextHolder}
            <DataTable columns={columns} data={shippingCosts} rowKey='idShippingCost' />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar la tarifa seleccionada?'
                open={modalDeleteActive}
                onCancel={closeModal}
                onOk={() => {
                    if (!selectedShippingCost)
                        return closeModal();
                    dispatch(deleteShippingCost(selectedShippingCost.idShippingCost, api, closeModal));
                }}
            />
        </>
    );
}

export default TableShippings;