import { FC, useMemo } from 'react';
import { Button, Col, Modal, notification, Row, Space } from 'antd';
import moment from 'moment';

import { useAppDispatch, useAppSelector, useColorsForm } from '../../hooks';
import { closeColorsModal, uploadColorsBatch } from '../../redux/slices/colors';
import { closeExcelUploadModal, openExcelUploadModal } from '../../redux/slices/ui';
import { ExcelUploadModal, ModalDeleteConfirmation } from '../ui';
import { Columns, exportCSV } from '../../utils/excel';
import { Color, ExcelColorRow } from '../../interfaces';
import ColorsTable from './ColorsTable';
import ColorsForm from './ColorsForm';

interface Props {
}

const ColorsModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { colors, modalActive } = useAppSelector((state) => state.colors);

    const { formActive, closeForm, openForm, selectedColor, onFormSubmit, onDelete, deletePromptActive, onDeleteConfirm, onDeleteCancel } = useColorsForm(api);

    const title = useMemo(() => {
        if (!formActive) return 'GESTIÓN DE COLORES';
        if (selectedColor) return 'ACTUALIZAR COLOR'
        return 'AGREGAR NUEVO COLOR'
    }, [formActive]);

    const closeModal = () => {
        closeForm();
        dispatch(closeColorsModal());
    }

    const downloadExcel = async () => {
        const columns: Columns<Color> = [
            {
                key: 'idColor',
                name: 'ID',
            },
            {
                key: 'name',
                name: 'Nombre',
            },
            {
                key: 'code',
                name: 'Código',
            },
            {
                key: 'card',
                name: 'Card'
            },
            {
                key: 'rgb',
                name: 'RGB',
            },
        ]

        await exportCSV<Color>(columns, colors, {
            fileName: `Listado_Colores_${moment().format('DD-MM-YYYY')}.xlsx`,
            sheet: 'Colores'
        });
    }

    const handleMassiveUpload = (data: ExcelColorRow[]) => {
        dispatch(uploadColorsBatch(data,
            () => {
                api.success({
                    message: 'La carga masiva de colores ha sido completada con éxito.'
                });
                dispatch(closeExcelUploadModal());
            },
            () => api.error({
                message: 'Ha ocurrido un error al intentar hacer la carga masiva de colores.'
            })
        ));
    }

    return (
        <Modal title={title} open={modalActive} width='70%' footer={[]} onCancel={closeModal}>
            {contextHolder}
            {
                formActive
                    ? null
                    : <Row justify='end'>
                        <Col>
                            <Space>
                                <Button type='primary' onClick={() => openForm()}>
                                    AGREGAR COLOR
                                </Button>
                                <Button type='primary' onClick={downloadExcel}>
                                    EXPORTAR CSV
                                </Button>
                                <Button type='primary' onClick={() => dispatch(openExcelUploadModal())}>
                                    IMPORTAR CSV
                                </Button>
                            </Space>
                        </Col>
                    </Row>
            }
            <Row>
                {
                    formActive
                        ? <ColorsForm
                            onFormCancel={closeForm}
                            selectedColor={selectedColor}
                            onFormSubmit={onFormSubmit}
                        />
                        : <ColorsTable
                            onEdit={openForm}
                            onDelete={onDelete}
                        />
                }
            </Row>
            <ExcelUploadModal
                title='Colores'
                columns={[
                    {
                        key: 'idColor',
                        name: 'ID',
                    },
                    {
                        key: 'name',
                        name: 'Nombre',
                    },
                    {
                        key: 'code',
                        name: 'Código',
                    },
                    {
                        key: 'card',
                        name: 'Card'
                    },
                    {
                        key: 'rgb',
                        name: 'RGB',
                    },
                ]}
                onDataRead={handleMassiveUpload}
            />
            <ModalDeleteConfirmation
                open={deletePromptActive}
                title='¿Está seguro de eliminar el color seleccionado?'
                onOk={onDeleteConfirm}
                onCancel={onDeleteCancel}
            />
        </Modal>
    );
}

export default ColorsModal;