import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Coupon, CouponFormData, GetCoupons } from '../../interfaces';
import { finishLoading, startLoading } from './ui';
import { AppThunk } from '../store';
import { mainApi } from '../../api';
import { NotificationInstance } from 'antd/es/notification/interface';

interface CouponsState {
    coupons: Coupon[];
    modalActive: boolean;
    formActive: boolean;
    selectedCoupon: Coupon | null;
}

const initialState: CouponsState = {
    coupons: [],
    modalActive: false,
    formActive: false,
    selectedCoupon: null,
};

const couponsSlice = createSlice({
    name: 'Coupons',
    initialState,
    reducers: {
        setCoupons(state, { payload }: PayloadAction<Coupon[]>) {
            state.coupons = payload;
        },
        openModal(state) {
            state.modalActive = true;
        },
        closeModal(state) {
            state.modalActive = false;
        },
        openForm(state) {
            state.formActive = true;
        },
        closeForm(state) {
            state.formActive = false;
        },
        setSelectedCoupon(state, { payload }: PayloadAction<Coupon | null>) {
            state.selectedCoupon = payload;
        },
    },
});

export const {
    setCoupons,
    openModal,
    closeModal,
    openForm,
    closeForm,
    setSelectedCoupon,
} = couponsSlice.actions;

export const getAllCoupons = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetCoupons>('/coupons');

            dispatch(setCoupons(data.coupons));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllCoupons', error);
            dispatch(finishLoading());
        }
    }
}

export const createCoupon = (data: CouponFormData, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/coupons', data);

            dispatch(getAllCoupons());
            dispatch(finishLoading());
            api.success({ message: 'El cupón ha sido agregado con éxito.' });
            callback();
        } catch (error) {
            console.log('createCoupon', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar agregar el cupón.' });
        }
    }
}

export const udpateCoupon = (idCoupon: number, data: CouponFormData, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/coupons/${idCoupon}`, {
                ...data, description: data.description ?? 'N/A',
            });

            dispatch(getAllCoupons());
            dispatch(finishLoading());
            api.success({ message: 'El cupón ha sido actualizado con éxito.' });
            callback();
        } catch (error) {
            console.log('udpateCoupon', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar actualizar el cupón.' });
        }
    }
}

export const deleteCoupon = (idCoupon: number, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/coupons/${idCoupon}`);

            dispatch(getAllCoupons());
            dispatch(finishLoading());
            api.success({ message: 'El cupón ha sido eliminado con éxito.' });
            callback();
        } catch (error) {
            console.log('deleteCoupon', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar eliminar el cupón.' });
        }
    }
}

export default couponsSlice.reducer;