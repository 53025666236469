import { FC, useState } from 'react';
import { Button, Popconfirm, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { Cancellation } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { StopOutlined } from '@ant-design/icons';
import { deleteCancellation, setSelectedCancellation, updateCancellation } from '../../redux/slices/cancellations';
import CancellationStatus from './CancellationStatus';


interface Props {
}

const TableCancellations: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const [deleteModalActive, setDeleteModalActive] = useState(false);

    const dispatch = useAppDispatch();
    const { cancellations, selectedCancellation } = useAppSelector(state => state.cancellations);

    const cancelOrder = (idCancellation: number) => {
        dispatch(updateCancellation(idCancellation, api));
    }

    const columns: ColumnsType<Cancellation> = [
        {
            title: 'ID',
            key: 'idCancellation', dataIndex: 'idCancellation',
        },
        {
            title: 'ID Pedido',
            key: 'idOrder', dataIndex: 'idOrder',
            render: (value) => '#' + value.toUpperCase(),
        },
        {
            title: 'Motivo',
            key: 'motive', dataIndex: 'motive',
            render: value => value ?? 'N/A',
        },
        {
            title: 'Fecha Solicitud',
            key: 'createdAt', dataIndex: 'createdAt',
            render: (value) => moment(value).format('LLL')
        },
        {
            title: 'Status',
            key: 'status', dataIndex: 'status',
            render: (value) => <CancellationStatus status={value} />
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn
                label='Factura'
                showEdit={false}
                showDetails={false}
                onDeleteClick={() => {
                    dispatch(setSelectedCancellation(record));
                    setDeleteModalActive(true);
                }}
                ExtraActions={
                    <>
                        {
                            record.status === 'pending'
                                ? <Popconfirm
                                    title='¿Está seguro de cancelar el pedido?'
                                    description='Esta acción no es revertible.'
                                    onConfirm={() => cancelOrder(record.idCancellation)}
                                    okText='ACEPTAR'
                                    cancelText='CANCELAR'
                                >
                                    <Button icon={<StopOutlined />} />
                                </Popconfirm>
                                : null
                        }
                    </>
                }
            />
        }
    ];

    const closeDeleteModal = () => {
        setDeleteModalActive(false);
        dispatch(setSelectedCancellation(null));
    }

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={cancellations} rowKey='idCancellation' />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar la cancelación seleccionada?'
                open={deleteModalActive}
                onCancel={closeDeleteModal}
                onOk={() => {
                    dispatch(deleteCancellation(selectedCancellation!.idCancellation, api, closeDeleteModal));
                }}
            />
        </>
    );
}

export default TableCancellations;