import { FC } from 'react';
import { Button, Col, Layout, Row, Space, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { mainLayoutStyles } from '../../styles';
import { logOut } from '../../redux/slices/auth';

interface Props {
}

const MainHeader: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { userData } = useAppSelector((state) => state.auth);

    const closeSession = () => {
        dispatch(logOut());
    }

    return (
        <Layout.Header style={mainLayoutStyles.header}>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Typography.Text>
                        {userData?.name} ({userData?.type.name})
                    </Typography.Text>
                </Col>
                <Col>
                    <Space>
                        <Typography.Text>
                            {userData?.email ?? '--'}
                        </Typography.Text>
                        <Button type='primary' onClick={closeSession}>
                            CERRAR SESIÓN
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Layout.Header>
    );
}

export default MainHeader;