import { FC, useEffect } from 'react';
import { Button, Col, Row, Space, Typography } from 'antd';
import { RetweetOutlined, SwitcherOutlined } from '@ant-design/icons';

import { button } from '../styles';
import { CouponsModal, DiscountFormModal, TableDiscounts } from '../components';
import { useAppDispatch } from '../hooks';
import { getAllDiscounts, openFormModal } from '../redux/slices/discounts';
import { getAllProducts } from '../redux/slices/products';
import { getAllCoupons, openModal } from '../redux/slices/coupons';

interface Props {
}

const Discounts: FC<Props> = () => {
    const dispatch = useAppDispatch();

    const getData = () => {
        dispatch(getAllDiscounts());
        dispatch(getAllProducts());
        dispatch(getAllCoupons());
    }

    useEffect(() => {
        getData();
    }, []);

    const openDiscountsFormModal = () => {
        dispatch(openFormModal());
    }

    const openCouponsModal = () => {
        dispatch(openModal());
    }

    return (
        <>
            <Typography.Title>Descuentos</Typography.Title>
            <Row justify='space-between'>
                <Col>
                    <Space>
                        <Button icon={<SwitcherOutlined />} style={button} onClick={openCouponsModal}>
                            Cupones
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button icon={<RetweetOutlined />} onClick={getData} />
                        <Button type='primary' style={button} onClick={openDiscountsFormModal}>
                            Agregar un nuevo descuento
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <TableDiscounts />
            </Row>
            <DiscountFormModal />
            <CouponsModal />
        </>
    );
}

export default Discounts;