import { FC, useEffect } from 'react';
import { Button, Col, Form, Input, notification, Row, Space, Typography } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { button } from '../../styles';
import { LineFormData } from '../../interfaces';
import { createLine, updateLine } from '../../redux/slices/lines';

interface Props {
    onClose: () => void;
    api: NotificationInstance;
}

const LineForm: FC<Props> = ({ onClose, api }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { selectedLine } = useAppSelector(state => state.lines);

    useEffect(() => {
        if (selectedLine) {
            form.setFieldsValue({
                name: selectedLine.name,
                description: selectedLine.description,
            });
        } else {
            form.resetFields();
        }
    }, [selectedLine, form]);

    const onFinish = (data: LineFormData) => {
        if (selectedLine) {
            dispatch(updateLine(selectedLine.idLine, data,
                (message) => {
                    api.success({ message });
                    onClose();
                },
                (message) => api.error({ message }),
            ));
        } else {
            dispatch(createLine(data,
                (message) => {
                    api.success({ message });
                    onClose();
                },
                (message) => api.error({ message })
            ));
        }
    }

    return (
        <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off'>
            <Row gutter={12}>
                <Col xs={{ span: 12 }}>
                    <Form.Item
                        label='Nombre:'
                        name='name'
                        rules={[
                            { required: true, message: 'Este campo es requerido.' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }}>
                    <Form.Item
                        label="Descripción:"
                        name="description"
                    >
                        <Input.TextArea rows={4} maxLength={500} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='end'>
                <Space>
                    <Button style={button} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type='primary' style={button} htmlType='submit'>
                        {selectedLine ? 'actualizar' : 'agregar'}
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default LineForm;