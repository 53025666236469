import { FC, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Space, notification } from 'antd';

import { getAllAnnouncements, setAnnouncementFormActiveAs, setAnnouncementHTMLSubmitActiveAs, setAnnouncementModalActiveAs, setSelectedAnnouncement } from '../../redux/slices/customers';
import { useAppDispatch, useAppSelector } from '../../hooks';
import TableAnnouncements from './TableAnnouncements';
import AnnouncementsForm from './AnnouncementsForm';
import { button } from '../../styles';
import { RetweetOutlined } from '@ant-design/icons';
import AnnouncementSubmit from './AnnouncementSubmit';

interface Props {
}

const AnnouncementsModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { announcementModalActive, selectedAnnouncement, announcementFormActive, announcementHTMLSubmitActive } = useAppSelector(state => state.customers);

    const title = useMemo(() => {
        if (announcementFormActive) {
            return selectedAnnouncement ? 'ACTUALIZAR ANUNCIO' : 'CREAR NUEVO ANUNCIO'
        }
        if (announcementHTMLSubmitActive)
            return 'ENVIAR HTML COMO ANUNCIO';
        return selectedAnnouncement ? 'DETALLES DE ANUNCIO' : 'GESTIÓN DE ANUNCIOS';
    }, [selectedAnnouncement, announcementFormActive, announcementHTMLSubmitActive])

    useEffect(() => {
        if (announcementModalActive) {
            dispatch(getAllAnnouncements());
        }
    }, [announcementModalActive]);

    const closeModal = () => {
        dispatch(setSelectedAnnouncement(null));
        dispatch(setAnnouncementFormActiveAs(false));
        dispatch(setAnnouncementHTMLSubmitActiveAs(false));
        dispatch(setAnnouncementModalActiveAs(false));
    }

    const openForm = () => {
        dispatch(setAnnouncementFormActiveAs(true));
    }

    const openHTMLSubmit = () => {
        dispatch(setAnnouncementHTMLSubmitActiveAs(true));
    }

    return (
        <Modal open={announcementModalActive} title={title} width='70%' footer={[]} onCancel={closeModal}>
            {contextHolder}
            {
                (announcementFormActive || announcementHTMLSubmitActive)
                    ? <>
                        {
                            announcementFormActive
                                ? <AnnouncementsForm />
                                : null
                        }
                        {
                            announcementHTMLSubmitActive
                                ? <AnnouncementSubmit api={api} />
                                : null
                        }
                    </>
                    : <>
                        <Row justify='end'>
                            <Space>
                                <Button icon={<RetweetOutlined />} onClick={() => dispatch(getAllAnnouncements())} />
                                <Button type='primary' style={button} onClick={openForm}>
                                    Crear nuevo anuncio
                                </Button>
                                <Button type='primary' style={button} onClick={openHTMLSubmit}>
                                    Enviar HTML como Anuncio
                                </Button>
                            </Space>
                        </Row>
                        <Row>
                            <TableAnnouncements />
                        </Row>
                    </>
            }
        </Modal>
    );
}

export default AnnouncementsModal;