import { FC, useState } from 'react';
import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ProductVariantFormData } from '../../interfaces';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { deleteVariant } from '../../redux/slices/productsForm';
import { deleteVariantInDatabase } from '../../redux/slices/products';

interface Props {
    onEdit: (record: ProductVariantFormData, index: number) => void;
}

const VariantsTable: FC<Props> = ({ onEdit }) => {
    const [deleteConfirmationActive, setDeleteConfirmationActive] = useState(false);
    const [selectedVariantIndex, setSelectedVariantIndex] = useState<number | null>(null);
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { variants } = useAppSelector(state => state.productsForm);
    const { measurements, units } = useAppSelector(state => state.measurements);

    const columns: ColumnsType<ProductVariantFormData> = [
        {
            title: 'ID',
            key: 'idVariant', dataIndex: 'idVariant',
            render: (value) => value ?? 'N/A',
        },
        {
            title: 'Clave',
            key: 'productKey', dataIndex: 'productKey',
        },
        {
            title: 'Código de Barras',
            key: 'barcode', dataIndex: 'barcode',
        },
        {
            title: 'Medida',
            key: 'idMeasurement', dataIndex: 'idMeasurement',
            render: (idMeasurement) => {
                const { container, quantity, idUnit } = measurements.find(m => m.idMeasurement === idMeasurement)!;
                return `${container} ${quantity} ${units.find(u => u.idUnit === idUnit)!.abbreviation}`
            }
        },
        {
            title: 'Peso (Kg)',
            key: 'weight', dataIndex: 'weight'
        },
        {
            title: 'Imágenes',
            key: 'files', dataIndex: 'files',
            render: (value, record) => value.length + (record.images ? record.images.length : 0),
        },
        {
            title: 'Acciones',
            render: (_, record, index) => <ActionsColumn
                label='Presentación'
                showDetails={false}
                onEditClick={() => {
                    onEdit(record, index);
                }}
                onDeleteClick={() => {
                    if (record.idVariant) {
                        setDeleteConfirmationActive(true);
                        setSelectedVariantIndex(index);
                    } else {
                        dispatch(deleteVariant(index));
                    }
                }}
            />
        }
    ];

    const closeDeleteConfirmation = () => {
        setDeleteConfirmationActive(false);
        setSelectedVariantIndex(null);
    }

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={variants} rowKey='productKey' showSearchBar={false} />
            <ModalDeleteConfirmation
                open={deleteConfirmationActive}
                title='¿Está seguro de eliminar la presentación seleccionada?'
                onCancel={closeDeleteConfirmation}
                onOk={() => {
                    dispatch(deleteVariant(selectedVariantIndex!));
                    dispatch(deleteVariantInDatabase(variants[selectedVariantIndex!].idVariant!,
                        () => {
                            api.success({ message: 'La presentación ha sido eliminada con éxito.' });
                            closeDeleteConfirmation();
                        },
                        () => api.error({ message: 'Ha ocurrido un error al intentar eliminar la presentación' }),
                    ));
                }}
            />
        </>
    );
}

export default VariantsTable;