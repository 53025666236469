
const useFormConstants = () => {
    const gutter = 12;
    const xs = { span: 12 };
    
    return {
        gutter,
        xs
    };
}

export default useFormConstants;