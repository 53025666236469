import { FC } from 'react';
import { Image, Layout } from 'antd';

import { mainLayoutStyles } from '../../styles';
import MainSiderMenu from './MainSiderMenu';

interface Props {
}

const MainSider: FC<Props> = () => {
    return (
        <Layout.Sider style={mainLayoutStyles.fixedSider}>
            <div style={mainLayoutStyles.siderLogoContainer}>
                <Image
                    width={100}
                    src='/famosas.png'
                    preview={false}
                />
            </div>
            <MainSiderMenu />
        </Layout.Sider>
    );
}

export default MainSider;