import { FC } from 'react';
import { Button, Card, Col, Row, Space, Tooltip, Typography, notification } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import { Order } from '../../interfaces';
import { Parser } from '../../utils';
import { useAppDispatch } from '../../hooks';
import { deleteOrder } from '../../redux/slices/orders';

interface Props {
    data: Order;
    onOrderConfirm: (idOrder: string) => void;
}

const UnconfirmedOrderCard: FC<Props> = ({ data, onOrderConfirm }) => {
    const dispatch = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const onDelete = () => {
        dispatch(deleteOrder(data.idOrder, api))
    }
    
    return (
        <Card size='small' style={{ marginBottom: '0.75rem' }}>
            {contextHolder}
            <Row align='middle'>
                <Col xs={{ span: 20 }}>
                    <Row>
                        <Typography.Text strong>
                            PEDIDO #{data.idOrder.toUpperCase()}
                        </Typography.Text>
                    </Row>
                    <Row>
                        <Space size='large'>
                            <Typography.Text>
                                Realizado por: {data.customerName}
                            </Typography.Text>
                            <Typography.Text>
                                Fecha: {moment(data.orderDate).format('LLL')}
                            </Typography.Text>
                        </Space>
                    </Row>
                    <Row>
                        <Space size='large'>
                            <Typography.Text>
                                Productos: {data.products.length}
                            </Typography.Text>
                            <Typography.Text>
                                Subtotal: {Parser.parseToPrice(data.subtotal)}
                            </Typography.Text>
                            <Typography.Text>
                                Envío: {data.shippingFee === 0 ? 'GRATIS' : Parser.parseToPrice(data.shippingFee)}
                            </Typography.Text>
                            <Typography.Text>
                                Total: {Parser.parseToPrice(data.total)}
                            </Typography.Text>
                        </Space>
                    </Row>
                </Col>
                <Col xs={{ span: 4 }}>
                    <Row justify='center'>
                        <Space size='middle'>
                            <Tooltip title='Confirmar Pedido'>
                                <Button size='large' shape='circle' icon={<CheckOutlined />} onClick={() => onOrderConfirm(data.idOrder)} />
                            </Tooltip>
                            <Tooltip title='Eliminar Pedido'>
                                <Button size='large' shape='circle' icon={<DeleteOutlined />} onClick={onDelete} />
                            </Tooltip>
                        </Space>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default UnconfirmedOrderCard;