import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mainApi } from '../../api';

import { ExcelMeasurementRow, GetMeasurements, GetUnits, Measurement, MeasurementFormData, Unit } from '../../interfaces';
import { FeedbackFunction } from '../../types';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';

interface MeasurementsState {
    measurements: Measurement[];
    modalActive: boolean;
    selectedMeasurement: Measurement | null;
    units: Unit[];
}

const initialState: MeasurementsState = {
    measurements: [],
    modalActive: false,
    selectedMeasurement: null,
    units: [],
};

const measurementsSlice = createSlice({
    name: 'Measurements',
    initialState,
    reducers: {
        openMeasurementsModal(state) {
            state.modalActive = true;
        },
        closeMeasurementsModal(state) {
            state.modalActive = false;
        },
        setMeasurements(state, { payload }: PayloadAction<Measurement[]>) {
            state.measurements = payload;
        },
        setSelectedMeasurement(state, { payload }: PayloadAction<Measurement | null>) {
            state.selectedMeasurement = payload;
        },
        setUnits(state, { payload }: PayloadAction<Unit[]>) {
            state.units = payload;
        },
    },
});

export const {
    openMeasurementsModal,
    closeMeasurementsModal,
    setMeasurements,
    setSelectedMeasurement,
    setUnits,
} = measurementsSlice.actions;

export const getAllUnits = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetUnits>('/units');

            dispatch(setUnits(data.units));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllUnits', error);
            dispatch(finishLoading());
        }
    }
}

export const getAllMeasurements = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetMeasurements>('/measurements');

            dispatch(setMeasurements(data.measurements));
            dispatch(getAllUnits());
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllMeasurements', error);
            dispatch(finishLoading());
        }
    }
}

export const createMeasurement = (data: MeasurementFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/measurements', data);

            dispatch(finishLoading());
            dispatch(getAllMeasurements());
            onSuccess('La medida ha sigo agregada con éxito.');
        } catch (error) {
            console.log('createMeasurement', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar agregar la medida.');
        }
    }
}

export const updateMeasurement = (id: number, data: MeasurementFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/measurements/${id}`, data);

            dispatch(finishLoading());
            dispatch(getAllMeasurements());
            onSuccess('La medida ha sido actualizada con éxito.');
        } catch (error) {
            console.log('updateMeasurement', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar actualizar la medida.');
        }
    }
}

export const deleteMeasurement = (id: number, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/measurements/${id}`);

            dispatch(finishLoading());
            dispatch(getAllMeasurements());
            onSuccess('La medida ha sido eliminada con éxito');
        } catch (error) {
            console.log('deleteMeasurement', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar eliminar la medida.');
        }
    }
}

export const uploadMeasurementsBatch = (data: ExcelMeasurementRow[], onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(
                Array.from(Array(Math.ceil(data.length / 500)).keys()).map((i) =>
                    mainApi.post('/measurements/massiveUpload',
                        {
                            data: data.slice(0 + (i * 500), 500 + (i * 500)),
                        }
                    )
                )
            );

            dispatch(finishLoading());
            dispatch(getAllMeasurements());
            onSuccess('La carga masiva de medidas ha sido completada con éxito.');
        } catch (error) {
            console.log('uploadMeasurementsBath', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar hacer la carga masiva de medidas.');
        }
    }
}

export default measurementsSlice.reducer;