import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { mainApi } from "../../api";
import { AppThunk } from "../store";
import { GetUsers, User, UserCreationFormData, UserUpdateFormData } from "../../interfaces";
import { setLoading } from "./ui";

interface UsersState {
    users: User[];
    selectedUser: User | null;
    userDetailsModalActive: boolean;
    userFormModalActive: boolean;
}

const initialState: UsersState = {
    users: [],
    selectedUser: null,
    userDetailsModalActive: false,
    userFormModalActive: false,
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers(state, { payload }: PayloadAction<User[]>) {
            state.users = payload;
        },
        setSelectedUser(state, { payload }: PayloadAction<User | null>) {
            state.selectedUser = payload;
        },
        setUserDetailsModalActive(state, { payload }: PayloadAction<boolean>) {
            state.userDetailsModalActive = payload;
        },
        setUserFormModalActive(state, { payload }: PayloadAction<boolean>) {
            state.userFormModalActive = payload;
        },
    },
});

export const {
    setUsers,
    setSelectedUser,
    setUserDetailsModalActive,
    setUserFormModalActive,
} = usersSlice.actions;

export const getAllUsers = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const { data } = await mainApi.get<GetUsers>('/users');

            dispatch(setUsers(data.users));
            dispatch(setLoading(false));
        } catch (error) {
            console.log('getAllUsers', error);
            dispatch(setLoading(false));
        }
    }
}

export const createUser = (formData: UserCreationFormData, onSuccess: () => void, onError: () => void): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const { name, email, phoneNumber, idType, password } = formData;

            await mainApi.post('/users', {
                name,
                email,
                phoneNumber,
                idType,
                password,
            });

            dispatch(getAllUsers());
            dispatch(setLoading(false));
            onSuccess();
        } catch (error) {
            console.log('createUser', error);
            dispatch(setLoading(false));
            onError();
        }
    }
}

export const updateUser = (id: number, formData: UserUpdateFormData, onSuccess: () => void, onError: () => void): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const { name, email, phoneNumber, idType, password, repeatPassword } = formData;

            console.log(formData);

            const dataToUpdate: any = {
                name, email, phoneNumber, idType
            };
            if (password.trim().length > 0) {
                dataToUpdate.oldPassword = password;
            }
            if (repeatPassword.trim().length > 0) {
                dataToUpdate.password = repeatPassword;
            }

            await mainApi.put(`/users/${id}`, dataToUpdate);

            dispatch(getAllUsers());
            dispatch(setLoading(false));
            onSuccess();
        } catch (error) {
            console.log('updateUser', error);
            dispatch(setLoading(false));
            onError();
        }
    }
}

export const deleteUser = (id: number, onSuccess: () => void, onError: () => void): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading)

            await mainApi.delete(`/users/${id}`);

            dispatch(getAllUsers());
            dispatch(setLoading(false));
            onSuccess();
        } catch (error) {
            console.log('deleteUser', error);
            dispatch(setLoading(false));
            onError();
        }
    }
}

export default usersSlice.reducer;