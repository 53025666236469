import { FC, useState } from 'react';
import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteDiscount, openFormModal, setSelectedDiscount } from '../../redux/slices/discounts';

interface Props {
}

const TableDiscounts: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { discounts, selectedDiscount } = useAppSelector(state => state.discounts);

    const [modalDeleteActive, setModalDeleteActive] = useState(false);

    const columns: ColumnsType = [
        {
            title: 'ID',
            key: 'idDiscount', dataIndex: 'idDiscount',
        },
        {
            title: 'Nombre',
            key: 'name', dataIndex: 'name',
        },
        {
            title: 'Descuento',
            key: 'discountPercentage', dataIndex: 'discountPercentage',
            render: (value) => `${value}%`
        },
        {
            title: 'Fecha Inicial',
            key: 'startDate', dataIndex: 'startDate',
            render: (value) => moment(value).format('LLLL'),
        },
        {
            title: 'Fecha Final',
            key: 'endDate', dataIndex: 'endDate',
            render: (value) => moment(value).format('LLLL'),
        },
        {
            title: 'Acciones',
            render: (record) => <ActionsColumn
                label='Descuentos'
                showDetails={false}
                onEditClick={() => {
                    dispatch(setSelectedDiscount(record));
                    dispatch(openFormModal());
                }}
                onDeleteClick={() => {
                    dispatch(setSelectedDiscount(record));
                    setModalDeleteActive(true);
                }}
            />
        }
    ];

    const closeModal = () => {
        setModalDeleteActive(false);
        dispatch(setSelectedDiscount(null));
    }

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={discounts} rowKey='idDiscount' />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar el descuento seleccionado?'
                open={modalDeleteActive}
                onCancel={closeModal}
                onOk={() => {
                    dispatch(deleteDiscount(selectedDiscount!.idDiscount,
                        (message) => {
                            api.success({ message });
                            closeModal();
                        },
                        (message) => api.error({ message })
                    ))
                }}
            />
        </>
    );
}

export default TableDiscounts;