import { FC, useMemo, useState } from 'react';
import { Button, notification, Space, Tag, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { User } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { DataTable, ModalDeleteConfirmation } from '../ui';
import { getColorByType } from './helpers';
import { deleteUser, setSelectedUser, setUserDetailsModalActive, setUserFormModalActive } from '../../redux/slices/users';
import { Parser } from '../../utils';

interface Props {
}

const TableUsers: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { userData } = useAppSelector((state) => state.auth);
    const { users, selectedUser } = useAppSelector((state) => state.users);
    const { types } = useAppSelector((state) => state.types);

    const [deleteConfirmationModalActive, setDeleteConfirmationModalActive] = useState(false);

    const canEdit = useMemo(() => {
        return (userData?.type.enum === 1);
    }, [userData]);

    const canDelete = (email: string) => {
        return (userData?.type.enum === 1 && email !== userData.email);
    }

    const selectUserAndOpenDetails = (user: User) => {
        dispatch(setSelectedUser(user));
        dispatch(setUserDetailsModalActive(true));
    }

    const selectUserAndOpenForm = (user: User) => {
        dispatch(setSelectedUser(user));
        dispatch(setUserFormModalActive(true));
    }

    const promptUserDelete = (user: User) => {
        dispatch(setSelectedUser(user));
        setDeleteConfirmationModalActive(true)
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Correo',
            key: 'email', dataIndex: 'email',
        },
        {
            title: 'Núm. Tel.',
            key: 'phoneNumber', dataIndex: 'phoneNumber',
            render: (value) => value === 'N/A' ? value : Parser.parsePhoneNumber(value),
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            render: (_, record) => {
                const type = types.find((type) => type.idType === record.idType);
                return (
                    <Tag color={getColorByType(type?.typeEnumeration ?? 0)}>
                        {type?.name ?? 'N/A'}
                    </Tag>
                );
            },
            sorter: (a, b) => {
                const aType = types.find((type) => type.idType === a.idType);
                const bType = types.find((type) => type.idType === b.idType);
                return (aType?.typeEnumeration ?? 0) - (bType?.typeEnumeration ?? 0);
            },
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Tooltip title='Ver detalles'>
                        <Button
                            icon={<EyeOutlined />}
                            onClick={() => {
                                selectUserAndOpenDetails(record);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title='Editar usuario'>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => {
                                selectUserAndOpenForm(record);
                            }}
                            disabled={
                                (record.email === 'admin@pinturasfamosas.com.mx' && userData?.email !== 'admin@pinturasfamosas.com.mx')
                                ||
                                !canEdit
                            }
                        />
                    </Tooltip>
                    <Tooltip title='Eliminar usuario'>
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                promptUserDelete(record);
                            }}
                            disabled={!canDelete(record.email)}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    const closeModal = () => {
        dispatch(setSelectedUser(null));
        setDeleteConfirmationModalActive(false);
    }

    const deleteUserAndCloseModal = () => {
        if (selectedUser) {
            dispatch(deleteUser(selectedUser.idUser,
                () => {
                    api.success({
                        message: 'El usuario ha sido eliminado con éxito.',
                    });
                },
                () => {
                    api.error({
                        message: 'Ha ocurrido un error al intentar eliminar al usuario.',
                    });
                }
            ));
        }
        dispatch(setSelectedUser(null));
        setDeleteConfirmationModalActive(false);
    }


    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={users} rowKey='idUser' />
            <ModalDeleteConfirmation
                open={deleteConfirmationModalActive}
                title='¿Está seguro de eliminar el usuario seleccionado?'
                onOk={deleteUserAndCloseModal}
                onCancel={closeModal}
            />
        </>
    );
}

export default TableUsers;