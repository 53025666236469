import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { mainApi } from '../../api';
import { ExcelLineRow, GetLines, Line, LineFormData } from '../../interfaces';
import { FeedbackFunction } from '../../types';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';

interface LinesState {
    lines: Line[];
    modalActive: boolean;
    selectedLine: Line | null;
}

const initialState: LinesState = {
    lines: [],
    modalActive: false,
    selectedLine: null,
};

const linesSlice = createSlice({
    name: 'Lines',
    initialState,
    reducers: {
        setLines(state, { payload }: PayloadAction<Line[]>) {
            state.lines = payload;
        },
        openLinesModal(state) {
            state.modalActive = true;
        },
        closeLinesModal(state) {
            state.modalActive = false;
        },
        setSelectedLine(state, { payload }: PayloadAction<Line | null>) {
            state.selectedLine = payload;
        },
    },
});

export const {
    setLines,
    openLinesModal,
    closeLinesModal,
    setSelectedLine,
} = linesSlice.actions;

export const getAllLines = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetLines>('/lines');

            dispatch(setLines(data.lines));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllLines', error);
            dispatch(finishLoading());
        }
    }
}

export const createLine = ({ name, description }: LineFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data: { name: string; description?: string; } = { name };
            if (description) data.description = description;

            await mainApi.post('/lines', data)

            dispatch(finishLoading());
            dispatch(getAllLines());
            onSuccess('La línea ha sido agregada con éxito.');
        } catch (error) {
            console.log('createLine', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar agregar la línea.')
        }
    }
}

export const updateLine = (idLine: number, { name, description }: LineFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data: { name: string; description?: string; } = { name };
            if (description) data.description = description;

            await mainApi.put(`/lines/${idLine}`, data)

            dispatch(finishLoading());
            dispatch(getAllLines());
            onSuccess('La línea ha sido actualizada con éxito.');
        } catch (error) {
            console.log('updateLine', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar actualizar la línea.');
        }
    }
}

export const deleteLine = (idLine: number, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/lines/${idLine}`);

            dispatch(finishLoading());
            dispatch(getAllLines());
            onSuccess('La línea ha sido eliminada con éxito.');
        } catch (error) {
            console.log('deleteLine', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar eliminar la línea.');
        }
    }
}

export const uploadLinesBatch = (data: ExcelLineRow[], onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(
                Array.from(Array(Math.ceil(data.length / 500)).keys()).map((i) =>
                    mainApi.post('/lines/massiveUpload',
                        {
                            data: data.slice(0 + (i * 500), 500 + (i * 500)),
                        }
                    )
                )
            );

            dispatch(finishLoading());
            dispatch(getAllLines());
            onSuccess('La carga masiva de líneas ha sido completada con éxito.');
        } catch (error) {
            console.log('handleMassiveUpload', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar realizar la carga masiva de líneas.');
        }
    }
}

export default linesSlice.reducer;