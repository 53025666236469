import { FC, useState } from 'react';
import { ColumnsType } from 'antd/es/table';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { Product } from '../../interfaces';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { deleteProduct, openDetailsModal, openProductsFormModal, setSelectedProduct } from '../../redux/slices/products';
import { notification } from 'antd';

interface Props {
}

const TableProducts: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { products, selectedProduct } = useAppSelector(state => state.products);
    const { stores } = useAppSelector(state => state.stores);
    const { categories } = useAppSelector(state => state.categories);
    const { lines } = useAppSelector(state => state.lines);

    const [modalDeleteActive, setModalDeleteActive] = useState(false)

    const columns: ColumnsType<Product> = [
        {
            title: 'ID',
            dataIndex: 'idProduct',
            key: 'idProduct'
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Tienda',
            dataIndex: 'idStore',
            key: 'idStore',
            render: (value) => value === null ? 'N/A' : stores.find(s => s.idStore === value)?.name ?? 'N/A'
        },
        {
            title: 'Categoría',
            dataIndex: 'idCategory',
            key: 'idCategory',
            render: (value) => value === null ? 'N/A' : categories.find(c => c.idCategory === value)?.name ?? 'N/A'
        },
        {
            title: 'Línea',
            dataIndex: 'idLine',
            key: 'idLine',
            render: (value) => value === null ? 'N/A' : lines.find(l => l.idLine === value)?.name ?? 'N/A'
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => <ActionsColumn
                label='Producto'
                onDetailsClick={() => {
                    dispatch(setSelectedProduct(record));
                    dispatch(openDetailsModal());
                }}
                onEditClick={() => {
                    dispatch(setSelectedProduct(record));
                    dispatch(openProductsFormModal());
                }}
                onDeleteClick={() => {
                    dispatch(setSelectedProduct(record));
                    setModalDeleteActive(true);
                }}
            />
        }
    ];

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={products} rowKey='idProduct' />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar el producto seleccionado?'
                open={modalDeleteActive}
                onCancel={() => {
                    dispatch(setSelectedProduct(null))
                    setModalDeleteActive(false);
                }}
                onOk={() => {
                    if (!selectedProduct) {
                        setModalDeleteActive(false);
                        return;
                    }
                    dispatch(
                        deleteProduct(selectedProduct.idProduct,
                            (message) => {
                                api.success({ message });
                                dispatch(setSelectedProduct(null));
                                setModalDeleteActive(false);
                            },
                            (message) => api.error({ message }),
                        )
                    )

                }}
            />
        </>
    );
}

export default TableProducts;