import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mainApi } from '../../api';

import { Color, ColorForm, ExcelColorRow, GetColors } from '../../interfaces';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';

interface ColorsState {
    colors: Color[];
    modalActive: boolean;
    seasonalModalActive: boolean;
    specialModalActive: boolean;
}

const initialState: ColorsState = {
    colors: [],
    modalActive: false,
    seasonalModalActive: false,
    specialModalActive: false,
}

const colorsSlice = createSlice({
    name: 'colors',
    initialState,
    reducers: {
        setColors(state, { payload }: PayloadAction<Color[]>) {
            state.colors = payload;
        },
        openColorsModal(state) {
            state.modalActive = true;
        },
        closeColorsModal(state) {
            state.modalActive = false;
        },
        openSeasonalColorsModal(state) {
            state.seasonalModalActive = true;
        },
        closeSeasonalColorsModal(state) {
            state.seasonalModalActive = false;
        },
        openSpecialColorsModal(state) {
            state.specialModalActive = true;
        },
        closeSpecialColorsModal(state) {
            state.specialModalActive = false;
        },
    },
});

export const {
    setColors,
    openColorsModal,
    closeColorsModal,
    openSeasonalColorsModal,
    closeSeasonalColorsModal,
    openSpecialColorsModal,
    closeSpecialColorsModal,
} = colorsSlice.actions;

export const getAllColors = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi<GetColors>('/colors');

            dispatch(setColors(data.colors));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllColors', error);
            dispatch(finishLoading());
        }
    }
}

export const createColor = ({ name, code, card, color }: ColorForm, onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/colors', {
                name, code, card,
                rgb: `(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`,
            });

            dispatch(finishLoading());
            onSuccess();
            dispatch(getAllColors());
        } catch (error) {
            console.log('createColor', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export const updateColor = (id: number, { name, code, card, color }: ColorForm, onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/colors/${id}`, {
                name, code, card,
                rgb: `(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`,
            });

            dispatch(finishLoading());
            onSuccess();
            dispatch(getAllColors());
        } catch (error) {
            console.log('updateColor', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export const deleteColor = (id: number, onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/colors/${id}`);

            dispatch(finishLoading());
            onSuccess();
            dispatch(getAllColors());
        } catch (error) {
            console.log('deleteColor', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export const uploadColorsBatch = (data: ExcelColorRow[], onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(
                Array.from(Array(Math.ceil(data.length / 500)).keys()).map((i) =>
                    mainApi.post('/colors/massiveUpload',
                        {
                            data: data.slice(0 + (i * 500), 500 + (i * 500)),
                        }
                    )
                )
            )

            onSuccess();
            dispatch(getAllColors());
            dispatch(finishLoading());
        } catch (error) {
            console.log('uploadColorsBatch', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export const saveSeasonalColors = (colors: Color[], deletedSeasonalColors: Color[], onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(colors.map(c =>
                mainApi.put(`/colors/${c.idColor}`, { ...c,
                    isSpecial: c.isSpecial ? 1 : 0,
                    isSeasonal: 1 
                }),
            ));

            await Promise.all(deletedSeasonalColors.map(c =>
                mainApi.put(`/colors/${c.idColor}`, { ...c, 
                    isSpecial: c.isSpecial ? 1 : 0,
                    isSeasonal: 0 
                }),
            ));

            dispatch(finishLoading());
            onSuccess();
            dispatch(getAllColors());
        } catch (error) {
            console.log('saveSeasonalColors', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export const saveSpecialColors = (colors: Color[], deletedSpecialColors: Color[], onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(colors.map(c =>
                mainApi.put(`/colors/${c.idColor}`, { ...c, 
                    isSeasonal: c.isSeasonal ? 1 : 0,
                    isSpecial: 1 
                }),
            ));

            await Promise.all(deletedSpecialColors.map(c =>
                mainApi.put(`/colors/${c.idColor}`, { ...c, 
                    isSeasonal: c.isSeasonal ? 1 : 0,
                    isSpecial: 0 
                }),
            ));

            dispatch(finishLoading());
            onSuccess();
            dispatch(getAllColors());
        } catch (error) {
            console.log('saveSeasonalColors', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export default colorsSlice.reducer;