import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from 'antd';
import { FC } from 'react';
import { useFormConstants, useFormRules, useShippingCostForm } from '../../hooks';
import { button } from '../../styles';

interface Props {
}

const ShippingCostFormModal: FC<Props> = () => {
    const {
        form,
        title,
        buttonText,
        contextHolder,
        formModalActive,
        closeModal,
        onFinish
    } = useShippingCostForm();

    const { required } = useFormRules();
    const { gutter, xs } = useFormConstants();

    return (
        <Modal open={formModalActive} title={title} footer={[]} onCancel={closeModal} width='70%'>
            {contextHolder}
            <Form layout='vertical' autoComplete='off' form={form} onFinish={onFinish}>
                <Row gutter={gutter}>
                    <Col xs={xs}>
                        <Form.Item label='Destino' name='destination' rules={[required]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={xs}>
                        <Form.Item label='Municipio' name='municipality' rules={[required]}>
                            <Select
                                options={[
                                    { value: 1, label: 'La Paz' },
                                    { value: 2, label: 'Los Cabos' },
                                    { value: 3, label: 'Comondú' },
                                    { value: 4, label: 'Loreto' },
                                    { value: 5, label: 'Mulegé' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={gutter}>
                    <Col xs={{ span: 8 }}>
                        <Form.Item label='0.01 a 20 Kg' name='costTier1' rules={[required]}>
                            <InputNumber
                                addonAfter='MXN'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 8 }}>
                        <Form.Item label='20 a 40 Kg' name='costTier2' rules={[required]}>
                            <InputNumber
                                addonAfter='MXN'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 8 }}>
                        <Form.Item label='40 a 60 Kg' name='costTier3' rules={[required]}>
                            <InputNumber
                                addonAfter='MXN'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='end'>
                    <Space>
                        <Button style={button} onClick={closeModal}>
                            Cancelar
                        </Button>
                        <Button style={button} type='primary' htmlType='submit'>
                            {buttonText}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default ShippingCostFormModal;