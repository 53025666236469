import axios from "axios";
import Cookies from "js-cookie";

import { Configs } from '../utils';

const mainApi = axios.create({
    baseURL: `${Configs.API_URL}api/v1`,
});

mainApi.interceptors.request.use((config: any) => {
    config.headers = {
        ...config.headers,
        'x-token': Cookies.get('x-token') ?? '',
    };
    return config;
});

export default mainApi;