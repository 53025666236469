import { FC, useState } from 'react';
import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { Line } from '../../interfaces';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { deleteLine, setSelectedLine } from '../../redux/slices/lines';

interface Props {
    onEdit: (record: Line) => void;
}

const LinesTable: FC<Props> = ({ onEdit }) => {
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useAppDispatch();
    const { lines, selectedLine } = useAppSelector(state => state.lines);

    const [deleteModalActive, setDeleteModalActive] = useState(false);

    const columns: ColumnsType<Line> = [
        {
            title: 'ID',
            key: 'idLine', dataIndex: 'idLine',
        },
        {
            title: 'Nombre',
            key: 'name', dataIndex: 'name',
        },
        {
            title: 'Descripción',
            key: 'description', dataIndex: 'description',
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn
                label='Línea'
                showDetails={false}
                onEditClick={() => {
                    onEdit(record);
                }}
                onDeleteClick={() => {
                    setDeleteModalActive(true);
                    dispatch(setSelectedLine(record));
                }}
            />
        }
    ]

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={lines} rowKey='idLine' />
            <ModalDeleteConfirmation
                open={deleteModalActive}
                title='¿Está seguro de eliminar la línea seleccionada?'
                onOk={() => {
                    if (!selectedLine) return setDeleteModalActive(false);
                    dispatch(deleteLine(selectedLine.idLine,
                        (message) => {
                            api.success({ message });
                            setDeleteModalActive(false);
                            dispatch(setSelectedLine(null));
                        },
                        (message) => api.error({ message }),
                    ));
                }}
                onCancel={() => {
                    setDeleteModalActive(false);
                    dispatch(setSelectedLine(null));
                }}
            />
        </>
    );
}

export default LinesTable;