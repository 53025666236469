import { FC, useEffect, useState } from 'react';
import { Alert, Col, Form, Input, Modal, notification, Row, Select } from 'antd';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { createUser, setSelectedUser, setUserFormModalActive, updateUser } from '../../redux/slices/users';
import { UserCreationFormData, Type, UserUpdateFormData, GetTypes } from '../../interfaces';
import { setLoading } from '../../redux/slices/ui';
import { mainApi } from '../../api';

interface Props {
}

const UserFormModal: FC<Props> = () => {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useAppDispatch();
    const { userFormModalActive, selectedUser } = useAppSelector((state) => state.users);

    const [userTypes, setUserTypes] = useState<Type[]>([]);

    const getUserTypes = async () => {
        try {
            dispatch(setLoading(true));

            const { data } = await mainApi.get<GetTypes>('/types/user');

            setUserTypes(data.types);
            dispatch(setLoading(false));
        } catch (error) {
            console.log('getUserTypes', error);
            dispatch(setLoading(false));
            api.warning({
                message: 'Error al obtener los roles de usuario.',
                description: 'Ha ocurrido un error al intentar obtener los roles de usuario, por favor intente más tarde.',
            });
        }
    }

    useEffect(() => {
        if (userFormModalActive === true) {
            getUserTypes();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userFormModalActive]);

    useEffect(() => {
        if (selectedUser) {
            form.setFieldsValue({
                name: selectedUser.name,
                email: selectedUser.email,
                phoneNumber: selectedUser.phoneNumber,
                idType: selectedUser.idType
            });
        } else {
            form.resetFields();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser]);

    const closeModal = () => {
        form.resetFields();
        dispatch(setSelectedUser(null));
        dispatch(setUserFormModalActive(false));
    }

    const onFinish = (data: UserCreationFormData | UserUpdateFormData) => {
        if (selectedUser) {
            if (data.password || data.repeatPassword) {
                if (!data.password || !data.repeatPassword) {
                    return api.warning({
                        description: 'Contraseñas inválidas',
                        message: 'Para actualizar la contraseña ambos campos son requeridos.'
                    });
                }
            }

            dispatch(updateUser(selectedUser.idUser, data as UserUpdateFormData,
                () => {
                    api.success({
                        message: 'El usuario ha sido actualizado con éxito.',
                    });
                    closeModal();
                },
                () => {
                    api.error({
                        message: 'Ha ocurrido un error al intentar actualizar al usuario.',
                    });
                }),
            )
        } else {
            if (data.password !== data.repeatPassword) {
                return api.warning({
                    message: 'Las contraseñas no coinciden.'
                });
            }
            dispatch(createUser(data as UserCreationFormData,
                () => {
                    api.success({
                        message: 'El usuario ha sido agregado con éxito.',
                    });
                    closeModal();
                },
                () => {
                    api.error({
                        message: 'Ha ocurrido un error al intentar agregar al usuario.',
                    });
                }),
            );
        }
    }

    return (
        <Modal
            title={selectedUser ? 'ACTUALIZAR USUARIO' : 'AGREGAR NUEVO USUARIO'}
            open={userFormModalActive}
            onOk={() =>  form.submit()}
            onCancel={closeModal}
            cancelText='CANCELAR'
            okText={selectedUser ? `ACTUALIZAR` : 'AGREGAR'}
            width='60%'
        >
            {contextHolder}
            <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off'>
                <Row gutter={12}>
                    <Col xs={{ span: 12 }}>
                        <Form.Item
                            label='Nombre Completo:'
                            name='name'
                            rules={[
                                { required: true, message: 'Este campo es requerido.' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <Form.Item
                            label="Correo:"
                            name="email"
                            rules={[
                                { required: true, message: 'El correo es requerido.' },
                                { type: 'email', message: 'La dirección de correo no es válida.' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col xs={{ span: 12 }}>
                        <Form.Item
                            label='Núm. Tel:'
                            name='phoneNumber'
                            rules={[
                                { required: true, message: 'Este campo es requerido.' },
                                { min: 10, message: 'El número telefónico debe ser de 10 dígitos.' },
                                { max: 10, message: 'Máximo sólo 10 dígitos.' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <Form.Item name="idType" label="Tipo:" rules={[{ required: true, message: 'Este campo es requerido.' }]}>
                            <Select>
                                {
                                    userTypes.map((userType) =>
                                        <Select.Option key={userType.idType} value={userType.idType}>
                                            {userType.name}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col xs={{ span: 12 }}>
                        <Form.Item
                            label={selectedUser ? 'Antigua Contraseña:' : 'Contraseña:'}
                            name="password"
                            rules={selectedUser ? [] : [{ required: true, message: 'La contraseña es requerida.' }]}
                            initialValue=''
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <Form.Item
                            label={selectedUser ? 'Nueva Contraseña' : 'Repetir Contraseña:'}
                            name="repeatPassword"
                            rules={selectedUser ? [] : [{ required: true, message: 'La contraseña es requerida.' }]}
                            initialValue=''
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    selectedUser
                        ? <Row>
                            <Col xs={{ span: 24 }}>
                                <Alert message='Para comprobar que las contraseñas se han actualizado es recomendable cerrar la sesión y probar con los nuevos datos de acceso, en caso de no verse reflejada la nueva contraseña es probable que la antigua contraseña no haya sido correcta.' type='warning' />
                            </Col>
                        </Row>
                        : null

                }

            </Form>
        </Modal>
    );
}

export default UserFormModal;