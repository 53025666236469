import { FC } from 'react';
import { Menu, MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMenuItems } from '../../hooks';

interface Props {
}

const MainSiderMenu: FC<Props> = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { menuItems, defaultOpenKeys } = useMenuItems();

    const onClick: MenuProps['onClick'] = ({ key }) => navigate(key);

    return (
        <Menu
            onClick={onClick}
            defaultSelectedKeys={[location.pathname]}
            defaultOpenKeys={defaultOpenKeys}
            mode="inline"
            theme="dark"
            items={menuItems}
        />
    );
}

export default MainSiderMenu;