import { FC, useMemo } from 'react';
import { Button, Col, Modal, Row, Tag, Typography } from 'antd';
import moment from 'moment';

import { modalStyles } from '../../styles';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setSelectedUser, setUserDetailsModalActive } from '../../redux/slices/users';
import { getColorByType } from './helpers';

interface Props {
}

const UserDetailsModal: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { userDetailsModalActive, selectedUser } = useAppSelector((state) => state.users);
    const { types } = useAppSelector((state) => state.types);

    const type = useMemo(() => {
        if (!selectedUser) return undefined;
        return types.find((type) => type.idType === selectedUser.idType);
    }, [types, selectedUser]);

    const closeModal = () => {
        dispatch(setUserDetailsModalActive(false));
        dispatch(setSelectedUser(null));
    }

    if (!selectedUser) {
        return null;
    }

    return (
        <Modal
            title='DETALLES DE USUARIO'
            open={userDetailsModalActive}
            onCancel={closeModal}
            footer={
                <Button type='primary' onClick={closeModal}>
                    ACEPTAR
                </Button>
            }
            width='60%'
        >
            <Row>
                <Col xs={{ span: 12 }} style={modalStyles.detailsCol}>
                    <Typography.Text strong>
                        Nombre:
                    </Typography.Text>
                    <Typography.Text>
                        {selectedUser.name}
                    </Typography.Text>
                </Col>
                <Col xs={{ span: 12 }} style={modalStyles.detailsCol}>
                    <Typography.Text strong>
                        Correo:
                    </Typography.Text>
                    <Typography.Text>
                        {selectedUser.email}
                    </Typography.Text>
                </Col>
            </Row>
            <Row style={{ margin: '1rem 0' }}>
                <Col xs={{ span: 12 }} style={modalStyles.detailsCol}>
                    <Typography.Text strong>
                        Núm. Tel:
                    </Typography.Text>
                    <Typography.Text>
                        {selectedUser.phoneNumber}
                    </Typography.Text>
                </Col>
                <Col xs={{ span: 12 }} style={modalStyles.detailsCol}>
                    <Typography.Text strong>
                        Rol:
                    </Typography.Text>
                    <div>
                        <Tag color={getColorByType(type?.typeEnumeration ?? 0)}>
                            {type?.name ?? 'N/A'}
                        </Tag>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 12 }} style={modalStyles.detailsCol}>
                    <Typography.Text strong>
                        Fecha de creación:
                    </Typography.Text>
                    <Typography.Text>
                        {moment(selectedUser.createdAt).format('LLL')}
                    </Typography.Text>
                </Col>
                <Col xs={{ span: 12 }} style={modalStyles.detailsCol}>
                    <Typography.Text strong>
                        Fecha de última actualización:
                    </Typography.Text>
                    <Typography.Text>
                        {selectedUser.updatedAt ? moment(selectedUser.updatedAt).format('LLL') : '--'}
                    </Typography.Text>
                </Col>
            </Row>
        </Modal>
    );
}

export default UserDetailsModal;