import { FC } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

import { useAppDispatch } from '../../hooks';
import { ExcelUploadModal } from '../ui';
import { ExcelMeasurementRow } from '../../interfaces';
import { uploadMeasurementsBatch } from '../../redux/slices/measurements';
import { closeExcelUploadModal } from '../../redux/slices/ui';

interface Props {
    api: NotificationInstance;
}

const MesurementsImportModal: FC<Props> = ({ api }) => {
    const dispatch = useAppDispatch();

    const handleMassiveUpload = (data: ExcelMeasurementRow[]) => {
        dispatch(uploadMeasurementsBatch(data,
            (message) => {
                api.success({ message });
                dispatch(closeExcelUploadModal());
            },
            (message) => api.error({ message })
        ));
    }

    return (
        <ExcelUploadModal
            title='Medidas'
            columns={[
                {
                    key: 'idMeasurement',
                    name: 'ID',
                },
                {
                    key: 'container',
                    name: 'Contenedor',
                },
                {
                    key: 'quantity',
                    name: 'Capacidad',
                },
                {
                    key: 'idUnit',
                    name: 'ID Unidad',
                },
            ]}
            onDataRead={handleMassiveUpload}
        />
    );
}

export default MesurementsImportModal;