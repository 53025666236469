import { FC } from 'react';
import { Card, Col, Image, Modal, Row, Typography } from 'antd';

import { useAppSelector } from '../../hooks';

interface Props {
    open: boolean;
    onCancel: () => void;
}

const PreviewModal: FC<Props> = ({ open, onCancel }) => {
    const { selectedStore } = useAppSelector(state => state.stores);

    if (!selectedStore) return null;

    return (
        <Modal title='VISTA PREVIA' open={open} onCancel={onCancel} footer={[]} width='50%'>
            <Card style={{ backgroundColor: selectedStore.backgroundColor }} bodyStyle={{ padding: '1rem' }} >
                <Row>
                    <Col xs={{ span: 18 }}>
                        <Typography.Title style={{ color: selectedStore.color }}>
                            {selectedStore.name}
                        </Typography.Title>
                    </Col>
                    <Col xs={{ span: 6 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {selectedStore.image !== 'N/A'
                            ? <Image
                                src={selectedStore.image}
                                style={{ maxWidth: '3rem' }}
                                preview={false}
                            />
                            : null
                        }
                    </Col>
                </Row>
                <Row>
                    <Typography.Text style={{ color: selectedStore.color, textAlign: 'justify' }}>
                        {selectedStore.description}
                    </Typography.Text>
                </Row>
            </Card>
        </Modal>
    );
}

export default PreviewModal;