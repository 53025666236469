import { Typography } from 'antd';
import { FC, ReactNode } from 'react';

interface Props {
    title: string;
    value?: string | number;
    component?: ReactNode;
}

const DataDisplay: FC<Props> = ({ title, value = '', component = null }) => {
    return (
        <div style={{ marginBottom: '0.5rem' }} >
            <div>
                <Typography.Text strong>
                    {title}
                </Typography.Text>
            </div>
            <div>
                {
                    component
                        ? component 
                        : <Typography.Text>
                            {value}
                        </Typography.Text>
                }
            </div>
        </div>
    );
}

export default DataDisplay;