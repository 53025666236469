import { FC, useMemo, useState } from 'react';
import { Card, Empty, Modal, Row, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '../../hooks';
import UnconfirmedOrderCard from './UnconfirmedOrderCard';
import { setUnconfirmedModalActiveAs } from '../../redux/slices/orders';
import OrderConfirmModal from './OrderConfirmModal';

interface Props {
}

const UnconfirmedOrdersModal: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { unconfirmedModalActive, orders } = useAppSelector(state => state.orders);

    const [selectedOrder, setSelectedOrder] = useState('');

    const unconfirmedOrders = useMemo(() => {
        return orders.filter(o => o.status === 'created');
    }, [orders]);

    const closeModal = () => {
        dispatch(setUnconfirmedModalActiveAs(false));
    }

    return (
        <Modal open={unconfirmedModalActive} title='GESTIÓN DE PEDIDOS NO CONFIRMADOS' width='70%' footer={[]} onCancel={closeModal}>
            {
                unconfirmedOrders.length === 0
                    ? <Row justify='center' style={{ margin: '1rem' }}>
                        <Empty />
                    </Row>
                    : unconfirmedOrders.map(order =>
                        <UnconfirmedOrderCard key={order.idOrder} data={order} onOrderConfirm={setSelectedOrder} />
                    )
            }
            <OrderConfirmModal
                open={selectedOrder !== ''}
                idOrder={selectedOrder}
                onClose={() => {
                    setSelectedOrder('')
                }}
            />
        </Modal>
    );
}

export default UnconfirmedOrdersModal;