import { FC } from 'react';
import { Button, Col, Modal, Row, Space } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import { button } from '../../styles';
import { useAppSelector, useCouponsLogic } from '../../hooks';
import TableCoupons from './TableCoupons';
import CouponForm from './CouponForm';

interface Props {
}

const CouponsModal: FC<Props> = () => {
    const { modalActive } = useAppSelector(state => state.coupons);

    const {
        contextHolder,
        title,
        formActive,
        refreshData,
        openCouponForm,
        closeCouponForm,
        closeCouponsModal,
        onFinish,
    } = useCouponsLogic()

    return (
        <Modal open={modalActive} title={title} width='70%' footer={[]} onCancel={closeCouponsModal}>
            {contextHolder}
            {
                formActive
                    ? <CouponForm onFinish={onFinish} onCancel={closeCouponForm} />
                    : <>
                        <Row justify='end'>
                            <Col>
                                <Space>
                                    <Button icon={<RetweetOutlined />} onClick={refreshData} />
                                    <Button style={button} type='primary' onClick={openCouponForm}>
                                        Agregar un nuevo cupón
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <TableCoupons />
                    </>
            }
        </Modal>
    );
}

export default CouponsModal;