import { FC } from 'react';
import { ColumnsType } from 'antd/es/table';

import { useAppSelector } from '../../hooks';
import { Customer } from '../../interfaces';
import { ActionsColumn, DataTable } from '../ui';
import { Parser } from '../../utils';

interface Props {
}

const TableCustomers: FC<Props> = () => {
    const { customers } = useAppSelector((state) => state.customers);

    const columns: ColumnsType<Customer> = [
        {
            title: 'ID',
            key: 'idCustomer', dataIndex: 'idCustomer',
        },
        {
            title: 'Nombre',
            dataIndex: 'fullName', key: 'fullName',
        },
        {
            title: 'Correo',
            dataIndex: 'email', key: 'email'
        },
        {
            title: 'Núm. Tel.',
            key: 'phoneNumber', dataIndex: 'phoneNumber',
            render: (value) => Parser.parsePhoneNumber(value),
        },
        {
            title: 'Tipo',
            key: 'type', dataIndex: 'type',
        },
        // {
        //     title: 'Acciones',
        //     render: () => <ActionsColumn label='Cliente' showEdit={false} showDelete={false} />,
        // }
    ];

    return (
        <DataTable columns={columns} data={customers} rowKey='idCustomer' />
    );
}

export default TableCustomers;