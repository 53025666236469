import { FC, useEffect } from 'react';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import { useAppDispatch } from '../hooks';
import { getAllUsers, setUserFormModalActive } from '../redux/slices/users';
import { TableUsers, UserDetailsModal, UserFormModal } from '../components';
import { getAllTypes } from '../redux/slices/types';

interface Props {
}

const Users: FC<Props> = () => {
    const dispatch = useAppDispatch();

    const fetchData = () => {
        dispatch(getAllUsers());
        dispatch(getAllTypes());
    }

    useEffect(() => {
        fetchData();
    }, []);

    const openForm = () => {
        dispatch(setUserFormModalActive(true));
    }

    return (
        <div>
            <Typography.Title>
                Usuarios
            </Typography.Title>
            <Row justify='end'>
                <Col>
                    <Space align='center'>
                        <Tooltip title='Actualizar datos'>
                            <Button icon={<RetweetOutlined />} onClick={fetchData} />
                        </Tooltip>
                        <Button type='primary' onClick={openForm}>
                            AGREGAR USUARIO
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row>
                <TableUsers />
            </Row>
            <UserDetailsModal />
            <UserFormModal />
        </div>
    );
}

export default Users;