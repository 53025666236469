import { FC } from 'react';
import { Button, Row, Space } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import { button } from '../../styles';
import { useAppDispatch } from '../../hooks';
import { openExcelUploadModal } from '../../redux/slices/ui';

interface Props {
    text: string;
    onRefreshClick?: () => void;
    onAddClick?: () => void;
    onExportClick?: () => void;
}

const ButtonsRow: FC<Props> = ({ text, onRefreshClick, onAddClick, onExportClick }) => {
    const dispatch = useAppDispatch();

    return(
        <Row justify='end'>
            <Space>
                <Button icon={<RetweetOutlined />} onClick={onRefreshClick} />
                <Button type='primary' style={button} onClick={onAddClick}>
                    {text}
                </Button>
                <Button type='primary' style={button} onClick={onExportClick}>
                    Exportar csv
                </Button>
                <Button type='primary' style={button} onClick={() => dispatch(openExcelUploadModal())}>
                    Importar csv
                </Button>
            </Space>
        </Row>
    );
}

export default ButtonsRow;