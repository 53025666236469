import { FC, useMemo } from 'react';
import { Card, Col, Collapse, CollapseProps, Modal, Row, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeDetailsModal, setSelectedOrder } from '../../redux/slices/orders';
import TableOrderProducts from './TableOrderProducts';
import { DataDisplay } from '../ui';
import OrderStatus from './OrderStatus';
import { Parser } from '../../utils';
import moment from 'moment';

interface Props {
}

const OrderDetailsModal: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { detailsModalActive, selectedOrder } = useAppSelector(state => state.orders);

    const closeModal = () => {
        dispatch(closeDetailsModal());
        dispatch(setSelectedOrder(null));
    }

    const customerData: string[] = useMemo(() => {
        if (!selectedOrder) return [];
        const { customerName, customerEmail, customerPhoneNumber } = selectedOrder;
        return [
            customerName, customerEmail, customerPhoneNumber
        ];
    }, [selectedOrder]);

    const addressData: (string | null)[] = useMemo(() => {
        if (!selectedOrder) return [];
        const { addressName, addressCompany, address1, address2, addressPhoneNumber, addressCity, addressZipcode } = selectedOrder;
        return [
            addressName, addressCompany, address1, address2, `${addressCity}, BCS ${addressZipcode}`, addressPhoneNumber
        ];
    }, [selectedOrder]);

    if (!selectedOrder) {
        return null;
    }

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Datos del Destino',
            children: <Row gutter={12}>
                <Col xs={{ span: 12 }}>
                    <Card title='Cliente'>
                        {
                            customerData.map((data, index) =>
                                <div key={`customer_data_${index}`}>
                                    <Typography.Text>
                                        {data}
                                    </Typography.Text>
                                </div>
                            )
                        }
                    </Card>
                </Col>
                <Col xs={{ span: 12 }}>
                    <Card title='Dirección'>
                        {
                            addressData.map((data, index) =>
                                data ? <div key={`address_data_${index}`}>
                                    <Typography.Text>
                                        {data}
                                    </Typography.Text>
                                </div>
                                    : null
                            )
                        }
                    </Card>
                </Col>
            </Row>
        },
        {
            key: '2',
            label: 'Datos de la Compra',
            children: <Row gutter={12}>
                <Col xs={{ span: 12 }}>
                    <DataDisplay title='Status:' component={<OrderStatus status={selectedOrder.status} />} />
                    <DataDisplay title='Subtotal:' value={Parser.parseToPrice(selectedOrder.subtotal)} />
                    <DataDisplay title='Costo de Envío:' value={Parser.parseToPrice(selectedOrder.shippingFee)} />
                    <DataDisplay title='Total:' value={Parser.parseToPrice(selectedOrder.total)} />
                    <DataDisplay title='Fecha de la Compra:' value={moment(selectedOrder.orderDate).format('LLLL')} />
                </Col>
                <Col xs={{ span: 12 }}>
                    <DataDisplay title='Tipo de Pago:' value={selectedOrder.paymentType} />
                    <DataDisplay title='Método de Pago:' value={selectedOrder.paymentMethod} />
                    <DataDisplay title='Referencia de Pago:' value={selectedOrder.paymentId} />
                    <DataDisplay title='Cupón:' value={selectedOrder.couponCode ?? 'N/A'} />
                    <DataDisplay title='Fecha de Entrega:' value={selectedOrder.deliveryDate ? moment(selectedOrder.deliveryDate).format('LLLL') : 'N/A'} />
                </Col>
            </Row>
        },
        {
            key: '3',
            label: 'Datos del Pedido',
            children: <>
                {
                    selectedOrder.deliveryCode
                        ? <Row>
                            <Typography.Text>
                                Contraseña: <span style={{ fontWeight: 'bold'}}>{selectedOrder.deliveryCode}</span>
                            </Typography.Text>
                        </Row>
                        : null
                }
                <Row>
                    <TableOrderProducts />
                </Row>
            </>
        }
    ]

    return (
        <Modal open={detailsModalActive} title={`PEDIDO #${selectedOrder.idOrder.toUpperCase()}`} width='70%' footer={[]} onCancel={closeModal}>
            <Collapse items={items} />
        </Modal>
    );
}

export default OrderDetailsModal;