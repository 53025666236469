import { FC, useState } from 'react';
import { Tag, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { Order } from '../../interfaces';
import { deleteOrder, openActionsModal, openDetailsModal, setSelectedOrder } from '../../redux/slices/orders';
import OrderStatus from './OrderStatus';

interface Props {
}

const TableOrders: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const [deleteModalActive, setDeleteModalActive] = useState(false);

    const dispatch = useAppDispatch();
    const { orders, selectedOrder } = useAppSelector(state => state.orders);

    const columns: ColumnsType<Order> = [
        {
            title: 'ID Corto',
            key: 'idOrder', dataIndex: 'idOrder',
            render: value => value.split('-')[0].toUpperCase(),
        },
        {
            title: 'Cliente',
            key: 'customerName', dataIndex: 'customerName',
        },
        {
            title: 'C. P.',
            key: 'addressZipcode', dataIndex: 'addressZipcode',
        },
        {
            title: 'Total',
            key: 'total', dataIndex: 'total',
            render: (value) => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value),
        },
        {
            title: 'Fecha Pedido',
            key: 'orderDate', dataIndex: 'orderDate',
            render: (value) => moment(value).format('LLL')
        },
        {
            title: 'Fecha Entrega',
            key: 'deliveryDate', dataIndex: 'deliveryDate',
            render: (value) => value ? moment(value).format('LLL') : 'N/A'
        },
        {
            title: 'Status',
            key: 'status', dataIndex: 'status',
            render: (value) => <OrderStatus status={value} />
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn
                label='Pedido'
                editDisabled={record.status === 'completed' || record.status === 'cancelled'}
                onDetailsClick={() => {
                    dispatch(setSelectedOrder(record));
                    dispatch(openDetailsModal());
                }}
                onEditClick={() => {
                    dispatch(setSelectedOrder(record));
                    dispatch(openActionsModal());
                }}
                onDeleteClick={() => {
                    dispatch(setSelectedOrder(record));
                    setDeleteModalActive(true);
                }}
            />
        }
    ];

    const closeDeleteModal = () => {
        setDeleteModalActive(false);
        dispatch(setSelectedOrder(null));
    }

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={orders.filter(o => o.status !== 'created')} rowKey='idOrder' />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar el pedido seleccionado?'
                open={deleteModalActive}
                onCancel={closeDeleteModal}
                onOk={() => {
                    dispatch(deleteOrder(selectedOrder!.idOrder, api, closeDeleteModal));
                }}
            />
        </>
    );
}

export default TableOrders;