import { FC, useMemo, useState } from 'react';
import { Button, Card, Col, Modal, Row, Space, Typography, notification } from 'antd';

import { button } from '../../styles';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setInvoiceActionsModalActiveAs, setSelectedInvoice, updateInvoice } from '../../redux/slices/invoices';
import dayjs from 'dayjs';
import { SingleFileInput } from '../ui';

interface Props {
}

const InvoiceActionsModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { actionsModalActive, selectedInvoice } = useAppSelector(state => state.invoices);

    const [pdf, setPdf] = useState<File | null>(null);
    const [xml, setXml] = useState<File | null>(null);

    const canSubmit = useMemo(() => {
        return Boolean((pdf && pdf.type === 'application/pdf') && (xml && xml.type === 'text/xml'));
    }, [pdf, xml]);

    const closeModal = () => {
        setPdf(null);
        setXml(null);
        dispatch(setSelectedInvoice(null));
        dispatch(setInvoiceActionsModalActiveAs(false));
    }

    const submitInvoiceData = () => {
        if (selectedInvoice && pdf && xml) 
            dispatch(updateInvoice(selectedInvoice.idInvoice, { pdf, xml }, api, closeModal))
    }

    if (!selectedInvoice) {
        return null;
    }

    return (
        <Modal open={actionsModalActive} title={`SUBIR FACTURA`} width='70%' footer={[]} onCancel={closeModal}>
            {contextHolder}
            <Row gutter={12} justify='space-between'>
                <Typography.Text>
                    Pedido el {dayjs(selectedInvoice.order.orderDate).format('DD [de] MMMM [del] YYYY')}
                </Typography.Text>
                <Typography.Text>
                    Pedido #{selectedInvoice.idOrder.toUpperCase()}
                </Typography.Text>
            </Row>
            <Row gutter={12} style={{ marginTop: '0.5rem' }}>
                <Col xs={{ span: 12 }}>
                    <Card size='small'>
                            <Typography.Text strong>
                            Archivo PDF
                        </Typography.Text>
                        <SingleFileInput file={pdf} accept='application/pdf' onFileCapture={(file) => setPdf(file)} />
                    </Card>
                </Col>
                <Col xs={{ span: 12 }}>
                    <Card size='small'>
                        <Typography.Text strong>
                            Archivo XML
                        </Typography.Text>
                        <SingleFileInput file={xml} accept='text/xml' onFileCapture={(file) => setXml(file)} />
                    </Card>
                </Col>
            </Row>
            <Row justify='end' style={{ marginTop: '1rem' }}>
                <Space>
                    <Button type='default' onClick={closeModal} style={button}>
                        CANCELAR
                    </Button>
                    <Button type='primary' disabled={!canSubmit} onClick={submitInvoiceData} style={button}>
                        Subir
                    </Button>
                </Space>
            </Row>
        </Modal>
    );
}

export default InvoiceActionsModal;