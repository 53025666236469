import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Button, Card, Modal, Popconfirm, Row, Space, notification } from 'antd';
import { button } from '../../styles';
import DeliveryDateAction from './DeliveryDateAction';
import { closeActionsModal, setSelectedOrder, updateOrderStatus } from '../../redux/slices/orders';

interface Props {
}

const OrderActionsModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { actionsModalActive, selectedOrder } = useAppSelector(state => state.orders);

    const closeModal = () => {
        dispatch(setSelectedOrder(null));
        dispatch(closeActionsModal());
    }

    const cancelOrder = () => {
        dispatch(updateOrderStatus(selectedOrder!.idOrder, 'cancelled', api, closeModal));
    }

    const completeOrder = () => {
        dispatch(updateOrderStatus(selectedOrder!.idOrder, 'completed', api, closeModal));
    }

    if (!selectedOrder) return null;
    return (
        <Modal open={actionsModalActive} title={`GESTIÓN DEL PEDIDO #${selectedOrder.idOrder}`} footer={[]} width='70%' onCancel={closeModal}>
            {contextHolder}
            <Row>
                <DeliveryDateAction api={api} onDeliveryDateAssigned={closeModal} />
            </Row>
            <Row justify='end' style={{ marginTop: '1rem' }}>
                <Space>
                    <Popconfirm
                        title='¿Está seguro de completar el pedido?'
                        description='Esta acción no es revertible.'
                        onConfirm={completeOrder}
                        okText='ACEPTAR'
                        cancelText='CANCELAR'
                    >
                        <Button type='primary' style={button}>
                            MARCAR COMO COMPLETADO
                        </Button>
                    </Popconfirm>
                </Space>
            </Row>
        </Modal>
    );
}

export default OrderActionsModal;