import { FC, useCallback, useEffect } from 'react';
import { Button, Col, Dropdown, notification, Row, Space, Typography } from 'antd';

import { useAppDispatch, useAppSelector, useProductsLogic } from '../hooks';
import { DownOutlined, FireOutlined, RetweetOutlined } from '@ant-design/icons';
import { button } from '../styles';
import { CategoriesImportModal, CategoriesModal, HotProductsModal, LinesImportModal, LinesModal, MeasurementsImportModal, MeasurementsModal, ProductDetailsModal, ProductsFormModal, ProductsImportModal, SpecialColorsModal, StoresImportModal, StoresModal, TableProducts, VariantsImportModal } from '../components';
import { getAllHotProducts, getAllProducts, openProductsFormModal, setHotProductsModalActiveAs } from '../redux/slices/products';
import { getAllStores } from '../redux/slices/stores';
import { getAllCategories } from '../redux/slices/categories';
import { getAllLines } from '../redux/slices/lines';
import { getAllMeasurements } from '../redux/slices/measurements';
import { closeExcelUploadModal, openExcelUploadModal } from '../redux/slices/ui';
import { getAllColors } from '../redux/slices/colors';
import { getAllColorCodes } from '../redux/slices/productsForm';
import { PaintSheensModal } from '../components/paintSheens';
import { getAllPaintSheens } from '../redux/slices/paintSheens';

interface Props {
}

const Products: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();

    const { modalActive: storesModal } = useAppSelector(state => state.stores);
    const { modalActive: categoriesModal } = useAppSelector(state => state.categories);
    const { modalActive: linesModal } = useAppSelector(state => state.lines);
    const { modalActive: measurementsModal } = useAppSelector(state => state.measurements);

    const { productsImportModalActive, variantsImportModalActive, pricesImportModalActive } = useAppSelector(state => state.productsForm);

    const { productSubmodules, variantSubmodules, exportItems, importItems } = useProductsLogic();

    const getData = useCallback(() => {
        dispatch(getAllColors());
        dispatch(getAllProducts());
        dispatch(getAllStores());
        dispatch(getAllCategories());
        dispatch(getAllLines());
        dispatch(getAllMeasurements());
        dispatch(getAllHotProducts());
        dispatch(getAllPaintSheens());
    }, [dispatch])

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        if (productsImportModalActive)
            dispatch(openExcelUploadModal());
        else
            dispatch(closeExcelUploadModal());
    }, [productsImportModalActive]);

    useEffect(() => {
        if (variantsImportModalActive)
            dispatch(openExcelUploadModal());
        else
            dispatch(closeExcelUploadModal());
    }, [variantsImportModalActive]);

    useEffect(() => {
        if (pricesImportModalActive)
            dispatch(openExcelUploadModal());
        else
            dispatch(closeExcelUploadModal());
    }, [pricesImportModalActive]);

    const openHotProductsModal = () => {
        dispatch(setHotProductsModalActiveAs(true));
    }

    return (
        <div>
            {contextHolder}
            <Typography.Title>
                Productos
            </Typography.Title>
            <Row justify='space-between'>
                <Col>
                    <Row style={{ marginBottom: '0.35rem' }}>
                        <Space>
                            {
                                productSubmodules.map(({ name, icon, onClick }, index) =>
                                    <Button
                                        key={`productSubmodules_${index}`}
                                        icon={icon}
                                        style={button}
                                        onClick={() => onClick ? onClick() : undefined}
                                    >
                                        {name}
                                    </Button>
                                )
                            }
                        </Space>
                    </Row>
                    <Row>
                        <Space>
                            {
                                variantSubmodules.map(({ name, icon, onClick }, index) =>
                                    <Button key={`variantSubmodules_${index}`} icon={icon} style={button} onClick={onClick}>
                                        {name}
                                    </Button>
                                )
                            }
                        </Space>
                    </Row>
                </Col>
                <Col>
                    <Row style={{ marginBottom: '0.35rem' }} justify='end'>
                        <Space>
                            <Button icon={<RetweetOutlined />} onClick={getData} />
                            <Button icon={<FireOutlined />} style={button} onClick={openHotProductsModal}>
                                Productos Destacados
                            </Button>
                            <Button type='primary' style={button} onClick={() => dispatch(openProductsFormModal())}>
                                Agregar un nuevo producto
                            </Button>
                        </Space>
                    </Row>
                    <Row justify='end'>
                        <Space>
                            <Dropdown menu={{ items: exportItems }} trigger={['click']}>
                                <Button style={button}>
                                    Exportar csv
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                            <Dropdown menu={{ items: importItems }} trigger={['click']}>
                                <Button style={button}>
                                    Importar csv
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Space>
                    </Row>
                </Col>
            </Row>
            <Row>
                <TableProducts />
            </Row>
            <Row>
                <SpecialColorsModal />
                <ProductDetailsModal />
                <StoresModal />
                {storesModal && <StoresImportModal api={api} />}
                <CategoriesModal />
                {categoriesModal && <CategoriesImportModal api={api} />}
                <LinesModal />
                {linesModal && <LinesImportModal api={api} />}
                <MeasurementsModal />
                {measurementsModal && <MeasurementsImportModal api={api} />}
                <ProductsFormModal />
                {productsImportModalActive && <ProductsImportModal api={api} />}
                {variantsImportModalActive && <VariantsImportModal api={api} />}
                <HotProductsModal />
                <PaintSheensModal />
            </Row>
        </div>
    );
}

export default Products;