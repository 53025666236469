import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mainApi } from "../../api";
import { GetTypes, Type } from "../../interfaces";
import { AppThunk } from "../store";
import { finishLoading, startLoading } from "./ui";

interface TypesState {
    types: Type[];
}

const initialState: TypesState = {
    types: [],
};

const typesSlice = createSlice({
    name: 'types',
    initialState,
    reducers: {
        setTypes(state, { payload }: PayloadAction<Type[]>) {
            state.types = payload;
        },
    },
});

const {
    setTypes,
} = typesSlice.actions;

export const getAllTypes = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const { data } = await mainApi.get<GetTypes>('/types/users');

            dispatch(setTypes(data.types));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllTypes', error);
            dispatch(finishLoading());
        }
    }
}

export default typesSlice.reducer;