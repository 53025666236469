import { FC, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';

import { PaintSheenFormData } from '../../interfaces';
import { button } from '../../styles';
import { useAppDispatch, useAppSelector, useFormRules } from '../../hooks';
import { createPaintSheen, setPaintSheensFormActiveAs, setSelectedPaintSheen, updatePaintSheen } from '../../redux/slices/paintSheens';
import { NotificationInstance } from 'antd/es/notification/interface';

interface Props {
    api: NotificationInstance;
}

const PaintSheenForm: FC<Props> = ({ api }) => {
    const [form] = Form.useForm<PaintSheenFormData>();

    const dispatch = useAppDispatch();
    const { selectedPaintSheen } = useAppSelector(state => state.paintSheens);
    const { required } = useFormRules();

    useEffect(() => {
        if (selectedPaintSheen) {
            form.setFieldsValue({
                name: selectedPaintSheen.name,
            });
        } else {
            form.resetFields();
        }
    }, [selectedPaintSheen])

    const closeForm = () => {
        dispatch(setSelectedPaintSheen(null));
        dispatch(setPaintSheensFormActiveAs(false));
    }

    const onFinish = (data: PaintSheenFormData) => {
        if (selectedPaintSheen) {
            dispatch(updatePaintSheen(selectedPaintSheen.idPaintSheen, data, api, closeForm));
        } else {
            dispatch(createPaintSheen(data, api, closeForm));
        }
    }

    return(
        <Form form={form} layout='vertical' autoComplete='off' onFinish={onFinish}>
            <Row>
                <Col xs={{ span: 24 }}>
                    <Form.Item label='Nombre:' name='name' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='end'>
                <Space>
                    <Button style={button} onClick={closeForm}>
                        Cancelar
                    </Button>
                    <Button type='primary' htmlType='submit' style={button}> 
                        {selectedPaintSheen ? 'ACTUALIZAR' : 'AGREGAR'}
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default PaintSheenForm;