import { CSSProperties } from "react";

export const fixedSider: CSSProperties = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#313c51'
}

export const siderLogoContainer: CSSProperties = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5rem 0',
}

export const header: CSSProperties = {
    backgroundColor: '#f5f6f5', 
    padding: '0 0.5rem', 
    borderBottom: '1px solid #eaecef',
    position: 'sticky', 
    top: 0, 
    zIndex: 1, 
    width: '100%' 
}

export const footer: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderTop: '1px solid #eaecef',
    position: 'sticky', 
    bottom: 0, 
    zIndex: 1,
}

export const content: CSSProperties = {
    padding: '0.5rem', 
    backgroundColor: 'Background', 
    minHeight: '83vh'
}