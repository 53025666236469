import { FC, useEffect } from 'react';
import { notification, Row, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '../hooks';
import { getAllBanners, openFormModal, setSelectedBanner } from '../redux/slices/banners';
import { BannerCard, BannerFormModal } from '../components';

interface Props {
}

const Banners: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { banners } = useAppSelector((state) => state.banners);

    const getData = () => {
        dispatch(getAllBanners());
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
             {contextHolder}
            <Typography.Title>
                Banners
            </Typography.Title>
            <Row gutter={12}>
                {
                    banners.map((banner, index) =>
                        <BannerCard
                            key={`banner_card_${index}`}
                            data={banner}
                            onCardClick={(banner) => {
                                dispatch(setSelectedBanner(banner));
                                dispatch(openFormModal());
                            }}
                        />
                    )
                }
            </Row>
            <BannerFormModal api={api} />
        </div>
    );
}

export default Banners;