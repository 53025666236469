export const getColorByType = (type: number) => {
    switch (type) {
        case 1:
            return 'gold';
        case 2:
            return 'purple';
        case 3:
            return 'green';
        default:
            return 'geekblue';
    }
}