import { FC, useEffect } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space } from 'antd';

import { CouponFormData } from '../../interfaces';
import { useAppSelector, useFormConstants, useFormRules } from '../../hooks';
import { button } from '../../styles';

interface Props {
    onFinish: (data: CouponFormData) => void;
    onCancel: () => void;
}

const CouponForm: FC<Props> = ({ onFinish, onCancel }) => {
    const [form] = Form.useForm<CouponFormData>();

    const { gutter, xs } = useFormConstants();
    const { required, percentage } = useFormRules();

    const { selectedCoupon } = useAppSelector(state => state.coupons);

    useEffect(() => {
        if (selectedCoupon) {
            const { name, description, code, discountPercentage, status } = selectedCoupon;
            form.setFieldsValue({
                name, description, code, discountPercentage, status,
            });
        } else {
            form.resetFields();
        }
    }, [selectedCoupon]);

    return(
        <Form layout='vertical' autoComplete='off' form={form} onFinish={onFinish}>
            <Row gutter={gutter}>
                <Col xs={xs}>
                    <Form.Item label='Nombre' name='name' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={xs}>
                    <Form.Item label='Descripción (Opcional)' name='description' >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col xs={xs}>
                    <Form.Item label='Código' name='code' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={xs}>
                    <Form.Item label='Porcentaje de Descuento' name='discountPercentage' rules={[required, percentage]} >
                        <InputNumber addonAfter='%' style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            {
                selectedCoupon
                    ? <Row justify='start'>
                        <Col xs={xs}>
                            <Form.Item label='Estado' name='status' rules={[required]}>
                                <Select 
                                    options={[
                                        { label: 'Activo', value: 'active' },
                                        { label: 'Inactivo', value: 'inactive' }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    : null
            }
            <Row justify='end'>
                <Space>
                    <Button style={button} onClick={onCancel}>
                        Cancelar
                    </Button>
                    <Button style={button} type='primary' htmlType='submit'>
                        {selectedCoupon ? 'Actualizar': 'Agregar'}
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default CouponForm;