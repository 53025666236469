import { Button, Col, Form, Modal, Row } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import { FC, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeFormModal, setSelectedBanner, updateBanner } from '../../redux/slices/banners';
import { ImagesInput } from '../ui';
import { EyeOutlined } from '@ant-design/icons';
import DesktopPreviewModal from './DesktopPreviewModal';
import MobilePreviewModal from './MobilePreviewModal';

interface Props {
    api: NotificationInstance;
}

const BannerFormModal: FC<Props> = ({ api }) => {
    const dispatch = useAppDispatch();
    const { selectedBanner, bannerFormModalActive } = useAppSelector((state) => state.banners);

    const [form] = Form.useForm();

    const [desktopFiles, setDesktopFiles] = useState<File[]>([]);
    const [mobileFiles, setMobileFiles] = useState<File[]>([]);
    const [desktopPreviewActive, setDesktopPreviewActive] = useState(false);
    const [mobilePreviewActive, setMobilePreviewActive] = useState(false);

    const canPreviewDesktop = useMemo(() => {
        if (desktopFiles.length > 0)
            return true;
        return false;
    }, [desktopFiles]);

    const canPreviewMobile = useMemo(() => {
        if (mobileFiles.length > 0)
            return true;
        return false;
    }, [mobileFiles]);

    const closeModal = () => {
        dispatch(closeFormModal());
        setDesktopFiles([]);
        setMobileFiles([]);
        dispatch(setSelectedBanner(null));
    }

    if (!selectedBanner) {
        return null;
    }

    const onFinish = () => {
        const desktopImage = desktopFiles[0] ?? null;
        const mobileImage = mobileFiles[0] ?? null;

        dispatch(updateBanner({
            idBanner: selectedBanner.idBanner,
            desktopImage, mobileImage,
        }, () => {
            api.success({
                message: 'Los banners han sido actualizados con éxito.'
            });
            closeModal();
        }, () => {
            api.error({
                message: 'Ha ocurrido un error al intentar actualizar los banners.'
            });
        }));
    }

    const openDesktopPreview = () => {
        setDesktopPreviewActive(true);
    }

    const closeDesktopPreview = () => {
        setDesktopPreviewActive(false);
    }

    const openMobilePreview = () => {
        setMobilePreviewActive(true);
    }

    const closeMobilePreview = () => {
        setMobilePreviewActive(false);
    }

    return (
        <Modal
            title={`Gestión del Banner #${selectedBanner.idBanner}`}
            open={bannerFormModalActive}
            onCancel={closeModal}
            width='75%'
            cancelText='CANCELAR'
            okText='SUBIR'
            onOk={onFinish}
        >
            <Form layout='vertical' form={form} autoComplete='off'>
                <Row gutter={12}>
                    <Col xs={{ span: 12 }}>
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <Form.Item label="Banner de Escritorio (1 máx.):" valuePropName="fileList">
                                    <ImagesInput
                                        limit={1}
                                        files={desktopFiles}
                                        onFilesSubmit={(_files) => setDesktopFiles([...desktopFiles, ..._files])}
                                        onImageDelete={(index) => setDesktopFiles([...desktopFiles.filter((_, _index) => _index !== index)])}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Button icon={<EyeOutlined />} disabled={!canPreviewDesktop} style={{ width: '100%' }} onClick={openDesktopPreview}>
                                VISTA PREVIA
                            </Button>
                            {desktopPreviewActive && <DesktopPreviewModal source={URL.createObjectURL(desktopFiles[0])} onClose={closeDesktopPreview} />}
                        </Row>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <Form.Item label="Banner para Móviles (1 máx.):" valuePropName="fileList">
                                    <ImagesInput
                                        limit={1}
                                        files={mobileFiles}
                                        onFilesSubmit={(_files) => setMobileFiles([...mobileFiles, ..._files])}
                                        onImageDelete={(index) => setMobileFiles([...mobileFiles.filter((_, _index) => _index !== index)])}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Button icon={<EyeOutlined />} disabled={!canPreviewMobile} style={{ width: '100%' }} onClick={openMobilePreview}>
                                VISTA PREVIA
                            </Button>
                            {mobilePreviewActive && <MobilePreviewModal source={URL.createObjectURL(mobileFiles[0])} onClose={closeMobilePreview} />}
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default BannerFormModal;