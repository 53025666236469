import { FC, useEffect } from 'react';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';

import { button } from '../styles';
import { MailOutlined, RetweetOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../hooks';
import { getAllCustomers, setAnnouncementModalActiveAs } from '../redux/slices/customers';
import { AnnouncementsModal, TableCustomers } from '../components/customers';

interface Props {
}

const Customers: FC<Props> = () => {
    const dispatch = useAppDispatch();

    const fetchData = () => {
        dispatch(getAllCustomers());
    }

    useEffect(() => {
        fetchData();
    }, []);

    const openAnnouncementsModal = () => {
        dispatch(setAnnouncementModalActiveAs(true));
    }

    return (
        <>
            <Typography.Title>Clientes</Typography.Title>
            <Row justify='end'>
                <Col>
                    <Space style={{ paddingRight: '0.5rem' }}>
                        <Tooltip title='Actualizar datos'>
                            <Button icon={<RetweetOutlined />} onClick={fetchData} />
                        </Tooltip>
                        <Button type='primary' icon={<MailOutlined />} style={button} onClick={openAnnouncementsModal}>
                            ANUNCIOS
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row>
                <TableCustomers />
            </Row>
            <Row>
                <AnnouncementsModal />
            </Row>
        </>
    );
}

export default Customers;