import { FC, useEffect } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import { Colorpicker } from 'antd-colorpicker';

import { Color, ColorForm } from '../../interfaces';

interface Props {
    selectedColor?: Color | null;
    onFormCancel?: () => void;
    onFormSubmit?: (form: ColorForm) => void;
}

const ColorsForm: FC<Props> = ({ onFormCancel, selectedColor = null, onFormSubmit }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedColor) {
            const rgb = selectedColor.rgb.replaceAll('(', '').replaceAll(')', '').split(', ');
            form.setFieldsValue({
                name: selectedColor.name,
                code: selectedColor.code,
                card: selectedColor.card,
                color: {
                    r: parseInt(rgb[0]),
                    g: parseInt(rgb[1]),
                    b: parseInt(rgb[2]), 
                    a: 1,
                }
            })
        } else {
            form.resetFields();
        }
    }, [selectedColor]);

    const onFinish = (data: ColorForm) => {
        if (onFormSubmit) onFormSubmit(data);
    }

    const onCancel = () => {
        if (onFormCancel) onFormCancel();
    }

    return (
        <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off' style={{ width: '100%' }}>
            <Row gutter={12} justify='space-between'>
                <Col xs={{ span: 18 }}>
                    <Row gutter={12}>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label='Nombre:'
                                name='name'
                                rules={[
                                    { required: true, message: 'Este campo es requerido.' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label='Código:'
                                name='code'
                                rules={[
                                    { required: true, message: 'Este campo es requerido.' },
                                ]}
                                help='Si está agregando un color de una galería diferente a Millennium recuerde agregar las iniciales correspondientes al inicio: HI para Históricos, LI para colores de Línea, TQ para Tranquility, TS para Transicion y RAL para Industriales.'
                                style={{ textAlign: 'justify'}}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label='Card:'
                                name='card'
                                rules={[
                                    { required: true, message: 'Este campo es requerido.' },
                                ]}
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 6 }} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Form.Item
                        label="Color (RGB):"
                        name="color"
                    >
                        <Colorpicker />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='end' style={{ marginTop: '1rem' }}>
                <Space>
                    <Button onClick={onCancel}>
                        CANCELAR
                    </Button>
                    <Button type='primary' htmlType='submit'>
                        {selectedColor ? 'ACTUALIZAR' : 'AGREGAR'}
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default ColorsForm;