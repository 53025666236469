import { DeleteOutlined } from '@ant-design/icons';
import { Button, Divider, Row, Typography } from 'antd';
import { ChangeEvent, FC, useMemo, useRef } from 'react';

interface Props {
    file: File | null;
    onFileCapture: (file: File | null) => void;
    accept: string;
}

const SingleFileInput: FC<Props> = ({ file, onFileCapture, accept }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const showFileTypeWarning = useMemo(() => {
        if (file) return file.type !== accept
        return false;
    }, [file]);

    const openFileInput = () => {
        inputRef.current!.click();
    }

    const handleFileInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (!target.files)
            return;

        onFileCapture(target.files[0]);
    }

    const removeFile = () => {
        onFileCapture(null);
    }

    return (
        <Row style={{ marginTop: '1rem' }}>
            <Row align='middle' style={{ width: '100%', }}>
                <Button type='default' style={{ marginRight: '0.5rem' }} onClick={openFileInput}>
                    SUBIR ARCHIVO
                </Button>
                <input
                    style={{ display: 'none' }}
                    ref={(element) => inputRef.current = element}
                    type="file"
                    accept={accept}
                    value=''
                    onChange={handleFileInputChange}
                />
                {
                    file
                        ? <Row align='middle' justify='space-between' style={{ flex: 1 }}>
                            <Typography.Text style={{ width: '70%' }} ellipsis={true}>
                                {file.name}
                            </Typography.Text>
                            <Button icon={<DeleteOutlined />} onClick={removeFile} />
                        </Row>
                        : <Typography.Text>No hay ningún archivo seleccionado.</Typography.Text>
                }
            </Row>
            {
                showFileTypeWarning
                    ? <Typography.Text style={{ color: 'red' }}>
                        El tipo de archivo no es válido.
                    </Typography.Text>
                    : null
            }
            <Divider style={{ margin: '0.5rem 0' }} />
        </Row>
    );
}

export default SingleFileInput;