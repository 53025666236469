import { useEffect, useMemo, useState } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import { saveSpecialColors } from '../redux/slices/colors';
import { Color } from '../interfaces';

const useSpecialColorsForm = (api: NotificationInstance) => {
    const dispatch = useAppDispatch();
    const { colors } = useAppSelector((state) => state.colors);

    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        setTargetKeys(colors.filter(c => c.isSpecial === true).map(c => '' + c.idColor));
    }, [colors]);

    const hasChanges = useMemo(() => {
        const specialColors = colors.filter(c => c.isSpecial === true).map(c => '' + c.idColor);
        if (specialColors.length !== targetKeys.length) return true;
        let hasChangesInSpecialColors = false;
        targetKeys.forEach((key) => {
            if (!specialColors.includes(key)) hasChangesInSpecialColors = true;
        });
        return hasChangesInSpecialColors;
    }, [colors, targetKeys]);

    const onChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const saveChanges = () => {
        dispatch(saveSpecialColors(
            colors.filter(c => targetKeys.includes(''+c.idColor)), 
            colors.filter(c => c.isSpecial === true).filter(c => !targetKeys.includes(''+c.idColor)), 
            () => {
                api.success({
                    message: 'Los colores especiales han sido actualizados con éxito.'
                });
            },
            () => {
                api.error({
                    message: 'Ha ocurrido un error al intentar actualizar los colores especiales.',
                });
            },
        ));
    }

    const filterOption = (inputValue: string, option: Color) => {
        return option.name.toLowerCase().includes(inputValue.toLowerCase()) || option.code.toLowerCase().includes(inputValue.toLowerCase());
    }

    const resetForm = () => {
        setTargetKeys(colors.filter(c => c.isSpecial === true).map(c => '' + c.idColor));
        setSelectedKeys([]);
    }

    return {
        targetKeys,
        selectedKeys,
        onChange,
        onSelectChange,
        saveChanges,
        resetForm,
        hasChanges,
        filterOption,
    }
}

export default useSpecialColorsForm;