import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import esES from 'antd/lib/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx'

import { mainTheme } from "./themes";
import { useAppDispatch, useAppSelector } from "./hooks";
import { Banners, Cancellations, Customers, Dashboard, Discounts, Gallery, Invoicing, Login, Orders, Products, Shipping, Users } from "./pages";
import { PrivateRoute, PublicRoute } from "./routes";
import { LoadingOverlay, MainLayout, VerifyingSession } from "./components";
import { fetchUserData } from "./redux/slices/auth";

const App = () => {
  dayjs.locale('es-mx');

  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (userData === undefined) {
      dispatch(fetchUserData());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  if (userData === undefined) {
    return <VerifyingSession />;
  }

  return (
    <ConfigProvider theme={mainTheme} locale={esES}>
      <LoadingOverlay>
        <Routes>
          <Route path='/login' element={<PublicRoute><Login /> </PublicRoute>} />
          <Route path='/' element={<PrivateRoute> <MainLayout /> </PrivateRoute>}>
            <Route index element={<Dashboard />} />
            <Route path='/banners' element={<Banners />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/products' element={<Products />} />
            <Route path='/discounts' element={<Discounts />} />
            <Route path='/users' element={<Users />} />
            <Route path='/orders' element={<Orders />} />
            <Route path='/shipping' element={<Shipping />} />
            <Route path='/customers' element={<Customers />} />
            <Route path='/invoicing' element={<Invoicing />} />
            <Route path='/cancellations' element={<Cancellations />} />
          </Route>
          <Route path='*' element={<Navigate to='/'  />} />
        </Routes>
      </LoadingOverlay>
    </ConfigProvider>
  );
}

export default App;