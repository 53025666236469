import {
    DesktopOutlined,
    FileDoneOutlined,
    FormatPainterOutlined,
    MailOutlined,
    PieChartOutlined,
    ReconciliationOutlined,
    SettingOutlined,
    ShoppingOutlined,
    StopOutlined,
    TagsOutlined,
    UserOutlined
} from '@ant-design/icons';

export interface ChildrenModule {
    key: string;
    label: string;
    icon: JSX.Element;
}

export interface AppModule extends ChildrenModule {
    allowedTypes: number[];
    children?: ChildrenModule[];
}

const useModuleStructure = () => {
    // 1 -> Admin, 2 -> Capturista, 3 -> Mercadotecnia
    const appModules: AppModule[] = [
        {
            key: '/',
            label: 'Dashboard',
            icon: <PieChartOutlined />,
            allowedTypes: [1]
        },
        {
            key: '/banners',
            label: 'Banners',
            icon: <DesktopOutlined />,
            allowedTypes: [1, 3],
        },
        {
            key: '/gallery',
            label: 'Galería',
            icon: <FormatPainterOutlined />,
            allowedTypes: [1, 3],
        },
        {
            key: '/products',
            label: 'Productos',
            icon: <ReconciliationOutlined />,
            allowedTypes: [1, 2],
        },
        {
            key: '/discounts',
            label: 'Descuentos',
            icon: <TagsOutlined />,
            allowedTypes: [1, 3],
        },
        {
            key: '/orders',
            label: 'Pedidos',
            icon: <ShoppingOutlined />,
            allowedTypes: [1, 3],
        },
        {
            key: '/cancellations',
            label: 'Cancelaciones',
            icon: <StopOutlined />,
            allowedTypes: [1, 3]
        },
        {
            key: '/invoicing',
            label: 'Facturación',
            icon: <FileDoneOutlined />,
            allowedTypes: [1, 3],
        },
        {
            key: '/customers',
            label: 'Clientes',
            icon: <UserOutlined />,
            allowedTypes: [1, 3],
        },
        {
            key: '/admin',
            label: 'Administración',
            icon: <SettingOutlined />,
            allowedTypes: [1],
            children: [
                {
                    key: '/shipping',
                    label: 'Tarifas',
                    icon: <MailOutlined />
                },
                {
                    key: '/users',
                    label: 'Usuarios',
                    icon: <UserOutlined />,
                },
            ]
        }
    ];

    return {
        appModules
    };
}

export default useModuleStructure;