import { CSSProperties, FC, ReactNode } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useAppSelector } from '../../hooks';

interface Props {
    children: ReactNode
}

const LoadingOverlay: FC<Props> = ({ children }) => {
    const { loading } = useAppSelector((state) => state.ui);

    const loadingIconStyle: CSSProperties = {
        fontSize: 56,
        color: 'white',
    }

    return (
        <>
            {
                loading
                    ? <div className="loading-overlay">
                        <Spin indicator={<LoadingOutlined style={loadingIconStyle} spin />} />
                    </div>
                    : null
            }
            {children}
        </>
    );
}

export default LoadingOverlay;