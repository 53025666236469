import { FC } from 'react';
import { Card, Col, Row, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
}

const VerifyingSession: FC<Props> = () => {
    return (
        <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
            <Col xs={{ span: 20 }} md={{ span: 14 }} lg={{ span: 10 }} xl={{ span: 8 }}>
                <Card bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Spin
                        indicator={
                            <LoadingOutlined style={{ fontSize: 56, color: '#313c51' }} spin />
                        }
                        style={{ marginBottom: '2rem' }}
                    />
                    <Typography.Title className='text-center'>
                        Verificando sesión...
                    </Typography.Title>
                </Card>
            </Col>
        </Row>
    );
}

export default VerifyingSession;