import { FC } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

import { useAppDispatch } from '../../hooks';
import { ExcelUploadModal } from '../ui';
import { closeProductsImportModal } from '../../redux/slices/productsForm';
import { ExcelProductRow } from '../../interfaces';
import { uploadProductsBatch } from '../../redux/slices/products';

interface Props {
    api: NotificationInstance;
}

const ProductsImportModal: FC<Props> = ({ api }) => {
    const dispatch = useAppDispatch();

    const handleMassiveUpload = (data: ExcelProductRow[]) => {
        dispatch(uploadProductsBatch(data,
            (message) => {
                api.success({ message });
                dispatch(closeProductsImportModal());
            },
            (message) => api.error({ message })
        ));
    }

    return (
        <ExcelUploadModal
            onClose={() => dispatch(closeProductsImportModal())}
            title='Productos'
            columns={[
                {
                    key: 'idProduct',
                    name: 'ID',
                },
                {
                    key: 'name',
                    name: 'Nombre',
                },
                {
                    key: 'slug',
                    name: 'Slug',
                },
                {
                    key: 'description',
                    name: 'Descripción',
                },
                {
                    key: 'idStore',
                    name: 'ID Tienda',
                },
                {
                    key: 'idCategory',
                    name: 'ID Categoría',
                },
                {
                    key: 'idLine',
                    name: 'ID Línea'
                }
            ]}
            onDataRead={handleMassiveUpload}
        />
    );
}

export default ProductsImportModal;