import { Modal } from 'antd';
import { FC } from 'react';
import { DataTable } from '../ui';
import { ColumnsType } from 'antd/es/table';
import { Unit } from '../../interfaces';
import { useAppSelector } from '../../hooks';

interface Props {
    open: boolean;
    onClose: () => void;
}

const UnitsModal: FC<Props> = ({ open, onClose }) => {
    const { units } = useAppSelector(state => state.measurements);

    const columns: ColumnsType<Unit> = [
        {
            title: 'ID',
            key: 'idUnit', dataIndex: 'idUnit'
        },
        {
            title: 'Clave SAT',
            key: 'satKey', dataIndex: 'satKey'
        },
        {
            title: 'Nombre',
            key: 'name', dataIndex: 'name'
        },
        {
            title: 'Abreviación',
            key: 'abbreviation', dataIndex: 'abbreviation'
        }
    ]

    return (
        <Modal title='CATÁLOGO DE UNIDADES' open={open} onCancel={onClose} footer={[]} width='50%'>
            <DataTable 
                columns={columns}
                data={units}
                rowKey='idUnit'
            />
        </Modal>
    );
}

export default UnitsModal;