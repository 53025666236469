import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Cancellation } from '../../interfaces';
import { mainApi } from '../../api';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { NotificationInstance } from 'antd/es/notification/interface';

interface CancellationsState {
    cancellations: Cancellation[];
    selectedCancellation: Cancellation | null;
    detailsModalActive: boolean;
    actionsModalActive: boolean;
}

const initialState: CancellationsState = {
    cancellations: [],
    selectedCancellation: null,
    detailsModalActive: false,
    actionsModalActive: false,
};

const cancellationsSlice = createSlice({
    name: 'Cancellations',
    initialState,
    reducers: {
        setCancellations(state, { payload }: PayloadAction<Cancellation[]>) {
            state.cancellations = payload;
        },
        setSelectedCancellation(state, { payload }: PayloadAction<Cancellation | null>) {
            state.selectedCancellation = payload;
        },
        setCancellationDetailsModalActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.detailsModalActive = payload;
        },
        setCancellationActionsModalActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.actionsModalActive = payload;
        },
    },
});

export const {
    setCancellations,
    setSelectedCancellation,
    setCancellationDetailsModalActiveAs,
    setCancellationActionsModalActiveAs,
} = cancellationsSlice.actions;

export const getAllCancellations = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get('/cancellations');

            dispatch(setCancellations(data.cancellations));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllCancellations', error);
            dispatch(finishLoading());
        }
    }
}

export const updateCancellation = (idCancellation: number, api: NotificationInstance, callback?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/cancellations/${idCancellation}`);

            dispatch(getAllCancellations());
            dispatch(finishLoading());
            api.success({ message: 'El registro de la cancelación ha sido actualizado con éxito.' });
            if (callback) callback();
        } catch (error) {
            console.log('updateInvoice', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar actualizar el registro de la cancelación.' });
        }
    }
}

export const deleteCancellation = (idCancellation: number, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/cancellations/${idCancellation}`);

            dispatch(getAllCancellations());
            dispatch(finishLoading());
            api.success({ message: 'El registro de la cancelación ha sido eliminado con éxito.' });
            callback();
        } catch (error) {
            console.log('deleteCancellation', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar eliminar el registro de la cancelación.' });
        }
    }
}

export default cancellationsSlice.reducer;