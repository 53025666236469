import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Banner, BannerForm, GetBanners } from "../../interfaces"
import { AppThunk } from "../store";
import { mainApi } from "../../api";
import { finishLoading, startLoading } from "./ui";

interface BannersState {
    banners: Banner[];
    selectedBanner: Banner | null;
    bannerFormModalActive: boolean;
}

const initialState: BannersState = {
    banners: [],
    selectedBanner: null,
    bannerFormModalActive: false,
}

const bannersSlice = createSlice({
    name: 'banners',
    initialState,
    reducers: {
        setBanners(state, { payload }: PayloadAction<Banner[]>) {
            state.banners = payload;
        },
        setSelectedBanner(state, { payload }: PayloadAction<Banner | null>) {
            state.selectedBanner = payload;
        },
        openFormModal(state) {
            state.bannerFormModalActive = true;
        },
        closeFormModal(state) {
            state.bannerFormModalActive = false;
        },
    },
});

export const {
    setBanners,
    setSelectedBanner,
    openFormModal,
    closeFormModal,
} = bannersSlice.actions;

export const getAllBanners = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetBanners>('/banners');

            dispatch(setBanners(data.banners));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllBanners', error);
            dispatch(finishLoading());
        }
    }
}

export const updateBanner = ({ idBanner, desktopImage, mobileImage }: BannerForm, onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data = new FormData();

            if (desktopImage) {
                data.append('desktop', desktopImage);
            }

            if (mobileImage) {
                data.append('mobile', mobileImage);
            }

            await mainApi.put(`/banners/${idBanner}`, data);

            dispatch(getAllBanners());
            dispatch(finishLoading());
            onSuccess();
        } catch (error) {
            console.log('updateBanner', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export default bannersSlice.reducer;