import { useEffect, useState } from 'react';
import { Form } from 'antd';

import { ProductFormData, ProductVariantFormData } from '../interfaces';
import useAppSelector from './useAppSelector';
import { setFeatures, setFiles, setVariants } from '../redux/slices/productsForm';
import useAppDispatch from './useAppDispatch';

const useProductsForms = () => {
    const [productForm] = Form.useForm<ProductFormData>();
    const [variantForm] = Form.useForm<ProductVariantFormData>();

    const dispatch = useAppDispatch();
    const { selectedProduct, allFeatures, allVariants, allImages } = useAppSelector(state => state.products);
    const { selectedVariant } = useAppSelector(state => state.productsForm);

    const { stores } = useAppSelector(state => state.stores);
    const { categories } = useAppSelector(state => state.categories);
    const { lines } = useAppSelector(state => state.lines);
    const { measurements } = useAppSelector(state => state.measurements);

    useEffect(() => {
        if (selectedProduct) {
            const { name, slug, description, idStore, idCategory, idLine } = selectedProduct;
            productForm.setFieldsValue({
                name, slug, description,
                idStore: idStore ?? 0,
                idCategory: idCategory ?? 0,
                idLine: idLine ?? 0,
            });
            dispatch(setFeatures(
                allFeatures
                    .filter((f) => f.idProduct === selectedProduct.idProduct)
                    .map(({ idFeature, description }) => ({
                        idFeature, description,
                    }))
            ));

            const productVariants: ProductVariantFormData[] = allVariants
                .filter(v => v.idProduct === selectedProduct.idProduct)
                .map((databaseVariant) => {
                    const { idVariant, productKey, barcode, weight, localPrice, foreignPrice, generalPrice, idMeasurement } = databaseVariant;

                    const variant: ProductVariantFormData = {
                        idVariant, productKey, barcode, weight, localPrice, foreignPrice, generalPrice, idMeasurement,
                        usesColorGallery: databaseVariant.usesColorGallery ? 1 : 0,
                        usesSpecialPrices: databaseVariant.usesSpecialPrices ? 1 : 0,
                        paintSheens: databaseVariant.paintSheens ? databaseVariant.paintSheens.split(';').map(ps => parseInt(ps)) : [],
                        images: allImages.filter(i => i.idVariant === databaseVariant.idVariant),
                        files: [],
                    }

                    if (databaseVariant.usesColorGallery) {
                        variant.allowedColorGroups = databaseVariant.allowedColorGroups!.split(';');
                    }

                    if (databaseVariant.usesSpecialPrices) {
                        variant.specialLocalPrice = databaseVariant.specialLocalPrice;
                        variant.specialForeignPrice = databaseVariant.specialForeignPrice;
                        variant.specialGeneralPrice = databaseVariant.specialGeneralPrice;
                    }

                    return variant;
                });

            dispatch(setVariants(productVariants));
        } else {
            productForm.resetFields();
            dispatch(setFeatures([]));
            dispatch(setVariants([]));
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (selectedVariant) {
            variantForm.setFieldsValue({
                ...selectedVariant,
            });
            dispatch(setFiles(selectedVariant.files));
        } else {
            variantForm.resetFields();
            dispatch(setFiles([]));
        }
    }, [selectedVariant]);

    return {
        productForm,
        stores,
        categories,
        lines,
        measurements,
        selectedProduct,
        variantForm,
    };
}

export default useProductsForms;