import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row, Select, Space, Transfer, notification } from 'antd';
import { TransferLocale } from 'antd/es/transfer';

import { useAppDispatch, useAppSelector, useFormConstants } from '../../hooks';
import { setHotProductsModalActiveAs, updateHotProducts } from '../../redux/slices/products';
import { Product } from '../../interfaces';

interface Props {
}

const locale: Partial<TransferLocale> = {
    itemUnit: 'Producto',
    itemsUnit: 'Productos',
    selectAll: 'Seleccionar todo',
    selectCurrent: 'Seleccionar la página actual',
    selectInvert: 'Invertir la página actual'
}

const HotProductsModal: FC<Props> = () => {
    const [selectedStore, setSelectedStore] = useState<number | null>(null);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { hotProductsModalActive, products, hotProducts } = useAppSelector(state => state.products);
    const { stores } = useAppSelector(state => state.stores);
    const { gutter, xs } = useFormConstants();

    const hasChanges = useMemo(() => {
        if (hotProducts.length !== targetKeys.length) return true;
        let hasChangesInHotProducts = false;
        targetKeys.forEach((key) => {
            if (!hotProducts.map(p => `${p.idProduct}`).includes(key)) hasChangesInHotProducts = true;
        });
        return hasChangesInHotProducts;
    }, [hotProducts, targetKeys]);

    useEffect(() => {
        if (selectedStore)
            setTargetKeys(hotProducts.map(p => `${p.idProduct}`));
    }, [hotProducts, selectedStore]);

    const resetForm = () => {
        setTargetKeys(hotProducts.map(p => `${p.idProduct}`));
        setSelectedKeys([]);
    }

    const closeModal = () => {
        resetForm();
        setSelectedStore(null);
        dispatch(setHotProductsModalActiveAs(false));
    }

    const onChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const saveChanges = () => {
        if (!selectedStore)
            return;

        dispatch(updateHotProducts({ idStore: selectedStore, products: targetKeys }, () => {
            api.success({ message: 'Los productos destacados han sido actualizados con éxito.' });
        }, () => {
            api.error({ message: 'Ha ocurrido un error al intentar actualizar los productos destacados.' });
        }))
    }

    const filterOption = (inputValue: string, option: Product) => {
        return option.name.toLowerCase().includes(inputValue.toLowerCase());
    }

    return (
        <Modal open={hotProductsModalActive} title='PRODUCTOS DESTACADOS' width='70%' onCancel={closeModal} footer={[]}>
            {contextHolder}
            <Form layout='vertical'>
                <Row gutter={gutter}>
                    <Col xs={xs} >
                        <Form.Item label='Tienda seleccionada:'>
                            <Select
                                options={stores.map(store => ({ label: store.name, value: store.idStore }))}
                                onChange={(value) => setSelectedStore(value)}
                                value={selectedStore}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row style={{ margin: '0 auto' }}>
                <Transfer
                    dataSource={products.filter(p => p.idStore === selectedStore).map(p => ({ ...p, key: `${p.idProduct}` }))}
                    titles={['Todos los Productos', 'Productos Destacados']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={onChange}
                    onSelectChange={onSelectChange}
                    render={(item) => <div >{item.name}</div>}
                    listStyle={{ width: '50%' }}
                    style={{ width: '100%' }}
                    pagination
                    showSearch
                    filterOption={filterOption}
                    locale={locale}
                />
            </Row>
            <Row style={{ marginTop: '1rem' }} justify='end'>
                <Space>
                    <Button type='primary' disabled={!hasChanges} onClick={saveChanges}>
                        GUARDAR CAMBIOS
                    </Button>
                </Space>
            </Row>
        </Modal>
    );
}

export default HotProductsModal;