import { Modal } from 'antd';
import { FC } from 'react';

interface Props {
    open: boolean;
    onClose: () => void;
    source: string;
}

const InspirationImagePreviewModal: FC<Props> = ({ open, onClose, source }) => {
    return (
        <Modal open={open} title='VISTA PREVIA DE IMAGEN DE INSPIRACIÓN' onCancel={onClose} footer={[]}>
            {
                source !== ''
                    ? <img
                        src={source}
                        alt='Vista previa de Imagen de Inspiración'
                        style={{
                            width: '100%',
                            objectFit: 'contain'
                        }}
                    />
                    : null
            }

        </Modal>
    );
}

export default InspirationImagePreviewModal;