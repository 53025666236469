import { FC, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { button } from '../../styles';
import { createCategory, updateCategory } from '../../redux/slices/categories';
import { CategoryFormData } from '../../interfaces';

interface Props {
    onClose: () => void;
    api: NotificationInstance;
}

const CategoryForm: FC<Props> = ({ onClose, api }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { selectedCategory } = useAppSelector(state => state.categories);

    useEffect(() => {
        if (selectedCategory) {
            form.setFieldsValue({
                name: selectedCategory.name,
                description: selectedCategory.description,
            });
        } else {
            form.resetFields();
        }
    }, [selectedCategory, form]);

    const onFinish = (data: CategoryFormData) => {
        if (selectedCategory) {
            dispatch(updateCategory(selectedCategory.idCategory, data,
                (message) => {
                    api.success({ message });
                    onClose();
                },
                (message) => api.error({ message }),
            ));
        } else {
            dispatch(createCategory(data,
                (message) => {
                    api.success({ message });
                    onClose();
                },
                (message) => api.error({ message })
            ));
        }
    }

    return (
        <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off'>
            <Row gutter={12}>
                <Col xs={{ span: 12 }}>
                    <Form.Item
                        label='Nombre:'
                        name='name'
                        rules={[
                            { required: true, message: 'Este campo es requerido.' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }}>
                    <Form.Item
                        label="Descripción:"
                        name="description"
                    >
                        <Input.TextArea rows={4} maxLength={500} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='end'>
                <Space>
                    <Button style={button} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type='primary' style={button} htmlType='submit'>
                        {selectedCategory ? 'actualizar' : 'agregar'}
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default CategoryForm;