import { ThemeConfig } from "antd/es/config-provider/context";

export const colors = {
    primary: '#313c51',
}

const mainTheme: ThemeConfig = {
    token: {
        colorPrimary: colors.primary,
    },
    components: {
        Spin: {
            colorPrimary: colors.primary,
        },
    }
}

export default mainTheme;