import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { Measurement, MeasurementFormData } from '../interfaces';
import useAppSelector from './useAppSelector';
import useAppDispatch from './useAppDispatch';
import { createMeasurement, setSelectedMeasurement, updateMeasurement } from '../redux/slices/measurements';

const useMeasurementForm = (api: NotificationInstance) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { selectedMeasurement } = useAppSelector(state => state.measurements);

    const [formActive, setFormActive] = useState(false);

    useEffect(() => {
        if (selectedMeasurement) {
            const { container, quantity, idUnit } = selectedMeasurement;
            form.setFieldsValue({
                container, quantity, idUnit
            });
        } else {
            form.resetFields();;
        }
    }, [selectedMeasurement]);

    const openForm = (record: Measurement | null = null) => {
        if (record) {
            dispatch(setSelectedMeasurement(record));
        }
        setFormActive(true);
    }

    const closeForm = () => {
        form.resetFields();
        dispatch(setSelectedMeasurement(null));
        setFormActive(false);
    }

    const submitForm = (data: MeasurementFormData) => {
        if (selectedMeasurement) {
            dispatch(updateMeasurement(selectedMeasurement.idMeasurement, data,
                (message) => {
                    api.success({ message });
                    closeForm();
                },
                (message) => api.error({ message })
            ));
        } else {
            dispatch(createMeasurement(data,
                (message) => {
                    api.success({ message });
                    closeForm();
                },
                (message) => api.error({ message })
            ));
        }
    }


    return {
        formActive, openForm, closeForm, form, submitForm
    };
}

export default useMeasurementForm;