import { FC } from 'react';
import { Button, Card, Col, Modal, Row, Space, Typography } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

import { Parser } from '../../utils';
import { button } from '../../styles';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setInvoiceDetailsModalActiveAs, setSelectedInvoice } from '../../redux/slices/invoices';

interface Props {
}

const InvoiceDetailsModal: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { detailsModalActive, selectedInvoice } = useAppSelector(state => state.invoices);

    const closeModal = () => {
        dispatch(setSelectedInvoice(null));
        dispatch(setInvoiceDetailsModalActiveAs(false));
    }

    if (!selectedInvoice)
        return null;

    return (
        <Modal open={detailsModalActive} title={`FACTURA DEL PEDIDO #${selectedInvoice.idOrder.toUpperCase()}`} width='70%' footer={[]} onCancel={closeModal}>
            <Row gutter={12}>
                <Col xs={{ span: 8 }}>
                    <Card size='small'>
                        <Typography.Text strong>
                            Datos del Cliente
                        </Typography.Text>
                        <Row style={{ marginTop: '0.5rem' }}>
                            <Typography.Text>{selectedInvoice.order.customerName}</Typography.Text>
                            <Typography.Text>{selectedInvoice.order.customerEmail}</Typography.Text>
                            <Typography.Text>
                                {Parser.parsePhoneNumber(selectedInvoice.order.customerPhoneNumber)}
                            </Typography.Text>
                        </Row>
                    </Card>
                </Col>
                <Col xs={{ span: 8 }}>
                    <Card size='small'>
                        <Typography.Text strong>
                            Dirección de Envío
                        </Typography.Text>
                        <Row style={{ marginTop: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                            <Typography.Text>{selectedInvoice.order.addressName}</Typography.Text>
                            <Typography.Text>{selectedInvoice.order.addressCompany}</Typography.Text>
                            <Typography.Text>{selectedInvoice.order.address1}</Typography.Text>
                            <Typography.Text>{selectedInvoice.order.address2}</Typography.Text>
                            <Typography.Text>
                                {`${selectedInvoice.order.addressCity} ${selectedInvoice.order.addressZipcode}`}
                            </Typography.Text>
                            <Typography.Text style={{ textTransform: 'uppercase' }}>
                                {`${Parser.parseToMunicipality(selectedInvoice.order.addressMunicipality)}, BAJA CALIFORNIA SUR, MÉXICO`}
                            </Typography.Text>
                        </Row>
                    </Card>
                </Col>
                <Col xs={{ span: 8 }}>
                    <Card size='small'>
                        <Typography.Text strong>
                            Resumen del Pedido
                        </Typography.Text>
                        <Row style={{ marginTop: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                            <Row justify='space-between'>
                                <Typography.Text>Productos:</Typography.Text>
                                <Typography.Text>{Parser.parseToPrice(selectedInvoice.order.subtotal)}</Typography.Text>
                            </Row>
                            <Row justify='space-between'>
                                <Typography.Text>Envío:</Typography.Text>
                                <Typography.Text>{Parser.parseToPrice(selectedInvoice.order.shippingFee)}</Typography.Text>
                            </Row>
                            <Row justify='space-between'>
                                <Typography.Text>Subtotal:</Typography.Text>
                                <Typography.Text>{Parser.parseToPrice(selectedInvoice.order.subtotal)}</Typography.Text>
                            </Row>
                            <Row justify='space-between' style={{ marginTop: '1rem' }}>
                                <Typography.Text>Total (IVA incluido):</Typography.Text>
                                <Typography.Text>{Parser.parseToPrice(selectedInvoice.order.total)}</Typography.Text>
                            </Row>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: '0.5rem' }}>
                <Col xs={{ span: 24 }}>
                    <Card size='small'>
                        <Typography.Text strong>
                            Datos de Facturación
                        </Typography.Text>
                        <Row>
                            <Space size='middle'>
                                <Typography.Text>
                                    RFC: {selectedInvoice.rfc}
                                </Typography.Text>
                                <Typography.Text>
                                    Razón Social: {selectedInvoice.socialReason}
                                </Typography.Text>
                                <Typography.Text>
                                    Régimen Fiscal: {selectedInvoice.taxRegime}
                                </Typography.Text>
                                <Typography.Text>
                                    Uso CFDI: {selectedInvoice.cfdiUse}
                                </Typography.Text>
                            </Space>
                        </Row>
                        <Row>
                            <Space size='middle'>
                                <Typography.Text>
                                    Domicilio: {selectedInvoice.address}
                                </Typography.Text>
                                <Typography.Text>
                                    No. Ext.: {selectedInvoice.exteriorNumber ?? 'N/A'}
                                </Typography.Text>
                                <Typography.Text>
                                    No. Int.: {selectedInvoice.interiorNumber ?? 'N/A'}
                                </Typography.Text>
                                <Typography.Text>
                                    Código Postal: {selectedInvoice.zipcode}
                                </Typography.Text>
                            </Space>
                        </Row>
                        <Row>
                            <Space size='middle'>
                                <Typography.Text>
                                    Colonia: {selectedInvoice.neighbourhood}
                                </Typography.Text>
                                <Typography.Text>
                                    Localidad: {selectedInvoice.locality}
                                </Typography.Text>
                                <Typography.Text>
                                    Ciudad: {selectedInvoice.city}
                                </Typography.Text>
                                <Typography.Text>
                                    Estado: {selectedInvoice.state}
                                </Typography.Text>
                                <Typography.Text>
                                    País: {selectedInvoice.country}
                                </Typography.Text>
                            </Space>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: '0.5rem' }}>
                {selectedInvoice.order.products.map((product, index) =>
                    <Col key={product.variantKey + index} xs={{ span: 24 }} style={{ marginBottom: '0.5rem' }}>
                        <Card size='small' >
                            <Row>
                                <img src={product.productImage} style={{ width: '5rem', marginRight: '1rem', aspectRatio: '1 / 1', objectFit: 'contain' }} />
                                <Row style={{ marginTop: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                    <Typography.Text strong>
                                        {product.productName}
                                    </Typography.Text>
                                    <Row>
                                        <Space size='middle'>
                                            <Typography.Text>
                                                Presentación: {product.measurement}
                                            </Typography.Text>
                                            <Typography.Text>
                                                Clave: {product.variantKey}
                                            </Typography.Text>
                                            <Typography.Text>
                                                Precio Unitario:{' '}
                                                <span style={{ color: product.idDiscount ? 'red' : undefined }}>
                                                    {Parser.parseToPrice(product.unitPrice - (product.discount ? (product.discount / 100) * product.unitPrice : 0))}
                                                </span>
                                            </Typography.Text>
                                            <Typography.Text>
                                                Cantidad: {product.quantity}
                                            </Typography.Text>
                                            {
                                                product.idColor
                                                    ? <Typography.Text>Color: {`${product.colorName} (${product.colorCode})`}</Typography.Text>
                                                    : null
                                            }
                                            {
                                                product.paintSheenName
                                                    ? <Typography.Text>Acabado: {product.paintSheenName}</Typography.Text>
                                                    : null
                                            }
                                        </Space>
                                    </Row>
                                    <Row>
                                        <Space size='middle'>
                                            <Typography.Text>
                                                Notas: {product.notes || 'N/A'}
                                            </Typography.Text>
                                        </Space>
                                    </Row>
                                </Row>
                            </Row>
                        </Card>
                    </Col>
                )}
            </Row>
            <Row gutter={12} style={{ marginTop: '0.5rem' }}>
                {
                    selectedInvoice.pdf
                        ? <Col xs={{ span: 12 }}>
                            <Card size='small'>
                                <Typography.Text strong>
                                    Archivo PDF
                                </Typography.Text>
                                <Button href={selectedInvoice.pdf} type='primary' style={{ width: '100%', marginTop: '1rem', ...button }}>
                                    Abrir archivo
                                </Button>
                            </Card>
                        </Col>
                        : null
                }
                {
                    selectedInvoice.xml
                        ? <Col xs={{ span: 12 }}>
                            <Card size='small'>
                                <Typography.Text strong>
                                    Archivo XML
                                </Typography.Text>
                                <Button href={selectedInvoice.xml} type='primary' style={{ width: '100%', marginTop: '1rem', ...button }}>
                                    Abrir archivo
                                </Button>
                            </Card>
                        </Col>
                        : null
                }
            </Row>
            <Row justify='end' style={{ marginTop: '1rem' }}>
                <Button type='primary' onClick={closeModal} style={button}>
                    Aceptar
                </Button>
            </Row>
        </Modal>
    );
}

export default InvoiceDetailsModal;