import { FC, useEffect } from 'react';
import { Button, Col, notification, Row, Space, Tooltip, Typography } from 'antd';
import { RetweetOutlined, SnippetsOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { ColorsModal, InspirationImagesFormModal, InspirationImagesTable, ModalDeleteConfirmation, SeasonalColorsModal } from '../components';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getAllColors, openColorsModal, openSeasonalColorsModal } from '../redux/slices/colors';
import { deleteInspirationImage, getAllInspirationImages, openInspirationImagesModal, setDeletePromptActive, setSelectedInspirationImage } from '../redux/slices/inspirationImages';

interface Props {
}

const Gallery: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { deletePromptActive, selectedInspirationImage } = useAppSelector(state => state.inspirationImages);

    const [api, contextHolder] = notification.useNotification();

    const getData = () => {
        dispatch(getAllColors());
        dispatch(getAllInspirationImages());
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            {contextHolder}
            <Typography.Title>
                Galería
            </Typography.Title>
            <Row justify='space-between'>
                <Col>
                    <Space align='center'>
                        <Button icon={<UnorderedListOutlined />} onClick={() => dispatch(openColorsModal())}>
                            COLORES
                        </Button>
                        <Button icon={<SnippetsOutlined />} onClick={() => dispatch(openSeasonalColorsModal())}>
                            COLORES DE TEMPORADA
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Space align='center'>
                        <Tooltip title='Actualizar datos'>
                            <Button icon={<RetweetOutlined />} onClick={getData} />
                        </Tooltip>
                        <Button type='primary' onClick={() => dispatch(openInspirationImagesModal())}>
                            AGREGAR IMAGEN DE INSPIRACIÓN
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row>
                <InspirationImagesTable />
                <ModalDeleteConfirmation
                    open={deletePromptActive}
                    title='¿Está seguro de eliminar la Imagen de Inspiración seleccionada?'
                    onOk={() => {
                        dispatch(deleteInspirationImage(selectedInspirationImage!.idInspirationImage,
                            () => {
                                api.success({
                                    message: 'La Imagen de Inspiración ha sido eliminada con éxito.',
                                });
                                dispatch(setDeletePromptActive(false));
                            },
                            () => {
                                api.error({
                                    message: 'Ha ocurrido un error al intentar eliminar la Imagen de Inspiración.',
                                });
                            }
                        ));
                    }}
                    onCancel={() => {
                        dispatch(setDeletePromptActive(false));
                        dispatch(setSelectedInspirationImage(null));
                    }}
                />
            </Row>
            <ColorsModal />
            <SeasonalColorsModal />
            <InspirationImagesFormModal />
        </>
    );
}

export default Gallery;