import { FC, useRef, useState } from 'react';
import { Button, Modal, notification, Row, Space, Typography } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import readXlsxFile from 'read-excel-file'

import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeExcelUploadModal } from '../../redux/slices/ui';
import { exportCSV } from '../../utils/excel';

interface Column {
    key: string;
    name: string;
}

interface Props {
    title: string;
    columns: Column[];
    onDataRead?: (data: any[]) => void;
    onClose?: () => void;
}

const ExcelUploadModal: FC<Props> = ({ title, columns, onDataRead, onClose }) => {
    const dispatch = useAppDispatch();
    const { excelUploadModalActive } = useAppSelector((state) => state.ui);

    const [api, contextHolder] = notification.useNotification();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null)

    const closeModal = () => {
        setSelectedFile(null);
        dispatch(closeExcelUploadModal())
        if (onClose) onClose();
    };

    const downloadTemplate = async () => {
        await exportCSV<any>(columns, [], {
            fileName: `Plantilla_${title}.xlsx`,
            sheet: 'Plantilla',
        });
    }

    const openFileInput = () => {
        inputRef.current!.click();
    }

    const onFileCapture = (fileList: FileList | null) => {
        if (!fileList || fileList.length === 0) return;

        const file = fileList[0];

        if (file.name.split('.')[file.name.split('.').length - 1] !== 'xlsx') {
            api.error({
                message: 'Formato de archivo inválido.',
            });
            return;
        }

        inputRef.current!.value = '';
        setSelectedFile(file);
    }

    const readFile = async () => {
        await readXlsxFile(selectedFile!).then((rows) => {
            if (rows[0].length !== columns.length) {
                api.error({
                    message: 'No se ha podido leer correctamente el archivo.',
                    description: 'Por favor verifique tenga la misma estructura que la plantilla.',
                });
                return;
            }

            const data = rows.slice(1).map((row) => {
                const record: any = {};

                row.forEach((cell, index) => {
                    record[columns[index].key] = cell;
                });

                return record;
            });

            if (onDataRead) {
                onDataRead(data);
                setSelectedFile(null);
            }
        }).catch((error) => {
            api.error({
                message: 'Ha ocurrido un error al intentar leer el archivo.',
                description: 'Por favor verifique que las celdas no tengan ningún formato, si el error continúa contacte a un administrador.',
                duration: 4
            });
        });
    }

    return (
        <Modal
            open={excelUploadModalActive}
            title={`Importar CSV de ${title}`.toUpperCase()}
            onOk={readFile}
            onCancel={closeModal}
            okButtonProps={{
                disabled: !Boolean(selectedFile),
            }}
            okText='ACEPTAR'
            cancelText='CANCELAR'
            width='50%'
        >
            {contextHolder}
            <Typography.Text>
                Solo se admiten archivos XLSX. Recuerde que si los registros tienen imágenes, deben ser subidas de manera individual.
                Para obtener la plantilla,{' '}
                <Typography.Text underline style={{ cursor: 'pointer' }} onClick={downloadTemplate}>
                    haga click aquí
                </Typography.Text>
                .
            </Typography.Text>
            <Row style={{ margin: '1rem 0' }} align='middle' justify='space-between'>
                <Button icon={<UploadOutlined />} onClick={openFileInput} >
                    SUBIR ARCHIVO
                </Button>
                {
                    selectedFile
                        ? <Space>
                            <Typography.Text>
                                Archivo seleccionado:{' '}
                                <Typography.Text underline>
                                    {selectedFile.name}
                                </Typography.Text>
                            </Typography.Text>
                            <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => setSelectedFile(null)} />
                        </Space>
                        : <Typography.Text>
                            No hay ningún archivo seleccionado.
                        </Typography.Text>
                }
                <input
                    style={{ display: 'none' }}
                    ref={inputRef as any}
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={({ target }) => onFileCapture(target.files)}
                />
            </Row>
        </Modal >
    );
}

export default ExcelUploadModal;