import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { mainApi } from "../../api";

import { AuthResponse, LoginFormData, SessionData } from "../../interfaces";
import { AppThunk } from "../store";
import { setLoading } from "./ui";

interface AuthState {
    userData: SessionData | undefined | null;
}

const initialState: AuthState = {
    userData: undefined,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserData(state, { payload }: PayloadAction<SessionData | undefined | null>) {
            state.userData = payload;
        },
    },
});

export const {
    setUserData,
} = authSlice.actions;

export const fetchUserData = (): AppThunk => {
    return async (dispatch) => {
        const token = Cookies.get('x-token');

        if (!token) {
            dispatch(setUserData(null));
            return;
        }

        try {
            const { data } = await mainApi.post<AuthResponse>('/auth/verify-user');

            dispatch(setUserData(data.user));
            Cookies.set('x-token', data.token);
        } catch (error) {
            dispatch(setUserData(null));
            Cookies.remove('x-token');
        }
    }
}

export const logIn = ({ email, password, remember }: LoginFormData, onError: () => void): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const { data } = await mainApi.post<AuthResponse>('/auth/login-user', {
                email,
                password,
            });

            dispatch(setLoading(false));
            dispatch(setUserData(data.user));
            if (remember) {
                Cookies.set('x-token', data.token);
            } else {
                Cookies.set('x-token', data.token, {
                    expires: 1
                });
            }
        } catch (error) {
            console.log('logIn', error);
            dispatch(setLoading(false));
            onError();
        }
    }
}

export const logOut = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            setTimeout(() => {
                dispatch(setUserData(null));
                Cookies.remove('x-token');

                dispatch(setLoading(false));
            }, 500);
        } catch (error) {
            console.log('logOut', error);
            dispatch(setLoading(false));
        }
    }
}

export default authSlice.reducer;