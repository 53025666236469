import { FC, useState } from 'react';
import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { Store } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteStore, setSelectedStore } from '../../redux/slices/stores';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import PreviewModal from './PreviewModal';

interface Props {
    onEdit: (record: Store) => void;
}

const StoresTable: FC<Props> = ({ onEdit }) => {
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useAppDispatch();
    const { stores, selectedStore } = useAppSelector(state => state.stores);

    const [detailsModalActive, setDetailsModalActive] = useState(false);
    const [deleteModalActive, setDeleteModalActive] = useState(false);

    const columns: ColumnsType<Store> = [
        {
            title: 'ID',
            key: 'idStore', dataIndex: 'idStore',
        },
        {
            title: 'Nombre',
            key: 'name', dataIndex: 'name',
        },
        {
            title: 'Descripción',
            key: 'description', dataIndex: 'description',
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn
                label='Tienda'
                onEditClick={() => {
                    onEdit(record);
                }}
                onDetailsClick={() => {
                    dispatch(setSelectedStore(record));
                    setDetailsModalActive(true);
                }}
                onDeleteClick={() => {
                    setDeleteModalActive(true);
                    dispatch(setSelectedStore(record));
                }}
            />
        }
    ]

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={stores} rowKey='idStore' />
            <ModalDeleteConfirmation
                open={deleteModalActive}
                title='¿Está seguro de eliminar la tienda seleccionada?'
                onCancel={() => {
                    setDeleteModalActive(false);
                    dispatch(setSelectedStore(null));
                }}
                onOk={() => {
                    if (!selectedStore) return setDeleteModalActive(false);
                    dispatch(deleteStore(selectedStore.idStore,
                        (message) => {
                            api.success({ message });
                            setDeleteModalActive(false);
                            dispatch(setSelectedStore(null));
                        },
                        (message) => api.error({ message }),
                    ));
                }}
            />
            <PreviewModal 
                open={detailsModalActive}
                onCancel={() => {
                    setDetailsModalActive(false);
                    dispatch(setSelectedStore(null));
                }}
            />
        </>
    );
}

export default StoresTable;