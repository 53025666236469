import { Layout } from 'antd';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { mainLayoutStyles } from '../../styles';
import { MainFooter, MainHeader, MainSider } from '../ui';

interface Props {
}

const MainLayout: FC<Props> = () => {
    return (
        <Layout hasSider>
            <MainSider />
            <Layout style={{ marginLeft: 200, minHeight: '100vh' }}>
                <MainHeader />
                <Layout.Content style={mainLayoutStyles.content}>
                    <Outlet />
                </Layout.Content>
                <MainFooter />
            </Layout>
        </Layout>
    );
}

export default MainLayout;