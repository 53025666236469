import { useMemo, useState } from 'react';
import { notification } from 'antd';

import { CouponFormData } from '../interfaces';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import { closeForm, closeModal, createCoupon, getAllCoupons, openForm, setSelectedCoupon, udpateCoupon } from '../redux/slices/coupons';

const useCouponsLogic = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { formActive, selectedCoupon } = useAppSelector(state => state.coupons);

    const title = useMemo(() => {
        if (formActive) {
            if (selectedCoupon) 
                return 'ACTUALIZAR CUPÓN';
            return 'AGREGAR NUEVO CUPÓN';
        }
        return 'GESTIÓN DE CUPONES';
    }, [formActive, selectedCoupon]);

    const refreshData = () => {
        dispatch(getAllCoupons());
    }

    const openCouponForm = () => {
        dispatch(openForm());
    }

    const closeCouponForm = () => {
        dispatch(closeForm());
        dispatch(setSelectedCoupon(null));
    }

    const closeCouponsModal = () => {
        dispatch(closeForm());
        dispatch(closeModal());
    }

    const onFinish = (data: CouponFormData) => {
        if (selectedCoupon)
            dispatch(udpateCoupon(selectedCoupon.idCoupon, data, api, closeCouponForm));
        else
            dispatch(createCoupon(data, api, closeCouponForm));
    }

    return {
        title,
        formActive,
        refreshData,
        openCouponForm,
        closeCouponForm,
        closeCouponsModal,
        onFinish,
        contextHolder,
    };
}

export default useCouponsLogic;