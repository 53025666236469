import { FC, useMemo, useState } from 'react';
import { Modal, notification } from 'antd';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../hooks';
import ButtonsRow from '../ui/ButtonsRow';
import { Columns, exportCSV } from '../../utils/excel';
import { Line } from '../../interfaces';
import LineForm from './LineForm';
import { closeLinesModal, getAllLines, setSelectedLine } from '../../redux/slices/lines';
import LinesTable from './LinesTable';

interface Props {
}

const LinesModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useAppDispatch();
    const { modalActive, lines, selectedLine } = useAppSelector(state => state.lines);

    const [formActive, setFormActive] = useState(false);

    const title = useMemo(() => {
        if (formActive) {
            if (selectedLine) return 'ACTUALIZAR LÍNEA';
            return 'AGREGAR NUEVA LÍNEA';
        }
        return 'GESTIÓN DE LAS LÍNEAS';
    }, []);

    const closeModal = () => {
        dispatch(setSelectedLine(null));
        dispatch(closeLinesModal());
    }

    const downloadExcel = async () => {
        const columns: Columns<Line> = [
            {
                key: 'idLine',
                name: 'ID',
            },
            {
                key: 'name',
                name: 'Nombre',
            },
            {
                key: 'description',
                name: 'Descripción',
            },
        ]

        await exportCSV<Line>(columns, lines, {
            fileName: `Listado_Líneas_${moment().format('DD-MM-YYYY')}.xlsx`,
            sheet: 'Líneas'
        });
    }

    return (
        <Modal open={modalActive} title={title} width='70%' footer={[]} onCancel={closeModal}>
            {contextHolder}
            {
                formActive
                    ? <LineForm 
                        api={api}
                        onClose={() => {
                            dispatch(setSelectedLine(null));
                            setFormActive(false);
                        }}
                    />
                    : <>
                        <ButtonsRow
                            text='agregar línea'
                            onRefreshClick={() => dispatch(getAllLines())}
                            onAddClick={() => setFormActive(true)}
                            onExportClick={downloadExcel}
                        />
                        <LinesTable
                            onEdit={(record) => {
                                dispatch(setSelectedLine(record));
                                setFormActive(true);
                            }}
                        />
                    </>
            }
        </Modal>
    );
}

export default LinesModal;