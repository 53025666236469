import { useMemo } from 'react';
import dayjs from 'dayjs';

import useAppSelector from './useAppSelector';
import { isInCurrentRange } from '../utils/dayjs';

const useDashboardProvider = () => {
    const { orders } = useAppSelector(state => state.orders);

    const weekSales = useMemo(() => {
        return orders.filter(o => o.status !== 'cancelled' && o.status !== 'created' && isInCurrentRange(dayjs(o.updatedAt!), 'week'));
    }, [orders]);

    const monthSales = useMemo(() => {
        return orders.filter(o => o.status !== 'cancelled' && o.status !== 'created' && isInCurrentRange(dayjs(o.updatedAt!), 'month'));
    }, [orders]);

    const totalSales = useMemo(() => {
        return orders.filter(o => o.status !== 'cancelled' && o.status !== 'created');
    }, [orders]);

    const pendingOrders = useMemo(() => {
        return orders.filter(o => o.status === 'pending');
    }, [orders]);

    const shippedOrders = useMemo(() => {
        return orders.filter(o => o.status === 'in-progress');
    }, [orders]);
    
    return {
        weekSales, monthSales, totalSales,
        pendingOrders, shippedOrders,
    };
}

export default useDashboardProvider;