import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Invoice, InvoiceFormData } from '../../interfaces';
import { mainApi } from '../../api';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { NotificationInstance } from 'antd/es/notification/interface';

interface InvoicesState {
    invoices: Invoice[];
    selectedInvoice: Invoice | null;
    detailsModalActive: boolean;
    actionsModalActive: boolean;
}

const initialState: InvoicesState = {
    invoices: [],
    selectedInvoice: null,
    detailsModalActive: false,
    actionsModalActive: false,
};

const InvoicesSlice = createSlice({
    name: 'Invoices',
    initialState,
    reducers: {
        setInvoices(state, { payload }: PayloadAction<Invoice[]>) {
            state.invoices = payload;
        },
        setSelectedInvoice(state, { payload }: PayloadAction<Invoice | null>) {
            state.selectedInvoice = payload;
        },
        setInvoiceDetailsModalActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.detailsModalActive = payload;
        },
        setInvoiceActionsModalActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.actionsModalActive = payload;
        },
    },
});

export const {
    setInvoices,
    setSelectedInvoice,
    setInvoiceDetailsModalActiveAs,
    setInvoiceActionsModalActiveAs,
} = InvoicesSlice.actions;

export const getAllInvoices = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get('/invoices');

            dispatch(setInvoices(data.invoices));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllInvoices', error);
            dispatch(finishLoading());
        }
    }
}

export const updateInvoice = (idInvoice: number, { pdf, xml }: InvoiceFormData, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data = new FormData();
            data.append('pdf', pdf);
            data.append('xml', xml);

            await mainApi.put(`/invoices/${idInvoice}`, data);

            dispatch(getAllInvoices());
            dispatch(finishLoading());
            api.success({ message: 'El registro de la factura ha sido actualizado con éxito.' });
            callback();
        } catch (error) {
            console.log('updateInvoice', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar actualizar el registro de la factura.' });
        }
    }
}

export const deleteInvoice = (idInvoice: number, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/invoices/${idInvoice}`);

            dispatch(getAllInvoices());
            dispatch(finishLoading());
            api.success({ message: 'El registro de la factura ha sido eliminado con éxito.' });
            callback();
        } catch (error) {
            console.log('deleteInvoice', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar eliminar el registro de la factura.' });
        }
    }
}

export default InvoicesSlice.reducer;