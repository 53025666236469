import { FC } from 'react';
import { Button, Card, Col, DatePicker, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector, useFormConstants, useFormRules } from '../../hooks';
import { button } from '../../styles';
import { NotificationInstance } from 'antd/es/notification/interface';
import { assignDeliveryDate } from '../../redux/slices/orders';
import { DeliveryDateForm } from '../../interfaces';

interface Props {
    api: NotificationInstance;
    onDeliveryDateAssigned: VoidFunction;
}

const DeliveryDateAction: FC<Props> = ({ api, onDeliveryDateAssigned }) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { selectedOrder } = useAppSelector(state => state.orders);
    const { required } = useFormRules();
    const { gutter, xs } = useFormConstants();

    const onFinish = ({ deliveryDate }: DeliveryDateForm) => {
        dispatch(assignDeliveryDate({
            idOrder: selectedOrder?.idOrder,
            deliveryDate,
        }, api, onDeliveryDateAssigned));
    }

    if (!selectedOrder) return null;
    return (
        <Card title='Asignar Fecha Entrega' style={{ width: '100%' }}>
            <Typography.Text>
                Al asignar una Fecha de Entrega, el Pedido pasará al estado "EN PROGRESO" y se actualizará el dato de Fecha de Entrega del pedido con la fecha indicada.
            </Typography.Text>
            <Form layout='vertical' autoComplete='off' style={{ marginTop: '1rem' }} form={form} onFinish={onFinish}>
                <Row gutter={gutter}>
                    <Col xs={xs}>
                        <Form.Item label='Fecha de Entrega' name='deliveryDate' rules={[required]}>
                            <DatePicker
                                format='YYYY-MM-DD'
                                disabledDate={(current) => dayjs(selectedOrder.orderDate) >= current}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='end'>
                    <Button type='primary' style={button} htmlType='submit'>
                        Asignar Fecha de Entrega
                    </Button>
                </Row>
            </Form>
        </Card>
    );
}

export default DeliveryDateAction;