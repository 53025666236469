import { FC, useMemo, useState } from 'react';
import { Button, Modal, Row, Space, notification } from 'antd';

import { useAppDispatch, useAppSelector, useMeasurementForm } from '../../hooks';
import { closeMeasurementsModal, getAllMeasurements } from '../../redux/slices/measurements';
import ButtonsRow from '../ui/ButtonsRow';
import MeasurementsTable from './MeasurementsTable';
import MeasurementFom from './MeasurementFom';
import { Columns, exportCSV } from '../../utils/excel';
import { Measurement } from '../../interfaces';
import moment from 'moment';
import { button } from '../../styles';
import UnitsModal from './UnitsModal';

interface Props {
}

const MeasurementsModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { modalActive, selectedMeasurement, measurements } = useAppSelector(state => state.measurements);

    const { formActive, openForm, closeForm, form, submitForm } = useMeasurementForm(api);

    const [unitsModalActive, setUnitsModalActive] = useState(false);

    const title = useMemo(() => {
        if (formActive) {
            if (selectedMeasurement) return 'ACTUALIZAR MEDIDA';
            return 'AGREGAR NUEVA MEDIDA';
        }
        return 'GESTIÓN DE MEDIDAS';
    }, [formActive]);

    const closeModal = () => {
        closeForm();
        dispatch(closeMeasurementsModal());
    }

    const downloadExcel = async () => {
        const columns: Columns<Measurement> = [
            {
                key: 'idMeasurement',
                name: 'ID',
            },
            {
                key: 'container',
                name: 'Contenedor',
            },
            {
                key: 'quantity',
                name: 'Capacidad',
            },
            {
                key: 'idUnit',
                name: 'ID Unidad',
            },
        ];

        await exportCSV<Measurement>(columns, measurements, {
            fileName: `Listado_Medidas_${moment().format('DD-MM-YYYY')}.xlsx`,
            sheet: 'Medidas'
        });
    }

    return (
        <Modal title={title} open={modalActive} width='70%' footer={[]} onCancel={closeModal}>
            {contextHolder}
            {
                formActive
                    ? <MeasurementFom
                        form={form}
                        onCancel={closeForm}
                        onFinish={submitForm}
                    />
                    : <>
                        <ButtonsRow
                            text='agregar medida'
                            onRefreshClick={() => dispatch(getAllMeasurements())}
                            onAddClick={() => openForm()}
                            onExportClick={downloadExcel}
                        />
                        <Row justify='end' style={{ marginTop: '0.5rem' }}>
                            <Space>
                                <Button style={button} onClick={() => setUnitsModalActive(true)}>
                                    Catálogo de unidades
                                </Button>
                            </Space>
                        </Row>
                        <MeasurementsTable
                            api={api}
                            onEdit={openForm}
                        />
                        <UnitsModal 
                            open={unitsModalActive}
                            onClose={() => setUnitsModalActive(false)}
                        />
                    </>
            }
        </Modal>
    );
}

export default MeasurementsModal;