import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mainApi } from '../../api';

import { GetInspirationImages, InspirationImage, InspirationImageForm } from '../../interfaces';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';

interface InspirationImagesState {
    inspirationImages: InspirationImage[];
    modalActive: boolean;
    selectedInspirationImage: InspirationImage | null;
    deletePromptActive: boolean;
}

const initialState: InspirationImagesState = {
    inspirationImages: [],
    modalActive: false,
    selectedInspirationImage: null,
    deletePromptActive: false,
}

const inspirationImagesSlice = createSlice({
    name: 'inspirationImages',
    initialState,
    reducers: {
        setInspirationImages(state, { payload }: PayloadAction<InspirationImage[]>) {
            state.inspirationImages = payload;
        },
        openInspirationImagesModal(state) {
            state.modalActive = true;
        },
        closeInspirationImagesModal(state) {
            state.modalActive = false;
        },
        setSelectedInspirationImage(state, { payload }: PayloadAction<InspirationImage | null>) {
            state.selectedInspirationImage = payload;
        },
        setDeletePromptActive(state, { payload }: PayloadAction<boolean>) {
            state.deletePromptActive = payload;
        }
    },
});

export const {
    setInspirationImages,
    openInspirationImagesModal,
    closeInspirationImagesModal,
    setSelectedInspirationImage,
    setDeletePromptActive,
} = inspirationImagesSlice.actions;

export const getAllInspirationImages = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetInspirationImages>('/inspirationImages');
            
            dispatch(setInspirationImages(data.inspirationImages));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllInspirationImages', error);
            dispatch(finishLoading());
        }
    }
}

export const createInspirationImage = ({ file, section, idColor }: InspirationImageForm, onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const formData = new FormData();
            formData.append('file', file);
            formData.append('section', section);
            formData.append('idColor', idColor.toString());

            await mainApi.post('/inspirationImages', formData);
            
            dispatch(finishLoading());
            onSuccess();
            dispatch(getAllInspirationImages());
        } catch (error) {
            console.log('createInspirationImage', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export const updateInspirationImage = (id: number, { file, section, idColor }: InspirationImageForm, onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const formData = new FormData();
            if (file) formData.append('file', file);
            formData.append('section', section);
            formData.append('idColor', idColor.toString());

            await mainApi.put(`/inspirationImages/${id}`, formData);
            
            dispatch(finishLoading());
            onSuccess();
            dispatch(getAllInspirationImages());
        } catch (error) {
            console.log('createInspirationImage', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export const deleteInspirationImage = (id: number, onSuccess: VoidFunction, onError: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/inspirationImages/${id}`);
            
            dispatch(finishLoading());
            onSuccess();
            dispatch(getAllInspirationImages());
        } catch (error) {
            console.log('createInspirationImage', error);
            dispatch(finishLoading());
            onError();
        }
    }
}

export default inspirationImagesSlice.reducer;