import { FC, useState } from 'react';
import { ColumnsType } from 'antd/es/table';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { InspirationImage } from '../../interfaces';
import { DataTable } from '../ui';
import { Button, Space, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import InspirationImagePreviewModal from './InspirationImagePreviewModal';
import { openInspirationImagesModal, setDeletePromptActive, setSelectedInspirationImage } from '../../redux/slices/inspirationImages';

interface Props {
}

const InspirationImagesTable: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { colors } = useAppSelector((state) => state.colors);
    const { inspirationImages } = useAppSelector(state => state.inspirationImages);

    const [previewModalActive, setPreviewModalActive] = useState(false);
    const [source, setSource] = useState('');

    const columns: ColumnsType<InspirationImage> = [
        {
            title: 'ID',
            dataIndex: 'idInspirationImage',
            key: 'idInspirationImage',
        },
        {
            title: 'Imagen',
            dataIndex: 'image',
            key: 'image',
            render: (image) =>
                <Button
                    icon={<EyeOutlined />}
                    onClick={() => {
                        setPreviewModalActive(true);
                        setSource(image);
                    }}
                >
                    ABRIR IMAGEN
                </Button>
        },
        {
            title: 'Color',
            dataIndex: 'idColor',
            key: 'idColor',
            render: (idColor) => (
                colors.find(c => c.idColor === idColor)
                    ? <Space>
                        <div
                            style={{
                                width: '3rem',
                                height: '1rem',
                                backgroundColor: `rgb${colors.find(c => c.idColor === idColor)!.rgb}`,
                                borderRadius: 2,
                            }}
                        />
                        <Typography.Text>
                            {colors.find(c => c.idColor === idColor)!.name}
                        </Typography.Text>
                    </Space>
                    : 'N/A'
            ),
        },
        {
            title: 'Sección',
            dataIndex: 'section',
            key: 'section',
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Tooltip title='Editar imagen'>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => {
                                dispatch(setSelectedInspirationImage(record));
                                dispatch(openInspirationImagesModal());
                            }}
                        />
                    </Tooltip>

                    <Tooltip title='Eliminar imagen'>
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                dispatch(setSelectedInspirationImage(record));
                                dispatch(setDeletePromptActive(true));
                            }}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <>
            <DataTable columns={columns} data={inspirationImages} rowKey='idColor' />
            <InspirationImagePreviewModal
                open={previewModalActive}
                onClose={() => {
                    setPreviewModalActive(false);
                    setSource('');
                }}
                source={source}
            />
        </>
    );
}

export default InspirationImagesTable;