import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryDateForm, GetOrders, Order } from '../../interfaces';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { mainApi } from '../../api';
import { NotificationInstance } from 'antd/es/notification/interface';
import { OrderConfirmForm } from '../../components/orders/OrderConfirmModal';

interface OrdersState {
    orders: Order[];
    selectedOrder: Order | null;
    detailsModalActive: boolean;
    actionsModalActive: boolean;
    unconfirmedModalActive: boolean;
}

const initialState: OrdersState = {
    orders: [],
    selectedOrder: null,
    detailsModalActive: false,
    actionsModalActive: false,
    unconfirmedModalActive: false,
};

const ordersSlice = createSlice({
    name: 'Orders',
    initialState,
    reducers: {
        setOrders(state, { payload }: PayloadAction<Order[]>) {
            state.orders = payload;
        },
        setSelectedOrder(state, { payload }: PayloadAction<Order | null>) {
            state.selectedOrder = payload;
        },
        openDetailsModal(state) {
            state.detailsModalActive = true;
        },
        closeDetailsModal(state) {
            state.detailsModalActive = false;
        },
        openActionsModal(state) {
            state.actionsModalActive = true;
        },
        closeActionsModal(state) {
            state.actionsModalActive = false;
        },
        setUnconfirmedModalActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.unconfirmedModalActive = payload;
        },
    },
});

export const {
    setOrders,
    setSelectedOrder,
    openDetailsModal,
    closeDetailsModal,
    openActionsModal,
    closeActionsModal,
    setUnconfirmedModalActiveAs,
} = ordersSlice.actions;

export const getAllOrders = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetOrders>('/orders');

            dispatch(setOrders(data.orders));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllOrders', error);
            dispatch(finishLoading());
        }
    }
}

export const assignDeliveryDate = ({ idOrder, deliveryDate }: DeliveryDateForm, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/orders/${idOrder}/assignDelivery`, { deliveryDate });

            dispatch(finishLoading());
            api.success({ message: 'La fecha de entrega del pedido ha sido asignada con éxito.' });
            callback();
        } catch (error) {
            console.log('assignDeliveryDate', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar asignar la fecha de entrega del pedido.' });
        }
    }
}

export const updateOrderStatus = (idOrder: string, status: string, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/orders/${idOrder}/updateStatus`, { status });

            dispatch(finishLoading());
            api.success({ message: 'El estado del pedido ha sido actualizado con éxito.' });
            callback();
        } catch (error) {
            console.log('updateOrderStatus', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar actualizar el estado del pedido.' });
        }
    }
}

export const deleteOrder = (idOrder: string, api: NotificationInstance, callback?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/orders/${idOrder}`);

            dispatch(finishLoading());
            api.success({ message: 'El pedido ha sido eliminado con éxito.' });
            if (callback) callback();
        } catch (error) {
            console.log('deleteOrder', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar eliminar el pedido.' });
        }
    }
}

export const confirmOrder = (idOrder: string, { paymentId, paymentMethod }: OrderConfirmForm, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            
            const { orders } = getState().orders;

            const order = orders.find(o => o.idOrder === idOrder)!;

            await mainApi.post('/orders/admin-confirm-payment', {
                preferenceId: order.paymentId,
                paymentId, paymentMethod,
            });
            
            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            console.log('confirmOrder', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export default ordersSlice.reducer;