import { FC } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select, Space, notification } from 'antd';
import { useAppDispatch, useFormConstants, useFormRules } from '../../hooks';
import { confirmOrder } from '../../redux/slices/orders';

interface Props {
    open: boolean;
    idOrder: string;
    onClose: VoidFunction;
}

export interface OrderConfirmForm {
    paymentMethod: string;
    paymentId: string;
}

const OrderConfirmModal: FC<Props> = ({ open, idOrder, onClose }) => {
    const dispatch = useAppDispatch();
    const { gutter, xs } = useFormConstants();
    const { required } = useFormRules();

    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm<OrderConfirmForm>();

    const onCancel = () => {
        form.resetFields();
        onClose();
    }

    const onSubmit = (data: OrderConfirmForm) => {
        dispatch(confirmOrder(idOrder, data, () => {
            api.success({ message: 'El Pedido ha sido confirmado con éxito.' });
            onClose();
        }, () => {
            api.error({ message: 'Ha ocurrido un error al intentar confirmar el Pedido.' });
            onClose();
        }))
    }


    return (
        <Modal open={open} title={`CONFIRMACIÓN DEL PEDIDO #${idOrder.toUpperCase()}`} onCancel={onCancel} width='60%' footer={[]}>
            {contextHolder}
            <Form form={form} layout='vertical' autoComplete='off' onFinish={onSubmit}>
                <Row gutter={gutter}>
                    <Col xs={xs}>
                        <Form.Item label='Método de Pago:' name='paymentMethod' rules={[required]}>
                            <Select
                                options={[
                                    { label: 'Tarjeta de Crédito', value: 'credit_card' },
                                    { label: 'Tarjeta de Débito', value: 'debit_card' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={xs}>
                        <Form.Item label='Referencia de Pago:' name='paymentId' rules={[required]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='end'>
                    <Space>
                        <Button onClick={onCancel}>
                            CANCELAR
                        </Button>
                        <Button type='primary' onClick={form.submit}>
                            CONFIRMAR
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default OrderConfirmModal;