import { FC, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { NotificationInstance } from 'antd/es/notification/interface';

import { Measurement } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { deleteMeasurement, setSelectedMeasurement } from '../../redux/slices/measurements';

interface Props {
    api: NotificationInstance;
    onEdit: (record: Measurement) => void;
}

const MeasurementsTable: FC<Props> = ({ api, onEdit }) => {
    const dispatch = useAppDispatch();
    const { measurements, selectedMeasurement, units } = useAppSelector(state => state.measurements);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    
    const columns: ColumnsType<Measurement> = [
        {
            title: 'ID',
            key: 'idMeasurement', dataIndex: 'idMeasurement',
        },
        {
            title: 'Contenedor',
            key: 'container', dataIndex: 'container'
        },
        {
            title: 'Cantidad',
            key: 'quantity', dataIndex: 'quantity',
        },
        {
            title: 'Unidad',
            key: 'idUnit', dataIndex: 'idUnit',
            render: (value) => units.find(u => u.idUnit === value)!.name
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn 
                label='Medida'
                showDetails={false}
                onEditClick={() => onEdit(record)}
                onDeleteClick={() => {
                    dispatch(setSelectedMeasurement(record));
                    setDeleteModalActive(true);
                }}
            />
        }
    ]

    return(
        <>
            <DataTable columns={columns} data={measurements} rowKey='idMeasurement' />
            <ModalDeleteConfirmation
                open={deleteModalActive}
                title='¿Está seguro de eliminar la medida seleccionada?'
                onCancel={() => {
                    setDeleteModalActive(false);
                    dispatch(setSelectedMeasurement(null));
                }}
                onOk={() => {
                    if (!selectedMeasurement) return setDeleteModalActive(false);
                    dispatch(deleteMeasurement(selectedMeasurement.idMeasurement,
                        (message) => {
                            api.success({ message });
                            setDeleteModalActive(false);
                            dispatch(setSelectedMeasurement(null));
                        },
                        (message) => api.error({ message }),
                    ));
                }}
            />
        </>
    );
}

export default MeasurementsTable;