import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Announcement, AnnouncementFormData, Customer, GetAnnouncements, GetCustomers, HTMLAnnouncementFormData } from '../../interfaces';
import { mainApi } from '../../api';
import { finishLoading, startLoading } from './ui';
import { AppThunk } from '../store';
import { NotificationInstance } from 'antd/es/notification/interface';

interface CustomersState {
    customers: Customer[];
    announcements: Announcement[];
    selectedAnnouncement: Announcement | null;
    announcementModalActive: boolean;
    announcementFormActive: boolean;
    announcementHTMLSubmitActive: boolean;
}

const initialState: CustomersState = {
    customers: [],
    announcements: [],
    selectedAnnouncement: null,
    announcementModalActive: false,
    announcementFormActive: false,
    announcementHTMLSubmitActive: false,
};

const customersSlice = createSlice({
    name: 'Customers',
    initialState,
    reducers: {
        setCustomers(state, { payload }: PayloadAction<Customer[]>) {
            state.customers = payload;
        },
        setAnnouncements(state, { payload }: PayloadAction<Announcement[]>) {
            state.announcements = payload;
        },
        setSelectedAnnouncement(state, { payload }: PayloadAction<Announcement | null>) {
            state.selectedAnnouncement = payload;
        },
        setAnnouncementModalActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.announcementModalActive = payload;
        },
        setAnnouncementFormActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.announcementFormActive = payload;
        },
        setAnnouncementHTMLSubmitActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.announcementHTMLSubmitActive = payload;
        }
    },
});

export const {
    setCustomers,
    setAnnouncements,
    setSelectedAnnouncement,
    setAnnouncementModalActiveAs,
    setAnnouncementFormActiveAs,
    setAnnouncementHTMLSubmitActiveAs,
} = customersSlice.actions;

export const getAllCustomers = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetCustomers>('/customers');

            dispatch(setCustomers(data.customers));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllCustomers', error);
            dispatch(finishLoading());
        }
    }
}

export const getAllAnnouncements = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetAnnouncements>('/announcements');

            dispatch(setAnnouncements(data.announcements));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllAnnouncements', error);
            dispatch(finishLoading());
        }
    }
}

export const sendAnnouncement = (idAnnouncement: number, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post(`/announcements/${idAnnouncement}`);

            dispatch(getAllAnnouncements());
            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            console.log('sendAnnouncement', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const createAnnouncement = (data: AnnouncementFormData, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const formData = new FormData();
            formData.append('mode', data.mode);
            formData.append('subject', data.subject);
            formData.append('title', data.title);
            formData.append('message', data.message);

            if (data.files.length > 0)
                formData.append('file1', data.files[0]);
            if (data.files.length > 1)
                formData.append('file2', data.files[1]);
            if (data.files.length > 2)
                formData.append('file3', data.files[2]);

            await mainApi.post('/announcements', formData);

            dispatch(getAllAnnouncements());
            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            console.log('createAnnouncement', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const updateAnnouncement = (idAnnouncement: number, data: AnnouncementFormData, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const formData = new FormData();
            formData.append('mode', data.mode);
            formData.append('subject', data.subject);
            formData.append('title', data.title);
            formData.append('message', data.message);

            if (data.files.length > 0)
                formData.append('file1', data.files[0]);
            if (data.files.length > 1)
                formData.append('file2', data.files[1]);
            if (data.files.length > 2)
                formData.append('file3', data.files[2]);

            await mainApi.put(`/announcements/${idAnnouncement}`, formData);

            dispatch(getAllAnnouncements());
            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            console.log('updateAnnouncement', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const deleteAnnouncement = (idAnnouncement: number, api: NotificationInstance, callback?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/announcements/${idAnnouncement}`);

            dispatch(getAllAnnouncements());
            dispatch(finishLoading());
            api.success({ message: 'El anuncio ha sido eliminado con éxito.' });
            if (callback) callback();
        } catch (error) {
            console.log('deleteAnnouncement', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar eliminar el anuncio.' });
        }
    }
}

export const sendHTMLTest = (html: File, testEmails: string, subject: string, api: NotificationInstance): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data = new FormData();
            data.append('file1', html);
            data.append('emails', testEmails);
            data.append('subject', subject);

            await mainApi.post('/announcements/html/test', data);

            dispatch(finishLoading());
            api.success({ message: 'La prueba de correo ha sido enviada con éxito.' });
        } catch (error) {
            console.log('sendHTMLTest', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar enviar la prueba de correo.' });
        }
    }
}

export const sendHTMLAnnouncement = (html: File, { mode, subject }: HTMLAnnouncementFormData, api: NotificationInstance, callback?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data = new FormData();
            data.append('file1', html);
            data.append('mode', mode);
            data.append('subject', subject);

            await mainApi.post('/announcements/html', data);

            dispatch(finishLoading());
            api.success({ message: 'El anuncio como HTML ha sido enviado con éxito.' });
            if (callback) callback();
        } catch (error) {
            console.log('sendHTMLAnnouncement', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar enviar el anuncio como HTML.' });
        }
    }
}

export default customersSlice.reducer;