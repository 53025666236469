import { FC, useEffect, useState } from 'react';
import { Col, Form, Modal, notification, Row, Select, Space, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeInspirationImagesModal, createInspirationImage, setSelectedInspirationImage, updateInspirationImage } from '../../redux/slices/inspirationImages';
import { ImagesInput } from '../ui';
import ImagesDisplay from '../ui/ImagesDisplay';

interface Props {
}

const sections = ['Recámara', 'Baño', 'Cocina', 'Estudio', 'Sala'];

const InspirationImagesFormModal: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { modalActive, selectedInspirationImage } = useAppSelector((state) => state.inspirationImages);
    const { colors } = useAppSelector((state) => state.colors);

    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [files, setFiles] = useState<File[]>([]);
    const [images, setImages] = useState<string[]>([]);

    const resetForm = () => {
        form.resetFields();
        setFiles([]);
        setImages([]);
    }

    useEffect(() => {
        if (selectedInspirationImage) {
            form.setFieldsValue({
                idColor: selectedInspirationImage.idColor,
                section: selectedInspirationImage.section,
            });
            setImages([selectedInspirationImage.image]);
        } else {
            form.resetFields();
            setFiles([]);
            setImages([]);
        }
    }, [selectedInspirationImage]);

    const closeModal = () => {
        resetForm();
        dispatch(setSelectedInspirationImage(null));
        dispatch(closeInspirationImagesModal());
    }

    const onFinish = (data: { idColor: number, section: string }) => {
        if (selectedInspirationImage) {
            dispatch(updateInspirationImage(selectedInspirationImage.idInspirationImage, {
                ...data, file: files[0] ?? null,
            }, () => {
                api.success({
                    message: 'La Imagen de Inspiración ha sido actualizada con éxito.',
                });
                closeModal();
            }, () => {
                api.error({
                    message: 'Ha ocurrido un error al intentar actualizar la Imagen de Inspiración.',
                });
            }));
        } else {
            if (files.length <= 0) return api.error({ message: 'No hay ninguna imagen seleccionada.' });
            dispatch(createInspirationImage({
                ...data, file: files[0],
            }, () => {
                api.success({
                    message: 'La Imagen de Inspiración ha sido agregada con éxito.',
                });
                closeModal();
            }, () => {
                api.error({
                    message: 'Ha ocurrido un error al intentar agregar la Imagen de Inspiración.',
                });
            }));
        }
    }

    return (
        <Modal
            title={selectedInspirationImage ? 'ACTUALIZAR IMAGEN DE INSPIRACIÓN' : 'AGREGAR NUEVA IMAGEN DE INSPIRACIÓN'}
            open={modalActive}
            onOk={form.submit}
            onCancel={closeModal}
            okText={selectedInspirationImage ? 'ACTUALIZAR' : 'AGREGAR'}
            cancelText='CANCELAR'
            width='70%'
        >
            {contextHolder}
            <Form layout='vertical' autoComplete='off' form={form} onFinish={onFinish} style={{ marginTop: '1rem' }}>
                <Row gutter={12}>
                    <Col xs={{ span: 12 }}>
                        <Form.Item
                            label='Color de Temporada:'
                            name='idColor'
                            rules={[
                                { required: true, message: 'Este campo es requerido.' }
                            ]}
                        >
                            <Select>
                                {
                                    colors.filter(c => c.isSeasonal === true).map((color) =>
                                        <Select.Option key={color.idColor} value={color.idColor}>
                                            <div style={{ display: 'flex' }}>
                                                <Space>
                                                    <div
                                                        style={{
                                                            width: '3rem',
                                                            height: '1rem',
                                                            backgroundColor: `rgb${color.rgb}`
                                                        }}
                                                    />
                                                    <Typography.Text>
                                                        {color.name}
                                                    </Typography.Text>
                                                </Space>
                                            </div>
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }}>
                        <Form.Item
                            label='Sección:'
                            name='section'
                            rules={[
                                { required: true, message: 'Este campo es requerido.' }
                            ]}
                        >
                            <Select>
                                {
                                    sections.map(section =>
                                        <Select.Option key={section} value={section}>{section}</Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 12 }}>
                        <Form.Item label="Imagen (1 máx.):">
                            <ImagesInput
                                files={files}
                                limit={1}
                                onFilesSubmit={(files) => setFiles(oldFiles => [...oldFiles, ...files])}
                                onImageDelete={(index) => setFiles(oldFiles => oldFiles.filter((_, oldIndex) => oldIndex !== index))}
                            />
                        </Form.Item>
                        {
                            selectedInspirationImage
                                ? <ImagesDisplay
                                    images={images}
                                    label='Imagen actual (será reemplazada si se sube una nueva imagen):'
                                />
                                : null
                        }
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default InspirationImagesFormModal;