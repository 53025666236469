
export const parseToPrice = (price: number) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(price);
}

export const parsePhoneNumber = (string: string) => {
    const totalLength = string.length;
    const prefixes = string.slice(0, totalLength - 10);
    const firstThreeDigits = string.slice(totalLength - 10, totalLength - 7);
    const secondThreeDigits = string.slice(totalLength - 7, totalLength - 4);
    const lastFourDigits = string.slice(totalLength - 4);
    return `${prefixes} ${firstThreeDigits} ${secondThreeDigits} ${lastFourDigits}`;
}

export const parseToMunicipality = (municipality: number) => {
    switch (municipality) {
        case 1:
            return 'La Paz';
        case 2:
            return 'Los Cabos';
        case 3: 
            return 'Comondú';
        case 4:
            return 'Loreto';
        case 5:
            return 'Mulegé';
        default:
            return 'N/A';
    }
}