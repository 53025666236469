import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mainApi } from '../../api';
import { Category, CategoryFormData, ExcelCategoryRow, GetCategories } from '../../interfaces';
import { FeedbackFunction } from '../../types';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';

interface CategoriesState {
    categories: Category[];
    modalActive: boolean;
    selectedCategory: Category | null;
}

const initialState: CategoriesState = {
    categories: [],
    modalActive: false,
    selectedCategory: null,
};

const categoriesSlice = createSlice({
    name: 'Categories',
    initialState,
    reducers: {   
        setCategories(state, { payload }: PayloadAction<Category[]>) {
            state.categories = payload;
        },
        openCategoriesModal(state) {
            state.modalActive = true;
        },
        closeCategoriesModal(state) {
            state.modalActive = false;
        },
        setSelectedCategory(state, { payload }: PayloadAction<Category | null>) {
            state.selectedCategory = payload;
        },
    },
});

export const {
    setCategories,
    openCategoriesModal,
    closeCategoriesModal,
    setSelectedCategory,
} = categoriesSlice.actions;

export const getAllCategories = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetCategories>('/categories');

            dispatch(setCategories(data.categories));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllCategories', error);
            dispatch(finishLoading());
        }
    }
}

export const createCategory = ({ name, description }: CategoryFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data: { name: string; description?: string; } = { name };
            if (description) data.description = description;

            await mainApi.post('/categories', data)

            dispatch(finishLoading());
            dispatch(getAllCategories());
            onSuccess('La categoría ha sido agregada con éxito.');
        } catch (error) {
            console.log('createCategory', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar agregar la categoría.')
        }
    }
}

export const updateCategory = (idCategory: number, { name, description }: CategoryFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data: { name: string; description?: string; } = { name };
            if (description) data.description = description;

            await mainApi.put(`/categories/${idCategory}`, data)

            dispatch(finishLoading());
            dispatch(getAllCategories());
            onSuccess('La categoría ha sido actualizada con éxito.');
        } catch (error) {
            console.log('updateCategory', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar actualizar la categoría.');
        }
    }
}

export const deleteCategory = (idCategory: number, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/categories/${idCategory}`);

            dispatch(finishLoading());
            dispatch(getAllCategories());
            onSuccess('La categoría ha sido eliminada con éxito.');
        } catch (error) {
            console.log('deleteCategory', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar eliminar la categoría.');
        }
    }
}

export const uploadCategoriesBatch = (data: ExcelCategoryRow[], onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(
                Array.from(Array(Math.ceil(data.length / 500)).keys()).map((i) =>
                    mainApi.post('/categories/massiveUpload',
                        {
                            data: data.slice(0 + (i * 500), 500 + (i * 500)),
                        }
                    )
                )
            );

            dispatch(finishLoading());
            dispatch(getAllCategories());
            onSuccess('La carga masiva de categorías ha sido completada con éxito.');
        } catch (error) {
            console.log('handleMassiveUpload', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar realizar la carga masiva de categorías.');
        }
    }
}

export default categoriesSlice.reducer;