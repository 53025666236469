import { FC, useState } from 'react';
import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { PaintSheen } from '../../interfaces';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deletePaintSheen, setPaintSheensFormActiveAs, setSelectedPaintSheen } from '../../redux/slices/paintSheens';

interface Props {
}

const TablePaintSheens: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const [modalDeleteActive, setModalDeleteActive] = useState(false)

    const dispatch = useAppDispatch();
    const { paintSheens, selectedPaintSheen } = useAppSelector(state => state.paintSheens);

    const columns: ColumnsType<PaintSheen> = [
        {
            title: 'ID',
            dataIndex: 'idPaintSheen', key: 'idPaintSheen',
        },
        {
            title: 'Nombre',
            dataIndex: 'name', key: 'name',
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => <ActionsColumn
                label='Acabado'
                showDetails={false}
                onEditClick={() => {
                    dispatch(setSelectedPaintSheen(record));
                    dispatch(setPaintSheensFormActiveAs(true));
                }}
                onDeleteClick={() => {
                    dispatch(setSelectedPaintSheen(record));
                    setModalDeleteActive(true);
                }}
            />
        }
    ];

    const closeDeleteModal = () => {
        dispatch(setSelectedPaintSheen(null));
        setModalDeleteActive(false);
    }

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={paintSheens} rowKey='idPaintSheen' />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar el acabado seleccionado?'
                open={modalDeleteActive}
                onCancel={closeDeleteModal}
                onOk={() => {
                    dispatch(
                        deletePaintSheen(selectedPaintSheen!.idPaintSheen,
                            () => {
                                api.success({ message: 'El acabado ha sido eliminado con éxito.' });
                                closeDeleteModal();
                            },
                            () => api.error({ message: 'Ha ocurrido un error al intentar eliminar el acabado.' }),
                        )
                    )

                }}
            />
        </>
    );
}

export default TablePaintSheens;