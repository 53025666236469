import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import {
    authReducer,
    bannersReducer,
    cancellationsReducer,
    categoriesReducer,
    colorsReducer,
    couponsReducer,
    customersReducer,
    discountsReducer,
    inspirationImagesReducer,
    invoicesReducer,
    linesReducer,
    measurementsReducer,
    ordersReducer,
    paintSheensReducer,
    productsFormReducer,
    productsReducer,
    shippingCostsReducer,
    storesReducer,
    typesReducer,
    uiReducer,
    usersReducer
} from "./slices";

const store = configureStore({
    reducer: {
        ui: uiReducer,
        auth: authReducer,

        users: usersReducer,
        types: typesReducer,

        banners: bannersReducer,
        colors: colorsReducer,
        inspirationImages: inspirationImagesReducer,

        products: productsReducer,

        stores: storesReducer,
        categories: categoriesReducer,
        lines: linesReducer,
        measurements: measurementsReducer,
        productsForm: productsFormReducer,

        discounts: discountsReducer,
        coupons: couponsReducer,

        orders: ordersReducer,
        shippingCosts: shippingCostsReducer,

        customers: customersReducer,

        invoices: invoicesReducer,
        cancellations: cancellationsReducer,

        paintSheens: paintSheensReducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false
        }),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;