import { FC, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';

import { DashboardCard, YearSalesGraph } from '../components';
import { useAppDispatch, useDashboardProvider, useSocket } from '../hooks';
import { Order } from '../interfaces';
import { finishLoading, startLoading } from '../redux/slices/ui';
import { getAllOrders, setOrders } from '../redux/slices/orders';
import { Configs } from '../utils';

interface Props {
}

const Dashboard: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { socket } = useSocket(Configs.API_URL);

    useEffect(() => {
        socket.on('orders-listener', (data: Order[]) => {
            dispatch(startLoading());
            setTimeout(() => {
                dispatch(setOrders(data));
                dispatch(finishLoading());
            }, 15);
        });

        return () => {
            socket.off('orders-listener');
        };
    }, [socket]);

    useEffect(() => {
        dispatch(getAllOrders());
    }, []);

    const { 
        weekSales, monthSales, totalSales,
        pendingOrders, shippedOrders,
    } = useDashboardProvider();

    return (
        <div>
            <Typography.Title>
                Dashboard
            </Typography.Title>
            <Row gutter={12}>
                <DashboardCard label='Ventas Semanales' value={weekSales.length} />
                <DashboardCard label='Ventas Mensuales' value={monthSales.length} />
                <DashboardCard label='Ventas Totales' value={totalSales.length} />
            </Row>
            <Row gutter={12}>
                <DashboardCard label='Pedidos Pendientes' value={pendingOrders.length} backgroundColor='#e67e22' />
                <DashboardCard label='Pedidos Enviados' value={shippedOrders.length} backgroundColor='#27ae60' />
            </Row>
            <Row gutter={12}>
                <Col xs={{ span: 8 }}>
                    <YearSalesGraph />
                </Col>
                {/* <Col xs={{ span: 8 }}>
                    <MonthlyProductSalesGraph orders={monthSales} />
                </Col> */}
            </Row>
        </div>
    );
}

export default Dashboard;