import { FC, useEffect } from 'react';
import { Col, Form, FormInstance, Input, notification, Row, Select } from 'antd';

import { ProductFormData } from '../../interfaces';
import { useAppSelector, useFormConstants, useFormRules, useProductsForms } from '../../hooks';
import { ImagesInput } from '../ui';
import { parseNameToSlug } from '../../utils/slug';
import ImagesDisplay from '../ui/ImagesDisplay';

interface Props {
    form: FormInstance<ProductFormData>;
    onFinish: (data: ProductFormData) => void;
}

const GeneralDataForm: FC<Props> = ({ form, onFinish }) => {
    const [api, contextHolder] = notification.useNotification();

    const { gutter, xs } = useFormConstants();
    const { required } = useFormRules();

    const { stores, categories, lines, selectedProduct } = useProductsForms();

    const name = Form.useWatch('name', form);
    const files = Form.useWatch('files', form);

    useEffect(() => {
        name
            ? form.setFieldValue('slug', parseNameToSlug(name))
            : form.setFieldValue('slug', '');
    }, [name]);

    const onFinishFailed = () => {
        api.error({
            message: 'Los datos generales no son válidos.',
            description: 'Por favor verifique que todos los campos sean correctos.'
        });
    }

    return (
        <>
            {contextHolder}
            <Form layout='vertical' autoComplete='off' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Row gutter={gutter}>
                    <Col xs={xs}>
                        <Form.Item label='Nombre:' name='name' rules={[required]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Slug:' name='slug'>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={xs}>
                        <Form.Item label='Descripción:' name='description' rules={[required]}>
                            <Input.TextArea rows={5} maxLength={750} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={gutter}>
                    <Col xs={xs}>
                        <Form.Item label='Tienda:' name='idStore' rules={[required]}>
                            <Select
                                options={[
                                    { value: 0, label: 'Sin Definir' },
                                    ...stores.map(({ idStore, name }) => ({ value: idStore, label: name })),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={xs}>
                        <Form.Item label='Categoría:' name='idCategory' rules={[required]}>
                            <Select
                                options={[
                                    { value: 0, label: 'Sin Definir' },
                                    ...categories.map(({ idCategory, name }) => ({ value: idCategory, label: name })),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={gutter}>
                    <Col xs={xs}>
                        <Form.Item label='Línea:' name='idLine' rules={[required]}>
                            <Select
                                options={[
                                    { value: 0, label: 'Sin Definir' },
                                    ...lines.map(({ idLine, name }) => ({ value: idLine, label: name })),
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={xs}>
                        <Form.Item label='Imagen:' name='files' initialValue={[]} rules={selectedProduct && selectedProduct.image !== 'N/A' ? [] : [required]}>
                            <ImagesInput
                                limit={1}
                                files={files ?? []}
                                onFilesSubmit={(newFiles) => form.setFieldValue('files', [...files, ...newFiles])}
                                onImageDelete={(index) => form.setFieldValue('files', files.filter((_, fileIndex) => fileIndex !== index))}
                            />
                        </Form.Item>
                        {
                            selectedProduct && selectedProduct.image !== 'N/A'
                                ? <ImagesDisplay images={[selectedProduct.image]} label='Imagen actual' />
                                : null
                        }
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default GeneralDataForm;