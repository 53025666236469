import { FC } from 'react';
import { Layout } from 'antd';

import { mainLayoutStyles } from '../../styles';

interface Props {
}

const MainFooter: FC<Props> = () => {
    return (
        <Layout.Footer style={mainLayoutStyles.footer}>
            Pinturas Famosas, S. A. de C. V. - Developed by Daniel De la Cruz.
        </Layout.Footer>
    );
}

export default MainFooter;