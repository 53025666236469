import { FC } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

import { useAppDispatch } from '../../hooks';
import { ExcelUploadModal } from '../ui';
import { uploadCategoriesBatch } from '../../redux/slices/categories';
import { ExcelCategoryRow } from '../../interfaces';
import { closeExcelUploadModal } from '../../redux/slices/ui';

interface Props {
    api: NotificationInstance;
}

const CategoriesImportModal: FC<Props> = ({ api }) => {
    const dispatch = useAppDispatch();

    const handleMassiveUpload = (data: ExcelCategoryRow[]) => {
        dispatch(uploadCategoriesBatch(data,
            (message) => {
                api.success({ message });
                dispatch(closeExcelUploadModal());
            },
            (message) => api.error({ message })
        ));
    }

    return (
        <ExcelUploadModal
            title='Categorías'
            columns={[
                {
                    key: 'idCategory',
                    name: 'ID',
                },
                {
                    key: 'name',
                    name: 'Nombre',
                },
                {
                    key: 'description',
                    name: 'Descripción',
                }
            ]}
            onDataRead={handleMassiveUpload}
        />
    );
}

export default CategoriesImportModal;