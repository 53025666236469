import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationInstance } from 'antd/es/notification/interface';

import { Discount, DiscountForm, DiscountVariant, GetDiscounts } from '../../interfaces';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { mainApi } from '../../api';
import { FeedbackFunction } from '../../types';

interface DiscountsState {
    discounts: Discount[];
    discountVariants: DiscountVariant[];
    formModalActive: boolean;
    selectedDiscount: Discount | null;
}

const initialState: DiscountsState = {
    discounts: [],
    discountVariants: [],
    formModalActive: false,
    selectedDiscount: null,
};

const discountsSlice = createSlice({
    name: 'Discounts',
    initialState,
    reducers: {
        setDiscounts(state, { payload }: PayloadAction<Discount[]>) {
            state.discounts = payload;
        },
        setDiscountVariants(state, { payload }: PayloadAction<DiscountVariant[]>) {
            state.discountVariants = payload;
        },
        openFormModal(state) {
            state.formModalActive = true;
        },
        closeFormModal(state) {
            state.formModalActive = false;
        },
        setSelectedDiscount(state, { payload }: PayloadAction<Discount | null>) {
            state.selectedDiscount = payload;
        },
    },
});

export const {
    setDiscounts,
    openFormModal,
    closeFormModal,
    setSelectedDiscount,
    setDiscountVariants,
} = discountsSlice.actions;

export const getAllDiscounts = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetDiscounts>('/discounts');

            dispatch(setDiscounts(data.discounts));
            dispatch(setDiscountVariants(data.discountVariants));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllDiscounts', error);
            dispatch(finishLoading());
        }
    }
}

export const createDiscount = (data: DiscountForm, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/discounts', data);

            dispatch(getAllDiscounts());
            dispatch(finishLoading());
            api.success({ message: 'El descuento ha sido agregado con éxito.' });
            callback();
        } catch (error) {
            console.log('createDiscount', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar agregar el descuento.' });
        }
    }
}

export const updateDiscount = (idDiscount: number, data: DiscountForm, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/discounts/${idDiscount}`, data);

            dispatch(getAllDiscounts());
            dispatch(finishLoading());
            api.success({ message: 'El descuento ha sido actualizado con éxito.' });
            callback();
        } catch (error) {
            console.log('updateDiscount', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar actualizar el descuento.' });
        }
    }
}

export const deleteDiscount = (idDiscount: number, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/discounts/${idDiscount}`);

            dispatch(getAllDiscounts());
            dispatch(finishLoading());
            onSuccess('El descuento ha sido eliminado con éxito.')
        } catch (error) {
            console.log('deleteDiscount', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar eliminar el descuento seleccionado.')
        }
    }
}

export default discountsSlice.reducer;