import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { Col, Modal, Radio, RadioChangeEvent, Row, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeDetailsModal, setSelectedProduct } from '../../redux/slices/products';

interface Props {
}

const ProductDetailsModal: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { measurements, units } = useAppSelector(state => state.measurements);
    const { detailsModalActive, selectedProduct, allImages, allVariants, allFeatures } = useAppSelector(state => state.products);
    const { paintSheens } = useAppSelector(state => state.paintSheens);

    const features = useMemo(() => {
        if (!selectedProduct) return [];
        return allFeatures.filter(feature => feature.idProduct === selectedProduct.idProduct);
    }, [selectedProduct, allFeatures]);

    const variants = useMemo(() => {
        if (!selectedProduct) return [];
        return allVariants.filter(variant => variant.idProduct === selectedProduct.idProduct);
    }, [selectedProduct]);
    const variantOptions = useMemo(() => {
        if (variants.length === 0) return [];
        return variants.map((variant, index) => {
            const measurement = measurements.find(m => m.idMeasurement === variant.idMeasurement)!;
            const unit = units.find(u => u.idUnit === measurement.idUnit)!;
            return {
                label: `${measurement.container.toUpperCase()} ${measurement.quantity} ${unit.abbreviation}${measurement.quantity > 1 ? 'S' : ''}`,
                value: index,
            };
        });
    }, [variants]);
    const [selectedVariant, setSelectedVariant] = useState(0);

    const selectedPrice = useMemo(() => {
        if (variants.length === 0) return 0;
        return variants[selectedVariant].generalPrice
    }, [variants, selectedVariant]);

    const images = useMemo(() => {
        if (!selectedProduct) return [];
        return allImages.filter(img => variants.map(variant => variant.idVariant).includes(img.idVariant));
    }, [selectedProduct, allVariants]);
    const [selectedImage, setSelectedImage] = useState(0);

    const selectedPaintSheens = useMemo(() => {
        if (variants[selectedVariant] && variants[selectedVariant].paintSheens) {
            return paintSheens
                .filter(ps => variants[selectedVariant].paintSheens!.split(';').includes(ps.idPaintSheen.toString()))
                .map(ps => ({ label: ps.name, value: ps.idPaintSheen }));
        }
        return [];
    }, [variants, paintSheens, selectedVariant, selectedProduct]);

    useEffect(() => {
        if (variants[selectedVariant]) {
            const image = allImages.find(img => img.idVariant === variants[selectedVariant].idVariant);
            if (image) {
                setSelectedImage(images.findIndex(img => img.idImage === image.idImage) + 1);
            }
        }
    }, [selectedVariant]);

    const smallImage: CSSProperties = {
        maxWidth: '100%',
        aspectRatio: 1 / 1,
        borderRadius: '5px',
        marginBottom: '0.25rem',
        objectFit: 'contain',
        cursor: 'pointer',
    }

    const handleVariantChange = ({ target: { value } }: RadioChangeEvent) => {
        setSelectedVariant(value);

    }

    const closeModal = () => {
        setSelectedImage(0);
        setSelectedVariant(0);
        dispatch(closeDetailsModal());
        dispatch(setSelectedProduct(null));
    }

    if (!selectedProduct) return null;

    return (
        <Modal open={detailsModalActive} width='85%' footer={[]} onCancel={closeModal}>
            <Row gutter={12}>
                <Typography.Title style={{ fontWeight: 300 }}>
                    {selectedProduct.name.toUpperCase()}
                </Typography.Title>
            </Row>
            <Row gutter={12}>
                <Col xs={{ span: 1 }}>
                    <img
                        src={selectedProduct.image}
                        style={{
                            ...smallImage,
                            border: `1px ${selectedImage === 0 ? '#f6c708' : '#313c51'} solid`,
                        }}
                        onClick={() => setSelectedImage(0)}
                    />
                    {
                        images.map((img, index) =>
                            <img
                                key={index.toString()}
                                src={img.url}
                                style={{
                                    ...smallImage,
                                    border: `1px ${index + 1 === selectedImage ? '#f6c708' : '#313c51'} solid`,
                                }}
                                onClick={() => setSelectedImage(index + 1)}
                            />
                        )
                    }
                </Col>
                <Col xs={{ span: 8 }}>
                    <img
                        src={selectedImage === 0 ? selectedProduct.image : images[selectedImage - 1].url}
                        style={{
                            width: '100%',
                            border: '1px #313c51 solid',
                            borderRadius: '5px',
                            aspectRatio: 1 / 1,
                            objectFit: 'contain'
                        }}
                    />
                </Col>
                <Col xs={{ span: 15 }}>
                    <Typography.Title style={{ fontSize: '2rem', fontWeight: 400, marginBottom: 0 }} >
                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(selectedPrice)}
                    </Typography.Title>
                    {
                        (variants.length > 0 && variants[selectedVariant].usesSpecialPrices === true) ?
                            <Typography.Text>
                                * Esta presentación tiene múltiples precios y solo se muestra el primer precio encontrado.
                            </Typography.Text>
                            : null
                    }
                    <Row style={{ marginTop: '1rem' }}>
                        <Typography.Text strong>
                            Presentación
                        </Typography.Text>
                    </Row>
                    <Radio.Group
                        options={variantOptions}
                        onChange={handleVariantChange}
                        value={selectedVariant}
                        optionType="button"
                        buttonStyle="solid"
                    />
                    {
                        variants[selectedVariant] && variants[selectedVariant].paintSheens
                            ? <>
                                <Row style={{ marginTop: '1rem' }}>
                                    <Typography.Text strong>
                                        Acabados
                                    </Typography.Text>
                                </Row>
                                <Radio.Group
                                    options={selectedPaintSheens}
                                    optionType="button"
                                    buttonStyle="solid"
                                    disabled
                                />
                            </>
                            : null
                    }
                    <Row style={{ margin: '1rem 0' }}>
                        <Col xs={{ span: 24 }}>
                            <Typography.Text strong style={{ display: 'block' }}>
                                Descripción
                            </Typography.Text>
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Typography.Paragraph style={{ textAlign: 'justify', display: 'block' }}>
                                {selectedProduct.description}
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                    {
                        features.length > 0
                            ? <>
                                <Row>
                                    <Typography.Text strong>
                                        Características
                                    </Typography.Text>
                                </Row>
                                <ul>
                                    {
                                        features.map(feature =>
                                            <li key={feature.idFeature} style={{ listStylePosition: 'inside' }} >
                                                {feature.description}
                                            </li>
                                        )
                                    }
                                </ul>
                            </>
                            : null
                    }
                </Col>
            </Row>
        </Modal>
    );
}

export default ProductDetailsModal;