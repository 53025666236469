import { FC, useMemo, useState } from 'react';
import { Input, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';

interface Props {
    columns: ColumnsType<any>;
    data: readonly any[];
    rowKey: string;
    showSearchBar?: boolean;
}

interface SearchBarProps {
    search: string;
    setSearch: (value: string) => void;
}

const DataTableSearchBar: FC<SearchBarProps> = ({ search, setSearch }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
            <div style={{ width: '25%' }}>
                <Input
                    placeholder='Buscar en la tabla...'
                    prefix={<SearchOutlined />}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                />
            </div>
        </div>
    );
}

/**
 * columns: ColumnsType<T>;
 * 
 * data: <T>[];
 * 
 * rowKey: string;
 */
const DataTable: FC<Props> = ({ columns, data, rowKey, showSearchBar = true }) => {
    const [search, setSearch] = useState('');

    const displayedData = useMemo(() => {
        if (search === '') {
            return data;
        }
        const filteredData: any[] = [];
        data.forEach((record) => {
            let recordMatches = false;
            columns.forEach((column) => {
                if (typeof record[column.key!] === 'string') {
                    if (record[column.key!].toLowerCase().includes(search.toLowerCase())) {
                        recordMatches = true;
                    }
                }
                if (typeof record[column.key!] === 'number') {
                    if (record[column.key!].toString().toLowerCase().includes(search.toLowerCase())) {
                        recordMatches = true
                    }
                }
            });
            if (recordMatches) {
                filteredData.push(record);
            }
        })
        return filteredData;
    }, [search, data, columns]);

    return (
        <div style={{ width: '100%', margin: '1.5rem 0' }}>
            {
                showSearchBar
                    ? <DataTableSearchBar search={search} setSearch={setSearch} />
                    : null
            }
            <Table columns={columns} dataSource={displayedData} style={{ width: '100%' }} rowKey={rowKey} />
        </div>
    );
}

export default DataTable;