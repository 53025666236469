import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAccess } from '../hooks';

interface Props {
    children: ReactNode;
}

const PublicRoute: FC<Props> = ({ children }) => {
    const { isAuthenticated, allowedLocations } = useAccess();

    return(
        <>
            {
                isAuthenticated 
                    ? <Navigate to={allowedLocations[0]} replace />
                    : children
            }
        </>
    );
}

export default PublicRoute;