import { FC, useState } from 'react';
import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import { Invoice } from '../../interfaces';
import InvoiceStatus from './InvoiceStatus';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { deleteInvoice, setInvoiceActionsModalActiveAs, setInvoiceDetailsModalActiveAs, setSelectedInvoice } from '../../redux/slices/invoices';

interface Props {
}

const TableInvoices: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const [deleteModalActive, setDeleteModalActive] = useState(false);

    const dispatch = useAppDispatch();
    const { invoices, selectedInvoice } = useAppSelector(state => state.invoices);

    const columns: ColumnsType<Invoice> = [
        {
            title: 'ID',
            key: 'idInvoice', dataIndex: 'idInvoice',
        },
        {
            title: 'ID Pedido',
            key: 'idOrder', dataIndex: 'idOrder',
            render: (value) => '#' + value.toUpperCase(),
        },
        {
            title: 'Fecha Solicitud',
            key: 'createdAt', dataIndex: 'createdAt',
            render: (value) => moment(value).format('LLL')
        },
        {
            title: 'Status',
            key: 'status', dataIndex: 'status',
            render: (value) => <InvoiceStatus status={value} />
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn
                label='Factura'
                onDetailsClick={() => {
                    dispatch(setSelectedInvoice(record));
                    dispatch(setInvoiceDetailsModalActiveAs(true));
                }}
                onEditClick={() => {
                    dispatch(setSelectedInvoice(record));
                    dispatch(setInvoiceActionsModalActiveAs(true));
                }}
                onDeleteClick={() => {
                    dispatch(setSelectedInvoice(record));
                    setDeleteModalActive(true);
                }}
            />
        }
    ];

    const closeDeleteModal = () => {
        setDeleteModalActive(false);
        dispatch(setSelectedInvoice(null));
    }

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={invoices} rowKey='idInvoice' />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar la factura seleccionada?'
                open={deleteModalActive}
                onCancel={closeDeleteModal}
                onOk={() => {
                    dispatch(deleteInvoice(selectedInvoice!.idInvoice, api, closeDeleteModal));
                }}
            />
        </>
    );
}

export default TableInvoices;