import { Button, Card, Col, Form, Input, Row, Select, Space, Typography, notification } from 'antd';
import { FC, useMemo, useState } from 'react';

import { button } from '../../styles';
import { useAppDispatch, useFormConstants, useFormRules } from '../../hooks';
import { SingleFileInput } from '../ui';
import { HTMLAnnouncementFormData } from '../../interfaces';
import { sendHTMLAnnouncement, sendHTMLTest, setAnnouncementHTMLSubmitActiveAs, setSelectedAnnouncement } from '../../redux/slices/customers';
import { Validators } from '../../utils';
import { NotificationInstance } from 'antd/es/notification/interface';

interface Props {
    api: NotificationInstance;
}

const AnnouncementSubmit: FC<Props> = ({ api }) => {
    const [form] = Form.useForm<HTMLAnnouncementFormData>();

    const testEmails = Form.useWatch('testEmails', form);
    const subject = Form.useWatch('subject', form);

    const dispatch = useAppDispatch();
    const { gutter, xs } = useFormConstants();
    const { required } = useFormRules();

    const [html, setHtml] = useState<File | null>(null);

    const canSendTestEmails = useMemo(() => {
        if (!testEmails)
            return false;
        let validTestEmails = testEmails.trim().length > 0 ? true : false;
        if (testEmails)
            testEmails.split(';').forEach(email => {
                if (!Validators.isValidEmail(email))
                    validTestEmails = false;
            });
        return (html !== null && validTestEmails && subject && subject.length > 0);
    }, [html, testEmails, subject])

    const closeForm = () => {
        form.resetFields();
        dispatch(setSelectedAnnouncement(null));
        dispatch(setAnnouncementHTMLSubmitActiveAs(false));
    }

    const handleSubmit = (data: HTMLAnnouncementFormData) => {
        if (html)
            dispatch(sendHTMLAnnouncement(html, data, api, closeForm));
    }

    const sendTest = () => {
        if (html && testEmails)
            dispatch(sendHTMLTest(html, testEmails, subject, api));
    }

    return (
        <Form layout='vertical' autoComplete='off' onFinish={handleSubmit} form={form}>
            <Row gutter={gutter} style={{ marginBottom: '1rem' }}>
                <Col xs={xs}>
                    <Form.Item label='Tipo:' name='mode' help='Los correos promocionales solo se enviarán a los clientes que esten suscritos, los correos de anuncio serán enviados a todos los clientes.' rules={[required]}>
                        <Select
                            options={[
                                { label: 'Promoción', value: 'promotional' },
                                { label: 'Anuncio', value: 'public' }
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={xs}>
                    <Form.Item label='Asunto:' name='subject' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 24 }}>
                    <Card size='small'>
                        <Typography.Text strong>
                            Archivo HTML
                        </Typography.Text>
                        <SingleFileInput file={html} accept='text/html' onFileCapture={(file) => setHtml(file)} />
                    </Card>
                </Col>
            </Row>
            <Row align='middle' style={{ marginTop: '1rem' }}>
                <Col xs={{ span: 16 }}>
                    <Form.Item label='Correos de Prueba:' name='testEmails' help='Se deben escribir las direcciones de correo electrónico sin espacios separados por un punto y coma. Ejemplo: example1@server.com;example2@server.com'>
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 8 }} style={{ paddingLeft: '1rem' }}>
                    <Button type='primary' disabled={!canSendTestEmails} style={{ ...button, width: '100%' }} onClick={sendTest}>
                        Enviar correo de prueba
                    </Button>
                </Col>
            </Row>
            <Row justify='end' style={{ marginTop: '1rem' }}>
                <Space>
                    <Button style={button} onClick={closeForm}>
                        Cancelar
                    </Button>
                    <Button type='primary' htmlType='submit' disabled={html === null} style={button}>
                        Enviar
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default AnnouncementSubmit;