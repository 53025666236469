import { Image, Space, Typography } from 'antd';
import { FC } from 'react';

interface Props {
    images: string[];
    onImageClick?: (index: number) => void;
    label?: string;
    allowPreview?: boolean;
}

const ImagesDisplay: FC<Props> = ({ images, onImageClick, label = 'Imágenes actuales (Haz click sobre ellas para eliminarlas):', allowPreview = false }) => {
    return (
        <div>
            <Typography.Text>{label}</Typography.Text>
            <div style={{ maxWidth: '80%', margin: '0 auto' }}>
                {
                    images.length > 0
                        ? <Space style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                                images.map((img, index) =>
                                    <Image
                                        key={img}
                                        src={img}
                                        style={{
                                            width: '5rem',
                                            height: '5rem',
                                            objectFit: 'cover',
                                            cursor: 'pointer',
                                        }}
                                        preview={allowPreview}
                                        onClick={() => {
                                            if (onImageClick) onImageClick(index);
                                        }}
                                    />
                                )
                            }
                        </Space>
                        : <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography.Text>
                                No hay imágenes actuales.
                            </Typography.Text>
                        </div>
                }
            </div>
        </div>
    );
}

export default ImagesDisplay;