import { CSSProperties } from "react";

export const cardBodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}

export const imageContainer: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
}