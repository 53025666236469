import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPaintSheens, PaintSheen, PaintSheenFormData } from '../../interfaces';
import { mainApi } from '../../api';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { NotificationInstance } from 'antd/es/notification/interface';

interface PaintSheensState {
    paintSheens: PaintSheen[];
    modalActive: boolean;
    formActive: boolean;
    selectedPaintSheen: PaintSheen | null;
}

const initialState: PaintSheensState = {
    paintSheens: [],
    modalActive: false,
    formActive: false,
    selectedPaintSheen: null,
};

const paintSheensSlice = createSlice({
    name: 'PaintSheens',
    initialState,
    reducers: {
        setPaintSheens(state, { payload }: PayloadAction<PaintSheen[]>) {
            state.paintSheens = payload;
        },
        setPaintSheensModalActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.modalActive = payload;
        },
        setPaintSheensFormActiveAs(state, { payload }: PayloadAction<boolean>) {
            state.formActive = payload;
        },
        setSelectedPaintSheen(state, { payload }: PayloadAction<PaintSheen | null>) {
            state.selectedPaintSheen = payload;
        },
    },
});

export const {
    setPaintSheens,
    setPaintSheensModalActiveAs,
    setPaintSheensFormActiveAs,
    setSelectedPaintSheen,
} = paintSheensSlice.actions;

export const getAllPaintSheens = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetPaintSheens>('/paintSheens');

            dispatch(setPaintSheens(data.paintSheens));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllPaintSheens', error);
            dispatch(finishLoading());
        }
    }
}

export const createPaintSheen = (data: PaintSheenFormData, api: NotificationInstance, callback?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/paintSheens', data);

            dispatch(getAllPaintSheens());
            dispatch(finishLoading());
            api.success({ message: 'El acabado ha sido agregado con éxito.' });
            if (callback) callback();
        } catch (error) {
            console.log('createPaintSheen', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar agregar el acabado.' });
        }
    }
}

export const updatePaintSheen = (idPaintSheen: number, data: PaintSheenFormData, api: NotificationInstance, callback?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/paintSheens/${idPaintSheen}`, data);

            dispatch(getAllPaintSheens());
            dispatch(finishLoading());
            api.success({ message: 'El acabado ha sido actualizado con éxito.' });
            if (callback) callback();
        } catch (error) {
            console.log('updatePaintSheen', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar actualizar el acabado.' });
        }
    }
}

export const deletePaintSheen = (idPaintSheen: number, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/paintSheens/${idPaintSheen}`);

            dispatch(getAllPaintSheens());
            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            console.log('deletePaintSheen', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export default paintSheensSlice.reducer;