import { Rule } from 'antd/es/form';

const useFormRules = () => {
    const required: Rule = { required: true, message: 'Este campo es requerido.' };
    
    const percentage: Rule = { type: 'number', min: 1, max: 100, message: 'El rango de valores debe estar entre 1 y 100.' };

    return {
        required,
        percentage,
    };
}

export default useFormRules;