import { FC, useState } from 'react';
import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { Category } from '../../interfaces';
import { deleteCategory, setSelectedCategory } from '../../redux/slices/categories';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';

interface Props {
    onEdit: (record: Category) => void;
}

const CategoriesTable: FC<Props> = ({ onEdit }) => {
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useAppDispatch();
    const { categories, selectedCategory } = useAppSelector(state => state.categories);

    const [deleteModalActive, setDeleteModalActive] = useState(false);

    const columns: ColumnsType<Category> = [
        {
            title: 'ID',
            key: 'idCategory', dataIndex: 'idCategory',
        },
        {
            title: 'Nombre',
            key: 'name', dataIndex: 'name',
        },
        {
            title: 'Descripción',
            key: 'description', dataIndex: 'description',
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn
                label='Categoría'
                showDetails={false}
                onEditClick={() => {
                    onEdit(record);
                }}
                onDeleteClick={() => {
                    setDeleteModalActive(true);
                    dispatch(setSelectedCategory(record));
                }}
            />
        }
    ]

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={categories} rowKey='idCategory' />
            <ModalDeleteConfirmation
                open={deleteModalActive}
                title='¿Está seguro de eliminar la categoría seleccionada?'
                onOk={() => {
                    if (!selectedCategory) return setDeleteModalActive(false);
                    dispatch(deleteCategory(selectedCategory.idCategory,
                        (message) => {
                            api.success({ message });
                            setDeleteModalActive(false);
                            dispatch(setSelectedCategory(null));
                        },
                        (message) => api.error({ message }),
                    ));
                }}
                onCancel={() => {
                    setDeleteModalActive(false);
                    dispatch(setSelectedCategory(null));
                }}
            />
        </>
    );
}

export default CategoriesTable;