import { Button, Modal, Row, Space, Typography, notification } from 'antd';
import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setPaintSheensFormActiveAs, setPaintSheensModalActiveAs } from '../../redux/slices/paintSheens';
import { RetweetOutlined } from '@ant-design/icons';
import { button } from '../../styles';
import TablePaintSheens from './TablePaintSheens';
import PaintSheenForm from './PaintSheenForm';

interface Props {
}

const PaintSheensModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    
    const dispatch = useAppDispatch();
    const { modalActive, formActive, selectedPaintSheen } = useAppSelector(state => state.paintSheens);

    const closeModal = () => {
        dispatch(setPaintSheensFormActiveAs(false));
        dispatch(setPaintSheensModalActiveAs(false));
    }

    const modalTitle = useMemo(() => {
        if (formActive) {
            if (selectedPaintSheen)
                return 'ACTUALIZAR ACABADO';
            return 'AGREGAR NUEVO ACABADO';
        }
        return 'GESTIÓN DE ACABADOS';
    }, [formActive]);

    const openForm = () => {
        dispatch(setPaintSheensFormActiveAs(true));
    }

    return (
        <Modal open={modalActive} title={modalTitle} onCancel={closeModal} width='65%' footer={[]}>
            {contextHolder}
            {
                formActive
                    ? <PaintSheenForm api={api} />
                    : <div>
                        <Row justify='end'>
                            <Space>
                                <Button icon={<RetweetOutlined />} />
                                <Button type='primary' style={button} onClick={openForm}>
                                    Agregar acabado
                                </Button>
                            </Space>
                        </Row>
                        <Row>
                            <TablePaintSheens />
                        </Row>
                    </div>
            }
        </Modal>
    );
}

export default PaintSheensModal;