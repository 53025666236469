import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { mainApi } from "../../api";
import { ExcelStoreRow, GetStores, Store, StoreFormData } from "../../interfaces";
import { FeedbackFunction } from '../../types';
import { AppThunk } from "../store";
import { setLoading } from "./ui";

interface StoresState {
    stores: Store[];
    modalActive: boolean;
    selectedStore: Store | null;
}

const initialState: StoresState = {
    stores: [],
    modalActive: false,
    selectedStore: null,
}

const storesSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {
        setStores(state, { payload }: PayloadAction<Store[]>) {
            state.stores = payload;
        },
        openStoreModal(state) {
            state.modalActive = true;
        },
        closeStoreModal(state) {
            state.modalActive = false;
        },
        setSelectedStore(state, { payload }: PayloadAction<Store | null>) {
            state.selectedStore = payload;
        },
    },
});

export const {
    setStores,
    openStoreModal,
    closeStoreModal,
    setSelectedStore,
} = storesSlice.actions;

export const getAllStores = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const { data } = await mainApi.get<GetStores>('/stores');

            dispatch(setStores(data.stores));
            dispatch(setLoading(false));
        } catch (error) {
            console.log('getAllStores', error);
            dispatch(setLoading(false));
        }
    }
}

export const createStore = (formData: StoreFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const { name, description, backgroundColor, color, image } = formData;
            const storeCreationData = new FormData();
            storeCreationData.append('name', name);
            storeCreationData.append('description', description);
            storeCreationData.append('backgroundColor', backgroundColor.hex);
            storeCreationData.append('color', color.hex);
            if (image) {
                storeCreationData.append('file', image)
            }

            await mainApi.post('/stores', storeCreationData);

            dispatch(getAllStores());
            dispatch(setLoading(false));
            onSuccess('La tienda ha sido agregada con éxito.');
        } catch (error) {
            console.log('createStore', error);
            dispatch(setLoading(false));
            onError('Ha ocurrio un error al intentar agregar la tienda.');
        }
    }
}

export const updateStore = (id: number, formData: StoreFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const { name, description, backgroundColor, color, image } = formData;
            const storeUpdateData = new FormData();
            storeUpdateData.append('name', name);
            storeUpdateData.append('description', description);
            storeUpdateData.append('backgroundColor', backgroundColor.hex);
            storeUpdateData.append('color', color.hex);
            if (image) {
                storeUpdateData.append('file', image)
            }

            await mainApi.put(`/stores/${id}`, storeUpdateData);

            dispatch(getAllStores());
            dispatch(setLoading(false));
            onSuccess('La tienda ha sido actualizada con éxito.');
        } catch (error) {
            console.log('updateStore', error);
            dispatch(setLoading(false));
            onError('Ha ocurrio un error al intentar actualizar la tienda.');
        }
    }
}

export const deleteStore = (id: number, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            await mainApi.delete(`/stores/${id}`);

            dispatch(getAllStores());
            dispatch(setLoading(false));
            onSuccess('La tienda ha sido eliminada con éxito.');
        } catch (error) {
            console.log('deleteStore', error);
            dispatch(setLoading(false));
            onError('Ha ocurrido un error al intentar eliminar la tienda.');
        }
    }
}

export const uploadStoresBatch = (data: ExcelStoreRow[], onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            await Promise.all(
                Array.from(Array(Math.ceil(data.length / 500)).keys()).map((i) =>
                    mainApi.post('/stores/massiveUpload',
                        {
                            data: data.slice(0 + (i * 500), 500 + (i * 500)),
                        }
                    )
                )
            );

            dispatch(getAllStores());
            dispatch(setLoading(false));
            onSuccess('La carga masiva de tiendas ha sido completada con éxito.');
        } catch (error) {
            console.log('uploadStoresBatch', error);
            dispatch(setLoading(false));
            onError('Ha ocurrido un error al intentar realizar la carga masiva de tiendas.');
        }
    }
}

export default storesSlice.reducer;