import { FC, useEffect, useMemo, useState } from 'react';
import { Modal, notification } from 'antd';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeCategoriesModal, getAllCategories, setSelectedCategory } from '../../redux/slices/categories';
import CategoriesTable from './CategoriesTable';
import ButtonsRow from '../ui/ButtonsRow';
import CategoryForm from './CategoryForm';
import { Columns, exportCSV } from '../../utils/excel';
import { Category } from '../../interfaces';

interface Props {
}

const CategoriesModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useAppDispatch();
    const { modalActive, categories, selectedCategory } = useAppSelector(state => state.categories);

    const [formActive, setFormActive] = useState(false);

    const title = useMemo(() => {
        if (formActive) {
            if (selectedCategory) return 'ACTUALIZAR CATEGORÍA';
            return 'AGREGAR NUEVA CATEGORÍA';
        }
        return 'GESTIÓN DE LAS CATEGORÍAS';
    }, []);

    const closeModal = () => {
        dispatch(setSelectedCategory(null));
        dispatch(closeCategoriesModal());
    }

    const downloadExcel = async () => {
        const columns: Columns<Category> = [
            {
                key: 'idCategory',
                name: 'ID',
            },
            {
                key: 'name',
                name: 'Nombre',
            },
            {
                key: 'description',
                name: 'Descripción',
            },
        ]

        await exportCSV<Category>(columns, categories, {
            fileName: `Listado_Categorías_${moment().format('DD-MM-YYYY')}.xlsx`,
            sheet: 'Categorías'
        });
    }

    return (
        <Modal open={modalActive} title={title} width='70%' footer={[]} onCancel={closeModal}>
            {contextHolder}
            {
                formActive
                    ? <CategoryForm 
                        api={api}
                        onClose={() => {
                            dispatch(setSelectedCategory(null));
                            setFormActive(false);
                        }}
                    />
                    : <>
                        <ButtonsRow
                            text='agregar categoría'
                            onRefreshClick={() => dispatch(getAllCategories())}
                            onAddClick={() => setFormActive(true)}
                            onExportClick={downloadExcel}
                        />
                        <CategoriesTable
                            onEdit={(record) => {
                                dispatch(setSelectedCategory(record));
                                setFormActive(true);
                            }}
                        />
                    </>
            }
        </Modal>
    );
}

export default CategoriesModal;