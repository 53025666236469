import { useState } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

import { Color, ColorForm } from '../interfaces';
import useAppDispatch from './useAppDispatch';
import { createColor, deleteColor, updateColor } from '../redux/slices/colors';

const useColorsForm = (api: NotificationInstance) => {
    const dispatch = useAppDispatch();
    const [formActive, setFormActive] = useState(false);
    const [selectedColor, setSelectedColor] = useState<Color | null>(null);
    const [deletePromptActive, setDeletePromptActive] = useState(false);

    const openForm = (color: Color | null = null) => {
        setSelectedColor(color);
        setFormActive(true);
    }

    const closeForm = () => {
        setSelectedColor(null);
        setFormActive(false);
    }

    const onFormSubmit = (data: ColorForm) => {
        if (!data.color) {
            return api.warning({
                description: 'Verifique que la selección de color sea correcta.',
                message: 'Color inválido',
            });
        };
        if (selectedColor) {
            dispatch(updateColor(selectedColor.idColor, data,
                () => {
                    api.success({
                        message: 'El color ha sido actualizado con éxito.',
                    });
                    closeForm();
                },
                () => {
                    api.error({
                        message: 'Ha ocurrido un error al intentar actualizar el color.',
                    });
                }
            ));
        } else {
            dispatch(createColor(data,
                () => {
                    api.success({
                        message: 'El color ha sido agregado con éxito.',
                    });
                    closeForm();
                },
                () => {
                    api.error({
                        message: 'Ha ocurrido un error al intentar agregar el color.',
                    });
                }
            ));
        }
    }

    const onDelete = (color: Color) => {
        setSelectedColor(color);
        setDeletePromptActive(true);
    }

    const onDeleteConfirm = () => {
        setDeletePromptActive(false);
        if (selectedColor) {
            dispatch(deleteColor(selectedColor.idColor,
                () => {
                    api.success({
                        message: 'El color ha sido eliminado con éxito.',
                    });
                    setSelectedColor(null);
                },
                () => {
                    api.error({
                        message: 'Ha ocurrido un error al intentar eliminar el color.',
                    });
                    setSelectedColor(null)
                }
            ));
        }
    }

    const onDeleteCancel = () => {
        setDeletePromptActive(false);
    }

    return {
        formActive,
        closeForm,
        openForm,
        onFormSubmit,
        selectedColor,
        onDelete,
        deletePromptActive,
        onDeleteConfirm,
        onDeleteCancel,
    }
}

export default useColorsForm;