import { FC } from 'react';
import { Modal, Typography } from 'antd';
import { button } from '../../styles';

interface Props {
    open: boolean;
    onCancel: () => void;
    onOk: () => void;
    title: string;
}

const ModalDeleteConfirmation: FC<Props> = ({ open, onCancel, onOk, title }) => {
    return (
        <Modal
            open={open}
            title={title}
            okText='Eliminar'
            cancelText='Cancelar'
            onCancel={onCancel}
            onOk={onOk}
            okButtonProps={{ style: button }}
            cancelButtonProps={{ style: button }}
        >
            <Typography.Text>
                Esta acción no es revertible.
            </Typography.Text>
        </Modal>
    );
}

export default ModalDeleteConfirmation;