import { FC, useEffect } from 'react';
import { Button, Row, Space, Typography } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import { useAppDispatch } from '../hooks';
import { TableCancellations } from '../components/cancellations';
import { getAllCancellations } from '../redux/slices/cancellations';

interface Props {
}

const Cancellations: FC<Props> = () => {
    const dispatch = useAppDispatch()

    const fetchData = () => {
        dispatch(getAllCancellations());
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <Typography.Title>Cancelaciones</Typography.Title>
            <Row justify='end'>
                <Space>
                    <Button icon={<RetweetOutlined />} onClick={fetchData} />
                </Space>
            </Row>
            <Row>
                <TableCancellations />
            </Row>
        </>
    );
}

export default Cancellations;