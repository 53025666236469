import { useEffect, useMemo } from 'react';
import { Form, notification } from 'antd';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import { closeFormModal, createShippingCost, setSelectedShippingCost, updateShippingCost } from '../redux/slices/shippingCosts';
import { ShippingCostFormData } from '../interfaces';

const useShippingCostForm = () => {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    
    const dispatch = useAppDispatch();
    const { formModalActive, selectedShippingCost } = useAppSelector(state => state.shippingCosts);

    const title = useMemo(() => {
        if (selectedShippingCost)
            return 'ACTUALIZAR TARIFA';
        return 'AGREGAR NUEVA TARIFA';
    }, [selectedShippingCost]);

    const buttonText = useMemo(() => {
        if (selectedShippingCost)
            return 'ACTUALIZAR';
        return 'AGREGAR';
    }, [selectedShippingCost]);

    useEffect(() => {
        if (selectedShippingCost) {
            const { destination, municipality, costTier1, costTier2, costTier3 } = selectedShippingCost;
            form.setFieldsValue({
                destination, municipality, costTier1, costTier2, costTier3,
            });
        } else {
            form.resetFields();
        }
    }, [selectedShippingCost]);

    const closeModal = () => {
        form.resetFields();
        dispatch(setSelectedShippingCost(null));
        dispatch(closeFormModal());
    }

    const onFinish = (data: ShippingCostFormData) => {
        if (selectedShippingCost) 
            dispatch(updateShippingCost(selectedShippingCost.idShippingCost, { ...data, destination: data.destination.trim() }, api, closeModal));
        else 
            dispatch(createShippingCost({ ...data, destination: data.destination.trim() }, api, closeModal));
    }

    return {
        form,
        title,
        buttonText,
        contextHolder,
        formModalActive,
        closeModal,
        onFinish,
    };
}

export default useShippingCostForm;