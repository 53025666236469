import { FC } from 'react';
import { Button, Space, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

interface Props {
    label: string;
    showDetails?: boolean;
    showEdit?: boolean;
    showDelete?: boolean;
    detailsDisabled?: boolean;
    editDisabled?: boolean;
    deleteDisabled?: boolean;
    onDetailsClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    ExtraActions?: JSX.Element | JSX.Element[];
}

const ActionsColumn: FC<Props> = ({ label, showDetails = true, showDelete = true, showEdit = true, editDisabled, deleteDisabled, detailsDisabled, onDetailsClick, onEditClick, onDeleteClick, ExtraActions = null }) => {
    return (
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
            {ExtraActions}
            {
                showDetails
                    ? <Tooltip title='Ver Detalles'>
                        <Button
                            icon={<EyeOutlined />}
                            onClick={() => {
                                if (onDetailsClick) onDetailsClick();
                            }}
                            disabled={detailsDisabled}
                        />
                    </Tooltip>
                    : null
            }
            {
                showEdit
                    ? <Tooltip title={`Editar ${label}`}>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => {
                                if (onEditClick) onEditClick();
                            }}
                            disabled={editDisabled}
                        />
                    </Tooltip>
                    : null
            }
            {
                showDelete
                    ? <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            if (onDeleteClick) onDeleteClick();
                        }}
                        disabled={deleteDisabled}
                    />
                    : null
            }
        </Space>
    );
}

export default ActionsColumn;