import { useMemo } from 'react';

import useAppSelector from './useAppSelector';
import useModuleStructure, { AppModule } from './useModuleStructure';

const useAccess = () => {
    const { userData } = useAppSelector((state) => state.auth);
    const { appModules } = useModuleStructure();

    const isAuthenticated = useMemo(() => {
        return Boolean(userData);
    }, [userData]);

    const allowedLocations = useMemo(() => {
        if (!userData) return [];
        const userType = userData.type.enum;
        const allModules: AppModule[] = [];
        appModules.forEach((module) => {
            allModules.push(module);
            if (module.children) {
                module.children.forEach((childrenModule) => allModules.push({ ...childrenModule, allowedTypes: module.allowedTypes }));
            } 
        });
        return allModules.filter(({ allowedTypes }) => allowedTypes.includes(userType)).map(module => module.key);
    }, [userData]);

    return {
        isAuthenticated,
        allowedLocations,
    };
}

export default useAccess;