import { FC, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';

import { ProductFeatureFormData } from '../../interfaces';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteFeature } from '../../redux/slices/productsForm';
import { deleteFeatureInDatabase } from '../../redux/slices/products';
import { notification } from 'antd';

interface Props {

}

const FeaturesTable: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { features } = useAppSelector(state => state.productsForm);

    const [modalDeleteActive, setModalDeleteActive] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState<ProductFeatureFormData | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const clearModalDelete = () => {
        setSelectedFeature(null);
        setSelectedIndex(null);
        setModalDeleteActive(false);
    }

    const columns: ColumnsType<ProductFeatureFormData> = [
        {
            title: 'Característica',
            dataIndex: 'description',
        },
        {
            title: 'Acciones',
            render: (_, record, index) => <ActionsColumn
                label='Característica'
                showDetails={false}
                showEdit={false}
                onDeleteClick={() => {
                    if (record.idFeature) {
                        setSelectedFeature(record);
                        setSelectedIndex(index);
                        setModalDeleteActive(true);
                    } else {
                        dispatch(deleteFeature(index));
                    }
                }}
            />
        }
    ];

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={features} rowKey='description' showSearchBar={false} />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar la característica seleccionada?'
                open={modalDeleteActive}
                onCancel={clearModalDelete}
                onOk={() => {
                    if (selectedFeature === null || selectedIndex === null) return;
                    dispatch(
                        deleteFeatureInDatabase(selectedFeature.idFeature!,
                            (message) => {
                                dispatch(deleteFeature(selectedIndex));
                                api.success({ message });
                                clearModalDelete();
                            },
                            (message) => api.error({ message }),
                        )
                    )
                }}
            />
        </>
    );
}

export default FeaturesTable;