import { ReactNode } from 'react';
import { MenuProps } from 'antd';
import { AppstoreAddOutlined, BulbOutlined, DashboardOutlined, FormatPainterOutlined, LinkOutlined, RetweetOutlined, TagsOutlined } from '@ant-design/icons';

import useAppDispatch from './useAppDispatch';
import { openStoreModal } from '../redux/slices/stores';
import { openCategoriesModal } from '../redux/slices/categories';
import { openLinesModal } from '../redux/slices/lines';
import { openMeasurementsModal } from '../redux/slices/measurements';
import { Columns, exportCSV } from '../utils/excel';
import { Price, Product, Variant } from '../interfaces';
import useAppSelector from './useAppSelector';
import moment from 'moment';
import { openPricesImportModal, openProductsImportModal, openVariantsImportModal } from '../redux/slices/productsForm';
import { openSpecialColorsModal } from '../redux/slices/colors';
import { setPaintSheensModalActiveAs } from '../redux/slices/paintSheens';

interface UIButton {
    name: string;
    icon?: ReactNode;
    variant?: string;
    onClick?: () => void;
}

const useProductsLogic = () => {
    const dispatch = useAppDispatch();
    const { products, allVariants } = useAppSelector(state => state.products);

    const downloadProductsExcel = async () => {
        const columns: Columns<Product> = [
            {
                key: 'idProduct',
                name: 'ID',
            },
            {
                key: 'name',
                name: 'Nombre',
            },
            {
                key: 'slug',
                name: 'Slug',
            },
            {
                key: 'description',
                name: 'Descripción',
            },
            {
                key: 'idStore',
                name: 'ID Tienda',
            },
            {
                key: 'idCategory',
                name: 'ID Categoría',
            },
            {
                key: 'idLine',
                name: 'ID Línea'
            }
        ];

        await exportCSV<Product>(columns, products, {
            fileName: `Listado_Productos_${moment().format('DD-MM-YYYY')}.xlsx`,
            sheet: 'Productos'
        });
    }

    const downloadVariantsExcel = async () => {
        const columns: Columns<Variant> = [
            { key: 'idVariant', name: 'ID' },
            { key: 'productKey', name: 'Clave' },
            { key: 'barcode', name: 'Código de Barras' },
            { key: 'weight', name: 'Peso (Kg)' },
            { key: 'usesColorGallery', name: 'Usa Galería de Color (1 | 0)' },
            { key: 'allowedColorGroups', name: 'Bases de Color' },
            { key: 'localPrice', name: 'Precio Local' },
            { key: 'foreignPrice', name: 'Precio Foráneo' },
            { key: 'generalPrice', name: 'Precio General' },
            { key: 'usesSpecialPrices', name: 'Usa Precio Especial (1 | 0)' },
            { key: 'specialLocalPrice', name: 'Precio Especial Local' },
            { key: 'specialForeignPrice', name: 'Precio Especial Foráneo' },
            { key: 'specialGeneralPrice', name: 'Precio Especial General' },
            { key: 'paintSheens', name: 'Acabados' },
            { key: 'idProduct', name: 'ID Producto' },
            { key: 'idMeasurement', name: 'ID Medida' }
        ];

        await exportCSV<any>(columns, allVariants.map(v => ({ ...v, usesColorGallery: v.usesColorGallery ? 1 : 0, usesSpecialPrices: v.usesSpecialPrices ? 1 : 0 })), {
            fileName: `Listado_Presentaciones_${moment().format('DD-MM-YYYY')}.xlsx`,
            sheet: 'Presentaciones'
        });
    }

    const productSubmodules: UIButton[] = [
        {
            name: 'Tiendas',
            icon: <LinkOutlined />,
            onClick: () => dispatch(openStoreModal()),
        },
        {
            name: 'Categorías',
            icon: <TagsOutlined />,
            onClick: () => dispatch(openCategoriesModal()),
        },
        {
            name: 'Líneas',
            icon: <AppstoreAddOutlined />,
            onClick: () => dispatch(openLinesModal()),
        }
    ];

    const variantSubmodules: UIButton[] = [
        {
            name: 'Medidas',
            icon: <DashboardOutlined />,
            onClick: () => dispatch(openMeasurementsModal()),
        },
        {
            name: 'Acabados',
            icon: <BulbOutlined />,
            onClick: () => dispatch(setPaintSheensModalActiveAs(true)),
        },
        {
            name: 'Colores Especiales',
            icon: <FormatPainterOutlined />,
            onClick: () => dispatch(openSpecialColorsModal()),
        },
    ];

    const exportItems: MenuProps['items'] = [
        {
            label: <span>PRODUCTOS</span>,
            key: '0',
            onClick: downloadProductsExcel,
        },
        {
            type: 'divider',
        },
        {
            label: <span>PRESENTACIONES</span>,
            key: '1',
            onClick: downloadVariantsExcel,
        },
        {
            type: 'divider',
        },
    ];

    const importItems: MenuProps['items'] = [
        {
            label: <span>PRODUCTOS</span>,
            key: '0',
            onClick: () => dispatch(openProductsImportModal()),
        },
        {
            type: 'divider',
        },
        {
            label: <span>PRESENTACIONES</span>,
            key: '1',
            onClick: () => dispatch(openVariantsImportModal()),
        },
        {
            type: 'divider',
        },
    ]

    return {
        productSubmodules, variantSubmodules,
        exportItems, importItems,
    };
}

export default useProductsLogic;