import { FC, useMemo, useState } from 'react';
import { Modal, notification } from 'antd';
import moment from 'moment';

import { Store } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeStoreModal, getAllStores, setSelectedStore, uploadStoresBatch } from '../../redux/slices/stores';
import { Columns, exportCSV } from '../../utils/excel';
import StoreForm from './StoreForm';
import StoresTable from './StoresTable';
import ButtonsRow from '../ui/ButtonsRow';

interface Props {
}

const StoresModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useAppDispatch();
    const { modalActive, selectedStore, stores } = useAppSelector((state) => state.stores);

    const [formActive, setFormActive] = useState(false);

    const title = useMemo(() => {
        if (formActive) {
            if (selectedStore) return 'ACTUALIZAR TIENDA'
            return 'AGREGAR NUEVA TIENDA'
        }
        return 'GESTIÓN DE LAS TIENDAS';
    }, [selectedStore, formActive]);

    const closeModal = () => {
        setFormActive(false);
        dispatch(setSelectedStore(null));
        dispatch(closeStoreModal());
    }

    const downloadExcel = async () => {
        const columns: Columns<Store> = [
            {
                key: 'idStore',
                name: 'ID',
            },
            {
                key: 'name',
                name: 'Nombre',
            },
            {
                key: 'description',
                name: 'Descripción',
            },
            {
                key: 'backgroundColor',
                name: 'Color de Fondo',
            },
            {
                key: 'color',
                name: 'Color',
            }
        ]

        await exportCSV<Store>(columns, stores, {
            fileName: `Listado_Tiendas_${moment().format('DD-MM-YYYY')}.xlsx`,
            columns: [{}, {}, { width: 75 }, { width: 15 }, {}],
            sheet: 'Tiendas'
        });
    }

    return (
        <Modal open={modalActive} title={title} onCancel={closeModal} width='70%' footer={[]}>
            {contextHolder}
            {
                formActive ?
                    <StoreForm
                        api={api}
                        onClose={() => {
                            dispatch(setSelectedStore(null));
                            setFormActive(false);
                        }}
                    />
                    :
                    <>
                        <ButtonsRow
                            text='agregar tienda'
                            onRefreshClick={() => dispatch(getAllStores())}
                            onAddClick={() => setFormActive(true)}
                            onExportClick={downloadExcel}
                        />
                        <StoresTable
                            onEdit={(record) => {
                                dispatch(setSelectedStore(record));
                                setFormActive(true);
                            }}
                        />
                    </>
            }
        </Modal>
    );
}

export default StoresModal;