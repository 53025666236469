import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mainApi } from '../../api';

import { GetColorCodes, PostProduct, PostVariant, ProductFeatureFormData, ProductFormData, ProductVariantFormData, PutVariant } from '../../interfaces';
import { FeedbackFunction } from '../../types';
import { AppThunk } from '../store';
import { getAllProducts } from './products';
import { finishLoading, startLoading } from './ui';

interface ProductsFormState {
    generalData: ProductFormData | null;
    features: ProductFeatureFormData[];
    variants: ProductVariantFormData[];
    featuresFormActive: boolean;
    variantsFormActive: boolean;
    pricesFormActive: boolean;
    colorCodes: string[];
    selectedVariant: ProductVariantFormData | null;
    files: File[];
    productsImportModalActive: boolean;
    variantsImportModalActive: boolean;
    pricesImportModalActive: boolean;
}

const initialState: ProductsFormState = {
    generalData: null,
    features: [],
    variants: [],
    featuresFormActive: false,
    variantsFormActive: false,
    pricesFormActive: false,
    colorCodes: [],
    selectedVariant: null,
    files: [],
    productsImportModalActive: false,
    variantsImportModalActive: false,
    pricesImportModalActive: false,
};

const productsFormSlice = createSlice({
    name: 'ProductsForm',
    initialState,
    reducers: {
        setGeneralData(state, { payload }: PayloadAction<ProductFormData | null>) {
            state.generalData = payload;
        },
        setFeatures(state, { payload }: PayloadAction<ProductFeatureFormData[]>) {
            state.features = payload;
        },
        setVariants(state, { payload }: PayloadAction<ProductVariantFormData[]>) {
            state.variants = payload;
        },
        openFeaturesForm(state) {
            state.featuresFormActive = true;
        },
        closeFeaturesForm(state) {
            state.featuresFormActive = false;
        },
        openVariantsForm(state) {
            state.variantsFormActive = true;
        },
        closeVariantsForm(state) {
            state.variantsFormActive = false;
        },
        openPricesForm(state) {
            state.pricesFormActive = true;
        },
        closePricesForm(state) {
            state.pricesFormActive = false;
        },
        setColorCodes(state, { payload }: PayloadAction<string[]>) {
            state.colorCodes = payload;
        },
        setSelectedVariant(state, { payload }: PayloadAction<ProductVariantFormData | null>) {
            state.selectedVariant = payload;
        },
        setFiles(state, { payload }: PayloadAction<File[]>) {
            state.files = payload;
        },
        openProductsImportModal(state) {
            state.productsImportModalActive = true;
        },
        closeProductsImportModal(state) {
            state.productsImportModalActive = false;
        },
        openVariantsImportModal(state) {
            state.variantsImportModalActive = true;
        },
        closeVariantsImportModal(state) {
            state.variantsImportModalActive = false;
        },
        openPricesImportModal(state) {
            state.pricesImportModalActive = true;
        },
        closePricesImportModal(state) {
            state.pricesImportModalActive = false;
        }
    },
});

export const {
    setGeneralData,
    setFeatures,
    setVariants,
    openFeaturesForm,
    closeFeaturesForm,
    openVariantsForm,
    closeVariantsForm,
    openPricesForm,
    closePricesForm,
    setColorCodes,
    setSelectedVariant,
    setFiles,
    openProductsImportModal,
    closeProductsImportModal,
    openVariantsImportModal,
    closeVariantsImportModal,
    openPricesImportModal,
    closePricesImportModal,
} = productsFormSlice.actions;

export const addFeature = (data: ProductFeatureFormData): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { features } = getState().productsForm;

            dispatch(setFeatures([...features, data]))
        } catch (error) {
            console.log('addFeature', error);
        }
    }
}

export const deleteFeature = (index: number): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { features } = getState().productsForm;

            dispatch(setFeatures(features.filter((_, featureIndex) => featureIndex !== index)));
        } catch (error) {
            console.log('deleteFeature', error);
        }
    }
}

export const getAllColorCodes = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetColorCodes>('/utils/colorCodes');

            dispatch(setColorCodes(data.results));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllColorCodes', error);
            dispatch(finishLoading());
        }
    }
}

export const addVariant = (data: ProductVariantFormData): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { variants } = getState().productsForm;

            const newVariant: ProductVariantFormData = {
                ...data,
            }

            if (newVariant.usesColorGallery === 0) {
                newVariant.usesSpecialPrices = 0;
            }

            dispatch(setVariants([...variants, newVariant]));
        } catch (error) {
            console.log('addVariant', error);
        }
    }
}

export const updateVariant = (index: number, data: ProductVariantFormData): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { variants } = getState().productsForm;

            const updatedVariant: ProductVariantFormData = {
                ...data,
            }

            if (updatedVariant.usesColorGallery === 0) {
                updatedVariant.usesSpecialPrices = 0;
            }

            dispatch(
                setVariants(
                    variants.map((variant, variantIndex) =>
                        variantIndex === index
                            ? { ...variant, ...updatedVariant }
                            : variant
                    )
                )
            );
        } catch (error) {
            console.log('updateVariant', error);
        }
    }
}

export const deleteVariant = (index: number): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { variants } = getState().productsForm;

            dispatch(setVariants(variants.filter((_, variantIndex) => variantIndex !== index)));
        } catch (error) {
            console.log('deleteVariant', error);
        }
    }
}

export const createProduct = (data: ProductFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());

            const { features, variants } = getState().productsForm;

            const { name, slug, description, idStore, idCategory, idLine, files } = data;

            const formData = new FormData();
            formData.append('name', name);
            formData.append('slug', slug);
            formData.append('description', description);
            formData.append('file', files[0]);

            if (idStore !== 0) formData.append('idStore', idStore.toString());
            if (idCategory !== 0) formData.append('idCategory', idCategory.toString());
            if (idLine !== 0) formData.append('idLine', idLine.toString());

            const { data: { product: { idProduct } } } = await mainApi.post<PostProduct>('/products', formData);

            if (features.length > 0) {
                const featuresData = features.map(({ description }) => ({
                    idFeature: 'N/A',
                    description, idProduct,
                }));

                await mainApi.post('/features/massiveUpload', { data: featuresData });
            }

            if (variants.length > 0) {
                await Promise.all(
                    variants.map((variant) => {
                        return new Promise<void>(async (resolve, reject) => {
                            try {
                                const variantData: any = {
                                    ...variant,
                                    usesSpecialPrices: variant.usesColorGallery === 0 ? 0 : variant.usesSpecialPrices,
                                    paintSheens: variant.paintSheens || [],
                                };

                                if (variant.usesColorGallery === 1)
                                    variantData.allowedColorGroups = variant.allowedColorGroups!.join(';');

                                const { data: { variant: { idVariant } } } = await mainApi.post<PostVariant>('/variants', {
                                    ...variantData, idProduct,
                                });

                                await Promise.all(
                                    variant.files.map((file) => {
                                        const formData = new FormData();
                                        formData.append('file', file);
                                        formData.append('idVariant', idVariant.toString());

                                        return mainApi.post('/images', formData);
                                    })
                                );
                                resolve();
                            } catch (error) {
                                reject(error);
                            }
                        });
                    })
                );
            }

            dispatch(finishLoading());
            dispatch(getAllProducts());
            onSuccess('El producto ha sido agregado con éxito.');
        } catch (error) {
            console.log('createProduct', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar agregar el producto.');
        }
    }
}

export const updateProduct = (id: number, data: ProductFormData, onSuccess: FeedbackFunction, onError: FeedbackFunction): AppThunk => {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());

            const { features, variants } = getState().productsForm;

            const { name, slug, description, idStore, idCategory, idLine, files } = data;

            const formData = new FormData();
            formData.append('name', name);
            formData.append('slug', slug);
            formData.append('description', description);

            if (idStore !== 0) formData.append('idStore', idStore.toString());
            if (idCategory !== 0) formData.append('idCategory', idCategory.toString());
            if (idLine !== 0) formData.append('idLine', idLine.toString());
            if (files[0]) formData.append('file', files[0]);

            await mainApi.put(`/products/${id}`, formData);

            if (features.length > 0) {
                const featuresData = features.filter(f => !f.idFeature).map(({ description }) => ({
                    idFeature: 'N/A',
                    description,
                    idProduct: id,
                }));

                if (featuresData.length > 0)
                    await mainApi.post('/features/massiveUpload', { data: featuresData });
            }

            if (variants.length > 0) {
                await Promise.all(
                    variants.map((variant) => {
                        return new Promise<void>(async (resolve, reject) => {
                            try {
                                const variantData: any = {
                                    ...variant,
                                    usesSpecialPrices: variant.usesColorGallery === 0 ? 0 : variant.usesSpecialPrices,
                                    paintSheens: variant.paintSheens || [],
                                };

                                if (variant.usesColorGallery === 1)
                                    variantData.allowedColorGroups = variant.allowedColorGroups!.join(';');

                                const { data: { variant: { idVariant } } } = variant.idVariant
                                    ? await mainApi.put<PutVariant>(`/variants/${variant.idVariant}`, {
                                        ...variantData, idProduct: id,
                                    })
                                    : await mainApi.post<PostVariant>('/variants', {
                                        ...variantData, idProduct: id,
                                    });

                                await Promise.all(
                                    variant.files.map((file) => {
                                        const formData = new FormData();
                                        formData.append('file', file);
                                        formData.append('idVariant', idVariant.toString());

                                        return mainApi.post('/images', formData);
                                    })
                                );

                                resolve();
                            } catch (error) {
                                reject(error);
                            }
                        });
                    })
                );
            }

            dispatch(finishLoading());
            dispatch(getAllProducts());
            onSuccess('El producto ha sido actualizado con éxito.');
        } catch (error) {
            console.log('updateProduct', error);
            dispatch(finishLoading());
            onError('Ha ocurrido un error al intentar actualizar el producto.');
        }
    }
}

export default productsFormSlice.reducer;