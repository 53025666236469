import { useEffect, useMemo, useState } from 'react';
import { io } from 'socket.io-client';

const useSocket = (path: string) => {
    const socket = useMemo(() => {
        return io(path, {
            transports: ['websocket'],
        });
    }, [path]);

    const [online, setOnline] = useState(false);

    useEffect(() => {
        setOnline(socket.connected);
    }, [socket]);

    useEffect(() => {
        socket.on('connect', () => {
            setOnline(true);
        });
    }, [socket]);

    useEffect(() => {
        socket.on('disconnect', () => {
            setOnline(false);
        });
    }, [socket]);

    return {
        socket, online,
    };
}

export default useSocket;