import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Collapse, CollapseProps, Modal, notification, Row, Space } from 'antd';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeProductsFormModal, setSelectedProduct } from '../../redux/slices/products';
import GeneralDataForm from './GeneralDataForm';
import useProductsForms from '../../hooks/useProductsForms';
import { button } from '../../styles';
import FeaturesForm from './FeaturesForm';
import VariantsForm from './VariantsForm';
import { closeFeaturesForm, closePricesForm, closeVariantsForm, createProduct, getAllColorCodes, setFeatures, setVariants, updateProduct } from '../../redux/slices/productsForm';
import { ProductFormData } from '../../interfaces';

interface Props {
}

const ProductsFormModal: FC<Props> = () => {
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { formModalActive, selectedProduct } = useAppSelector(state => state.products);

    const [activeKey, setActiveKey] = useState<string | string[]>(['1']);

    const {
        productForm, variantForm
    } = useProductsForms();

    const title = useMemo(() => {
        if (selectedProduct) {
            return 'ACTUALIZAR PRODUCTO';
        }
        return 'AGREGAR NUEVO PRODUCTO';
    }, []);

    useEffect(() => {
        if (formModalActive === true) {
            dispatch(getAllColorCodes());
        }
    }, [formModalActive]);

    const closeModal = () => {
        setActiveKey(['1']);
        productForm.resetFields();
        dispatch(closeFeaturesForm());
        dispatch(setFeatures([]));
        dispatch(closeVariantsForm());
        dispatch(setVariants([]));
        dispatch(closePricesForm());
        dispatch(setSelectedProduct(null));
        dispatch(closeProductsFormModal());
    }

    const onSubmit = () => {
        productForm.submit();
    }

    const onFinish = (data: ProductFormData) => {
        if (selectedProduct) {
            dispatch(
                updateProduct(selectedProduct.idProduct, data,
                    (message) => {
                        api.success({ message });
                        closeModal();
                    },
                    (message) => api.error({ message }),
                )
            );
        } else {
            dispatch(
                createProduct(data,
                    (message) => {
                        api.success({ message });
                        closeModal();
                    },
                    (message) => api.error({ message }),
                )
            );
        }
    }

    const items: CollapseProps['items'] = [
        {
            key: '1', label: 'Datos Generales', children: <GeneralDataForm form={productForm} onFinish={onFinish} />
        },
        {
            key: '2', label: 'Características', children: <FeaturesForm />
        },
        {
            key: '3', label: 'Presentaciones', children: <VariantsForm variantForm={variantForm} />
        }
    ]

    return (
        <Modal open={formModalActive} title={title} width='70%' onCancel={closeModal} footer={[]}>
            {contextHolder}
            <Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)} items={items} />
            <Row justify='end' style={{ marginTop: '1rem' }}>
                <Space>
                    <Button style={button} onClick={closeModal}>
                        Cancelar
                    </Button>
                    <Button type='primary' style={button} onClick={onSubmit}>
                        {selectedProduct ? 'Actualizar' : 'Agregar'}
                    </Button>
                </Space>
            </Row>
        </Modal>
    );
}

export default ProductsFormModal;