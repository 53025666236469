import { FC } from 'react';
import { Col, DatePicker, Form, Input, InputNumber, Modal, Row, Transfer } from 'antd';
import { TransferLocale } from 'antd/es/transfer';
import dayjs from 'dayjs';

import { useAppSelector, useDiscountsForm, useFormConstants, useFormRules } from '../../hooks';

interface Props {
}

const locale: Partial<TransferLocale> = {
    itemUnit: 'Presentación',
    itemsUnit: 'Presentaciones',
    notFoundContent: 'No hay presentaciones disponibles.',
    selectAll: 'Seleccionar todo',
    selectCurrent: 'Seleccionar la página actual',
    selectInvert: 'Invertir la página actual',
    searchPlaceholder: 'Buscar por clave de presentación',
}

const DiscountFormModal: FC<Props> = () => {
    const { products, allVariants } = useAppSelector(state => state.products);
    const { formModalActive } = useAppSelector(state => state.discounts);
    const {
        closeModal,
        form,
        onFinish,
        targetKeys,
        selectedKeys,
        handleTransferChange,
        handleTransferSelectChange,
        contextHolder,
        ...props
    } = useDiscountsForm();

    const { gutter, xs } = useFormConstants();
    const { required, percentage } = useFormRules();

    return (
        <Modal open={formModalActive} width='70%' cancelText='CANCELAR' onOk={form.submit} onCancel={closeModal} {...props}>
            {contextHolder}
            <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off'>
                <Row gutter={gutter}>
                    <Col xs={xs}>
                        <Form.Item label='Nombre' name='name' rules={[required]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 6 }}>
                        <Form.Item label='Porcentaje de Descuento' name='discountPercentage' rules={[required, percentage]}>
                            <InputNumber
                                style={{ width: '100%' }}
                                addonAfter='%'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 6 }}>
                        <Form.Item label='Duración' name='range' rules={[required]}>
                            <DatePicker.RangePicker
                                disabledDate={current => dayjs().add(-1, 'days') >= current}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={gutter}>
                    <Col xs={{ span: 24 }}>
                        <Transfer
                            dataSource={allVariants.map(variant => ({ ...variant, key: variant.idVariant.toString() }))}
                            titles={['Todas los Presentaciones', 'Presentaciones en Descuento']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={handleTransferChange}
                            onSelectChange={handleTransferSelectChange}
                            listStyle={{ width: '50%' }}
                            style={{ marginTop: '1rem' }}
                            pagination
                            locale={locale}
                            render={
                                (item) =>
                                    `(${item.productKey}) - ${products.find(product => product.idProduct === item.idProduct)!.name}`
                            }
                            showSearch
                            filterOption={(search, option) => option.productKey.includes(search)}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default DiscountFormModal;