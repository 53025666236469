import { FC } from 'react';
import { Card, Col, Image, Row, Typography } from 'antd';

import { loginStyles } from '../styles';
import { LoginForm } from '../components';

interface Props {
}

const Login: FC<Props> = () => {
    return (
        <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
            <Col xs={{ span: 20 }} md={{ span: 14 }} lg={{ span: 10 }} xl={{ span: 8 }}>
                <Card bodyStyle={loginStyles.cardBodyStyle}>
                    <div style={loginStyles.imageContainer}>
                        <Image
                            width={150}
                            src='/famosas.png'
                            preview={false}
                        />
                    </div>
                    <Typography.Title className='text-center'>
                        Administración
                    </Typography.Title>
                    <LoginForm />
                    <Typography.Text className='text-center'>
                        Pinturas Famosas S. A. de C. V.
                    </Typography.Text>
                </Card>
            </Col>
        </Row>
    );
}

export default Login;