import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Image, Input, notification, Row, Space, Typography } from 'antd';
import { Colorpicker } from 'antd-colorpicker';
import { NotificationInstance } from 'antd/es/notification/interface';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { StoreFormData } from '../../interfaces';
import { createStore, updateStore } from '../../redux/slices/stores';
import { button } from '../../styles';
import { ImagesInput } from '../ui';

interface Props {
    onClose: () => void;
    api: NotificationInstance;
}

const StoreForm: FC<Props> = ({ onClose, api }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { selectedStore } = useAppSelector(state => state.stores);

    const [files, setFiles] = useState<File[]>([]);
    const [images, setImages] = useState<string[]>([]);

    useEffect(() => {
        if (selectedStore) {
            form.setFieldsValue({
                name: selectedStore.name,
                description: selectedStore.description,
                backgroundColor: { hex: selectedStore.backgroundColor },
                color: { hex: selectedStore.color },
            });
            if (selectedStore.image !== 'N/A') {
                setImages([selectedStore.image]);
            } else {
                setImages([]);
            }
        } else {
            form.resetFields();
            setImages([]);
        }
    }, [selectedStore, form]);

    const onFinish = (data: StoreFormData) => {
        const formData: StoreFormData = { ...data, image: files[0] ?? null };

        if (selectedStore) {
            if ((files.length + images.length) > 1) {
                return api.warning({ message: 'Las imágenes nuevas y actuales no deben exceder el límite.' });
            }

            dispatch(updateStore(selectedStore.idStore, formData,
                (message) => {
                    api.success({ message });
                    onClose();
                },
                (message) => api.error({ message }),
            ));
        } else {
            dispatch(createStore(formData,
                (message) => {
                    api.success({ message });
                    onClose();
                },
                (message) => api.error({ message })
            ));
        }
    }

    return (
        <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off'>
            <Row gutter={12}>
                <Col xs={{ span: 12 }}>
                    <Form.Item
                        label='Nombre:'
                        name='name'
                        rules={[
                            { required: true, message: 'Este campo es requerido.' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }}>
                    <Form.Item
                        label="Descripción:"
                        name="description"
                        rules={[
                            { required: true, message: 'Este campo es requerido.' },
                        ]}
                    >
                        <Input.TextArea rows={4} maxLength={500} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col xs={{ span: 6 }}>
                    <Form.Item
                        label="Color de Fondo:"
                        name="backgroundColor"
                        initialValue={{ hex: '#313c51' }}
                    >
                        <Colorpicker />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 6 }}>
                    <Form.Item
                        label="Color:"
                        name="color"
                        initialValue={{ hex: '#ffffff' }}
                    >
                        <Colorpicker />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }}>
                    <Form.Item label="Imágenes (1 máx.):" valuePropName="fileList">
                        <ImagesInput
                            limit={1}
                            files={files}
                            onFilesSubmit={(_files) => setFiles([...files, ..._files])}
                            onImageDelete={(index) => setFiles([...files.filter((_, _index) => _index !== index)])}
                        />
                    </Form.Item>
                    {
                        selectedStore
                            ? <div>
                                <Typography.Text>Imágenes actuales (Haz click sobre ellas para eliminarlas):</Typography.Text>
                                <div style={{ maxWidth: '80%', margin: '0 auto' }}>
                                    {
                                        images.length > 0
                                            ? <Space style={{ display: 'flex', justifyContent: 'center' }}>
                                                {
                                                    images.map((img, index) =>
                                                        <Image
                                                            key={img}
                                                            src={img}
                                                            style={{
                                                                width: '5rem',
                                                                height: '5rem',
                                                                objectFit: 'contain',
                                                                cursor: 'pointer',
                                                            }}
                                                            preview={false}
                                                            onClick={() => setImages([...images.filter((_, _index) => _index !== index)])}
                                                        />
                                                    )
                                                }
                                            </Space>
                                            : <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Typography.Text>
                                                    No hay imágenes actuales.
                                                </Typography.Text>
                                            </div>
                                    }
                                </div>
                            </div>
                            : null
                    }
                </Col>
            </Row>
            <Row justify='end'>
                <Space>
                    <Button style={button} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type='primary' style={button} htmlType='submit'>
                        {selectedStore ? 'actualizar' : 'agregar'}
                    </Button>
                </Space>
            </Row>
        </Form>
    );
}

export default StoreForm;