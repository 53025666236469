import dayjs from 'dayjs';

export const parseToDates = (range: [dayjs.Dayjs, dayjs.Dayjs]) => {
    const startDate = dayjs(range[0]).set('seconds', 0).set('minutes', 0).set('hours', 0).toDate();
    const endDate = dayjs(range[1]).set('seconds', 59).set('minutes', 59).set('hours', 23).toDate();
    return { startDate, endDate };
}

export const isInCurrentRange = (date: dayjs.Dayjs, range: dayjs.OpUnitType) => {
    const weekStart = dayjs().startOf(range);
    const weekEnd = dayjs().endOf(range);
    return date.unix() >= weekStart.unix() && date.unix() <= weekEnd.unix();
}