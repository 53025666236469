import { FC, useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { StopOutlined } from '@ant-design/icons';

import { DataTable } from '../ui';
import { useAppSelector } from '../../hooks';
import { OrderProduct } from '../../interfaces';
import { Parser } from '../../utils';

interface Props {
}

const TableOrderProducts: FC<Props> = () => {
    const { selectedOrder } = useAppSelector(state => state.orders);

    const columns: ColumnsType<OrderProduct> = [
        {
            title: 'Nombre',
            key: 'variantKey', dataIndex: 'variantKey',
            render: (value, record) => `${record.productName} (${value})`,
        },
        {
            title: 'Medida',
            key: 'measurement', dataIndex: 'measurement',
        },
        {
            title: 'Precio Unitario',
            key: 'unitPrice', dataIndex: 'unitPrice',
            render: (value) => Parser.parseToPrice(value),
        },
        {
            title: 'Cantidad',
            key: 'quantity', dataIndex: 'quantity',
        },
        {
            title: 'Precio Total',
            key: 'totalPrice', dataIndex: 'totalPrice',
            render: (value) => Parser.parseToPrice(value),
        },
        {
            title: 'Color',
            key: 'colorCode', dataIndex: 'colorCode',
            render: (_, record) => record.idColor === null ? <StopOutlined /> : `${record.colorName} (${record.colorCode})`,
        },
        {
            title: 'Acabado',
            key: 'paintSheenName', dataIndex: 'paintSheenName',
            render: (_, record) => record.paintSheenName === null ? <StopOutlined /> : record.paintSheenName,
        }, 
        {
            title: 'Descuento',
            key: 'discountName', dataIndex: 'discountName',
            render: (_, record) => record.idDiscount === null ? <StopOutlined /> : record.discountName,
        }, 
        {
            title: 'Imagen',
            key: 'productImage', dataIndex: 'productImage',
            render: (value) => <img src={value} width='65px' height='65px' style={{ objectFit: 'contain' }} />
        }
    ];

    useEffect(() => {
        console.log('test ->', selectedOrder?.products)
    }, [selectedOrder])

    return (
        <DataTable columns={columns} data={selectedOrder ? selectedOrder.products : []} rowKey='idOrder' showSearchBar={false} />
    );
}

export default TableOrderProducts;