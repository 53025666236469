import { useEffect, useMemo, useState } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import { saveSeasonalColors } from '../redux/slices/colors';

const useSeasonalColorsForm = (api: NotificationInstance) => {
    const dispatch = useAppDispatch();
    const { colors } = useAppSelector((state) => state.colors);

    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        setTargetKeys(colors.filter(c => c.isSeasonal === true).map(c => '' + c.idColor));
    }, [colors]);

    const hasChanges = useMemo(() => {
        const seasonalColors = colors.filter(c => c.isSeasonal === true).map(c => '' + c.idColor);
        if (seasonalColors.length !== targetKeys.length) return true;
        let hasChangesInSeasonalColors = false;
        targetKeys.forEach((key) => {
            if (!seasonalColors.includes(key)) hasChangesInSeasonalColors = true;
        });
        return hasChangesInSeasonalColors;
    }, [colors, targetKeys]);

    const onChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const saveChanges = () => {
        dispatch(saveSeasonalColors(
            colors.filter(c => targetKeys.includes(''+c.idColor)), 
            colors.filter(c => c.isSeasonal === true).filter(c => !targetKeys.includes(''+c.idColor)), 
            () => {
                api.success({
                    message: 'Los colores de temporada han sido actualizados con éxito.'
                });
            },
            () => {
                api.error({
                    message: 'Ha ocurrido un error al intentar actualizar los colores de temporada.',
                });
            },
        ));
    }

    const resetForm = () => {
        setTargetKeys(colors.filter(c => c.isSeasonal === true).map(c => '' + c.idColor));
        setSelectedKeys([]);
    }

    return {
        targetKeys,
        selectedKeys,
        onChange,
        onSelectChange,
        saveChanges,
        resetForm,
        hasChanges,
    }
}

export default useSeasonalColorsForm;