import { FC } from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';

import { useAppDispatch } from '../../hooks';
import { LoginFormData } from '../../interfaces';
import { logIn } from '../../redux/slices/auth';

interface Props {
}

const LoginForm: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const onFinish = (data: LoginFormData) => {
        dispatch(logIn(data, () => {
            api.error({
                message: '¡Error!',
                description: 'Ha ocurrido un error al momento de intentar ingresar, por favor verifique su conexión a internet y que los datos de sesión sean correctos.',
                duration: 5
            });
        }));
    }

    return (
        <>
            {contextHolder}
            <Form layout='vertical' form={form} autoComplete='off' onFinish={onFinish}>
                <Form.Item
                    label="Correo:"
                    name="email"
                    rules={[
                        { required: true, message: 'El correo es requerido.' },
                        { type: 'email', message: 'La dirección de correo no es válida.' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Contraseña:"
                    name="password"
                    rules={[{ required: true, message: 'La contraseña es requerida.' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked" initialValue={false}>
                    <Checkbox>Recuérdame</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                        INGRESAR
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default LoginForm;