import writeXlsxFile from 'write-excel-file';

type ObjectKeys<T> = keyof T;

interface XlsxFileConfigs {
    fileName: string;
    columns?: any[];
    sheet?: string;
}

interface Column<T> {
    key: ObjectKeys<T>,
    name: string;
}

export type Columns<T> = Column<T>[];

export const exportCSV = <T extends Object>(columns: Column<T>[], data: T[], configs: XlsxFileConfigs) => {
    const HEADER_ROW = columns.map((column) => ({
        value: column.name,
        fontWeight: 'bold',
    }));

    const DATA_ROWS = data.map((element) => {
        return columns.map((column) => element[column.key]).map((value) => ({
            value: value ?? 'N/A',
        }));
    })

    const excelData = [HEADER_ROW, ...DATA_ROWS];

    return writeXlsxFile(excelData as any, {
        ...configs,
    });
}