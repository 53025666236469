import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExcelShippingCostRow, GetShippingCosts, ShippingCost, ShippingCostFormData } from '../../interfaces';
import { AppThunk } from '../store';
import { closeExcelUploadModal, finishLoading, startLoading } from './ui';
import { mainApi } from '../../api';
import { NotificationInstance } from 'antd/es/notification/interface';

interface ShippingCostsState {
    shippingCosts: ShippingCost[];
    selectedShippingCost: ShippingCost | null;
    formModalActive: boolean;
}

const initialState: ShippingCostsState = {
    shippingCosts: [],
    selectedShippingCost: null,
    formModalActive: false,
};

const shippingCostsSlice = createSlice({
    name: 'ShippingCosts',
    initialState,
    reducers: {
        setShippingCosts(state, { payload }: PayloadAction<ShippingCost[]>) {
            state.shippingCosts = payload;
        },
        setSelectedShippingCost(state, { payload }: PayloadAction<ShippingCost | null>) {
            state.selectedShippingCost = payload;
        },
        openFormModal(state) {
            state.formModalActive = true;
        },
        closeFormModal(state) {
            state.formModalActive = false;
        },
    },
});

export const {
    setShippingCosts,
    setSelectedShippingCost,
    openFormModal,
    closeFormModal,
} = shippingCostsSlice.actions;

export const getAllShippingCosts = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetShippingCosts>('/shippingCosts');

            dispatch(setShippingCosts(data.shippingCosts));
            dispatch(finishLoading());
        } catch (error) {
            console.log('getAllShippingCosts', error);
            dispatch(finishLoading());
        }
    }
}

export const createShippingCost = (data: ShippingCostFormData, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/shippingCosts', data);

            dispatch(getAllShippingCosts());
            dispatch(finishLoading());
            api.success({ message: 'La tarifa de envío ha sido agregada con éxito.' });
            callback();
        } catch (error) {
            console.log('createShippingCost', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar agregar la tarifa de envío.' });
        }
    }
}

export const updateShippingCost = (idShippingCost: number, data: ShippingCostFormData, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put(`/shippingCosts/${idShippingCost}`, data);

            dispatch(getAllShippingCosts());
            dispatch(finishLoading());
            api.success({ message: 'La tarifa de envío ha sido actualizada con éxito.' });
            callback();
        } catch (error) {
            console.log('updateShippingCost', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar actualizar la tarifa de envío.' });
        }
    }
}

export const deleteShippingCost = (idShippingCost: number, api: NotificationInstance, callback: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/shippingCosts/${idShippingCost}`);

            dispatch(getAllShippingCosts());
            dispatch(finishLoading());
            api.success({ message: 'La tarifa de envío ha sido eliminada con éxito.' });
            callback();
        } catch (error) {
            console.log('deleteShippingCost', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar eliminar la tarifa de envío.' });
        }
    }
}

export const uploadShippingCostsBatch = (data: ExcelShippingCostRow[], api: NotificationInstance): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await Promise.all(
                Array.from(Array(Math.ceil(data.length / 500)).keys()).map((i) =>
                    mainApi.post('/shippingCosts/massiveUpload',
                        {
                            data: data.slice(0 + (i * 500), 500 + (i * 500)),
                        }
                    )
                )
            )

            dispatch(getAllShippingCosts());
            dispatch(finishLoading());
            api.success({ message: 'La carga masiva de tarifas de envío ha sido completada con éxito.' });
            dispatch(closeExcelUploadModal());
        } catch (error) {
            console.log('uploadShippingCostsBatch', error);
            dispatch(finishLoading());
            api.error({ message: 'Ha ocurrido un error al intentar completar la carga masiva de tarifas de envío.' });
        }
    }
}

export default shippingCostsSlice.reducer;