import { FC } from 'react';
import { Button, Modal, notification, Row, Space, Transfer } from 'antd';
import { TransferLocale } from 'antd/es/transfer';

import { useAppDispatch, useAppSelector, useSeasonalColorsForm } from '../../hooks';
import { closeSeasonalColorsModal } from '../../redux/slices/colors';

interface Props {
}

const locale: Partial<TransferLocale> = {
    itemUnit: 'Color',
    itemsUnit: 'Colores',
    notFoundContent: 'No hay colores disponibles.',
    selectAll: 'Seleccionar todo',
    selectCurrent: 'Seleccionar la página actual',
    selectInvert: 'Invertir la página actual'
}

const SeasonalColorsModal: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { colors, seasonalModalActive } = useAppSelector((state) => state.colors);

    const [api, contextHolder] = notification.useNotification();

    const { resetForm, targetKeys, selectedKeys, onChange, onSelectChange, hasChanges, saveChanges } = useSeasonalColorsForm(api);

    const closeModal = () => {
        dispatch(closeSeasonalColorsModal());
        resetForm();
    }

    return (
        <Modal open={seasonalModalActive} title='COLORES DE TEMPORADA' width='70%' footer={[]} onCancel={closeModal}>
            {contextHolder}
            <Transfer
                dataSource={colors.map(c => ({ ...c, key: '' + c.idColor }))}
                titles={['Todos los Colores', 'Colores de Temporada']}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                render={(item) =>
                    <div style={{ display: 'flex' }}>
                        <Space>
                            <div
                                style={{
                                    width: '3rem',
                                    height: '1rem',
                                    backgroundColor: `rgb${item.rgb}`
                                }}
                            />
                            {item.name}
                        </Space>
                    </div>
                }
                listStyle={{ width: '50%' }}
                style={{ marginTop: '1rem' }}
                pagination
                locale={locale}
            />
            <Row style={{ marginTop: '1rem' }} justify='end'>
                <Space>
                    <Button type='primary' disabled={!hasChanges} onClick={saveChanges}>
                        GUARDAR CAMBIOS
                    </Button>
                </Space>
            </Row>
        </Modal>
    );
}

export default SeasonalColorsModal;