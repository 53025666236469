import { FC, Fragment, useEffect } from 'react';
import { Button, Form, Input, Row, Space, Typography } from 'antd';

import { ProductFeatureFormData } from '../../interfaces';
import { useAppDispatch, useAppSelector, useFormRules } from '../../hooks';
import FeaturesTable from './FeaturesTable';
import { button } from '../../styles';
import { addFeature, closeFeaturesForm, openFeaturesForm } from '../../redux/slices/productsForm';

interface Props {

}

const FeaturesForm: FC<Props> = () => {
    const [form] = Form.useForm<ProductFeatureFormData>()

    const dispatch = useAppDispatch();
    const { featuresFormActive } = useAppSelector(state => state.productsForm);

    const { required } = useFormRules();

    useEffect(() => {
        if (featuresFormActive === false)
            form.resetFields();

    }, [featuresFormActive])

    const onFinish = (data: ProductFeatureFormData) => {
        dispatch(addFeature(data));
        dispatch(closeFeaturesForm());
        form.resetFields();
    }

    return (
        featuresFormActive
            ? <Fragment>
                <Row justify='start'>
                    <Typography.Text strong>
                        AGREGAR NUEVA CARACTERÍSTICA
                    </Typography.Text>
                </Row>
                <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off' style={{ marginTop: '1rem' }}>
                    <Row>
                        <Form.Item label='Descripión:' name='description' rules={[required]} style={{ width: '100%', }}>
                            <Input.TextArea maxLength={500} rows={2} />
                        </Form.Item>
                    </Row>
                    <Row justify='end'>
                        <Space>
                            <Button style={button} onClick={() => dispatch(closeFeaturesForm())}>
                                Cancelar
                            </Button>
                            <Button type='primary' style={button} htmlType='submit'>
                                Agregar
                            </Button>
                        </Space>
                    </Row>
                </Form>
            </Fragment>
            : <Fragment>
                <Row justify='end'>
                    <Button type='primary' style={button} onClick={() => dispatch(openFeaturesForm())}>
                        Agregar CARACTERÍSTICA
                    </Button>
                </Row>
                <Row>
                    <FeaturesTable />
                </Row>
            </Fragment>
    );
}

export default FeaturesForm;