import { FC, useState } from 'react';
import { Button, Tooltip, notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { ColumnsType } from 'antd/es/table';
import { Announcement } from '../../interfaces';
import { ActionsColumn, DataTable, ModalDeleteConfirmation } from '../ui';
import { deleteAnnouncement, sendAnnouncement, setAnnouncementFormActiveAs, setSelectedAnnouncement } from '../../redux/slices/customers';

interface Props {
}

const TableAnnouncements: FC<Props> = () => {
    const [deleteConfirmActive, setDeleteConfirmActive] = useState(false);
    const [selected, setSelected] = useState<number | null>(null);
    
    const [api, contextHolder] = notification.useNotification();

    const dispatch = useAppDispatch();
    const { announcements } = useAppSelector((state) => state.customers);

    const sendAnnouncementToCustomers = (idAnnouncement: number) => {
        dispatch(sendAnnouncement(idAnnouncement, () => {
            api.success({ message: 'El anuncio ha sido enviado con éxito.' })
        }, () => {
            api.error({ message: 'Ha ocurrido un error al intentar enviar el anuncio.' });
        }))
    }

    const columns: ColumnsType<Announcement> = [
        {
            title: 'ID',
            key: 'idAnnouncement', dataIndex: 'idAnnouncement',
        },
        {
            title: 'Asunto',
            dataIndex: 'subject', key: 'subject',
        },
        {
            title: 'Título',
            dataIndex: 'title', key: 'title'
        },
        {
            title: 'Tipo',
            dataIndex: 'mode', key: 'mode',
            render: (value) => value === 'promotional' ? 'Promocional' : 'Anuncio'
        },
        {
            title: 'Imágenes',
            key: 'timesSent', dataIndex: 'timesSent',
            align: 'center',
            render: (_, record) => [record.image1, record.image2, record.image3].filter(img => img !== null).length
        },
        {
            title: 'Envios',
            key: 'timesSent', dataIndex: 'timesSent',
            align: 'center'
        },
        {
            title: 'Último envio',
            key: 'lastSent', dataIndex: 'lastSent',
            align: 'center',
            render: (value) => value ? moment(value).format('LLL') : 'N/A'
        },
        {
            title: 'Acciones',
            render: (_, record) => <ActionsColumn
                label='Anuncio'
                showDetails={false}
                onEditClick={() => {
                    dispatch(setSelectedAnnouncement(record));
                    dispatch(setAnnouncementFormActiveAs(true));
                }}
                onDeleteClick={() => {
                    setSelected(record.idAnnouncement);
                    setDeleteConfirmActive(true);
                }}
                ExtraActions={
                    <Tooltip title='Enviar Anuncio'>
                        <Button icon={<MailOutlined />} onClick={() => sendAnnouncementToCustomers(record.idAnnouncement)} />
                    </Tooltip>
                }
            />,
        }
    ];

    const closeDeleteModal = () => {
        setSelected(null);
        setDeleteConfirmActive(false);
    }

    return (
        <>
            {contextHolder}
            <DataTable columns={columns} data={announcements} rowKey='idAnnouncement' />
            <ModalDeleteConfirmation
                title='¿Está seguro de eliminar el anuncio seleccionado?'
                open={deleteConfirmActive}
                onCancel={closeDeleteModal}
                onOk={() => {
                    dispatch(deleteAnnouncement(selected!, api, closeDeleteModal));
                }}
            />
        </>
    );
}

export default TableAnnouncements;