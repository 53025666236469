import { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAccess } from '../hooks';

interface Props {
    children: ReactNode;
}

const PrivateRoute: FC<Props> = ({ children }) => {
    const location = useLocation();
    
    const { isAuthenticated, allowedLocations } = useAccess();

    return(
        <>
            {
                isAuthenticated
                    ? allowedLocations.includes(location.pathname)
                        ? children
                        : <Navigate to={allowedLocations[0]} replace />
                    : <Navigate to='/login' replace />
            }
        </>
    );
}

export default PrivateRoute;