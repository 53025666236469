import { FC } from 'react';
import { Tag } from 'antd';

import { Order } from '../../interfaces';

interface Props {
    status: Order['status'];
}

const OrderStatus: FC<Props> = ({ status }) => {
    switch (status) {
        case 'pending':
            return <Tag color='volcano'>PENDIENTE</Tag>
        case 'in-progress':
            return <Tag color='gold'>EN PROGRESO</Tag>
        case 'cancelled':
            return <Tag color='magenta'>CANCELADO</Tag>
        case 'completed':
            return <Tag color='green'>COMPLETADO</Tag>
        default:
            return <></>
    }
}

export default OrderStatus;